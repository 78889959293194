<kt-portlet>
    <kt-portlet-header
            [title]="getComponentTitle()|async"
            [class]="'kt-portlet-head--lg'"
            [tooltipTitle]="'TOOLTIP.AGENCY.FORM'+(agency && agency.id > 0 ? '_EDIT':'')+'.TITLE' | translate"
            [tooltipDesc]="'TOOLTIP.AGENCY.FORM'+(agency && agency.id > 0 ? '_EDIT':'')+'.DESCRIPTION' | translate"
            [sticky]="true">
        <ng-container ktPortletTools>
            <a (click)="back()"
               href="javascript:;"
               class="btn mr-2"
               mat-raised-button
               [matTooltip]="'GENERAL.TOOLTIP.BACK' | translate:{
                   back_page: ('AGENCY.LIST.TITLE' | translate)
               }">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">
                    {{ 'GENERAL.BUTTON.CLOSE' | translate}}
                </span>
            </a>
            <a *ngIf="canEdit$|async" href="javascript:;" class="btn btn-success mr-2" color="submit" (click)="onSubmit()" mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.SAVE' | translate">
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.SAVE' | translate }}</span>
            </a>
            <!--</mat-menu>-->
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body [ngClass]="{'readonly': !(canEdit$|async)}">
        <form class="form" [formGroup]="agencyForm" *ngIf="agencyForm">
            <kt-tab-header 
                [centerTabs]="true"
                [headerLabels]="tabHeaders" 
                (tabChange)="onTabChange($event)"
                [selectedTabChange]="selectedtabchange$">
            </kt-tab-header>
            <mat-tab-group class="invinsible-tabs" [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged($event);">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <div class="form-group kt-form-group row section-margin-top-60">
        
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Names
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.AGENCY_NAME.PLACEHOLDER' | translate" [(ngModel)]="agency.name" formControlName="name"/>
                                            <mat-error
                                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                                    field: ('AGENCY.FORM.FIELD.INFORMATION.AGENCY_NAME.NAME' | translate)
                                                }"
                                            >
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.LEGAL_NAME.PLACEHOLDER' | translate" [(ngModel)]="agency.legal_name" formControlName="legal_name"/>
                                            <mat-error
                                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                                    field: ('AGENCY.FORM.FIELD.INFORMATION.LEGAL_NAME.NAME' | translate)
                                                }"
                                            >
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group kt-form-group row section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Contacts
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.WEBSITE.PLACEHOLDER' | translate" [(ngModel)]="agency.website" formControlName="website"/>
                                            <mat-error
                                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                                    field: ('AGENCY.FORM.FIELD.INFORMATION.WEBSITE.NAME' | translate)
                                                }"
                                            >
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput type="email" [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.EMAIL.PLACEHOLDER' | translate" [(ngModel)]="agency.email" formControlName="email"/>
                                            <mat-error
                                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                                    field: ('AGENCY.FORM.FIELD.INFORMATION.EMAIL.NAME' | translate)
                                                }"
                                            >
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                
                                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput type="tel" [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.PHONE_NUMBER.PLACEHOLDER' | translate" [(ngModel)]="agency.phone" formControlName="phone"/>
                                            <mat-error
                                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                                    field: ('AGENCY.FORM.FIELD.INFORMATION.PHONE_NUMBER.NAME' | translate)
                                                }"
                                            >
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group kt-form-group row section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Address
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-12 kt-margin-bottom-10-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <textarea matInput rows="5" [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.ADDRESS.PLACEHOLDER' | translate" [(ngModel)]="agency.address"
                                                    formControlName="address"></textarea>
                                            <mat-error
                                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                                    field: ('AGENCY.FORM.FIELD.INFORMATION.ADDRESS.NAME' | translate)
                                                }"
                                            >
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.COUNTRY.PLACEHOLDER' | translate"
                                                        [(ngModel)]="agency.country_id"
                                                        formControlName="country_id"
                                                        (selectionChange)="selectCountry()">
                                                <mat-option>
                                                    <div (click)="addCountry()" class="add-entry">
                                                        <i class="ki ki-plus"></i> New Country
                                                    </div>
                                                </mat-option>
                                                <mat-option *ngFor="let country of allCountries" [value]="country.id">{{country.name}}</mat-option>
                                            </mat-select>
                                            <mat-error
                                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                                    field: ('AGENCY.FORM.FIELD.INFORMATION.COUNTRY.NAME' | translate)
                                                }"
                                            >
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.CITY.PLACEHOLDER' | translate" [(ngModel)]="agency.city_id" formControlName="city_id">
                                                <mat-option *ngIf="agencyForm.controls.country_id.value">
                                                    <div (click)="addCity()" class="add-entry">
                                                        <i class="ki ki-plus"></i> New city
                                                    </div>
                                                </mat-option>
                                                <mat-option *ngFor="let city of cityOfCountry" [value]="city.id">{{city.name}}</mat-option>
                                                <mat-option *ngIf="cityOfCountry.length == 0" disabled>
                                                    {{ 'GENERAL.MESSAGE.NO_CITY' | translate }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                                    field: ('AGENCY.FORM.FIELD.INFORMATION.CITY.NAME' | translate)
                                                }"
                                            >
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.ZIPCODE.PLACEHOLDER' | translate" [(ngModel)]="agency.zip_code" formControlName="zip_code"/>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        <div class="form-group kt-form-group row section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase d-flex align-items-center">
                                    <span>memberships</span>
                                    <mat-icon matSuffix class="cursor-pointer icon-gray ml-4"
                                        inline
                                        container="body"
                                        [ngbPopover]="'Valuation Professional Organisation'"
                                        (click)="$event.stopPropagation();">help
                                    </mat-icon>
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-4 my-5">
                                        <mat-checkbox formControlName="rics_regulated">
                                            Does the RICS regulate the Agency?
                                        </mat-checkbox>
                                    </div>
                                    <div class="col-4" >
                                        <mat-form-field class="mat-form-field-fluid" *ngIf="agencyForm.controls.rics_regulated.value">
                                            <input 
                                                matInput 
                                                type="number" 
                                                [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.RICS_REGISTRATION_NUMBER.PLACEHOLDER' | translate" 
                                                (ngModelChange)="registrationChanged($event)"
                                                [(ngModel)]="agency.registration_number"
                                                formControlName="registration_number"/>
                                            <mat-error
                                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                                    field: ('AGENCY.FORM.FIELD.INFORMATION.RICS_REGISTRATION_NUMBER.NAME' | translate)
                                                }"
                                            >
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4 my-auto">
                                        <mat-checkbox formControlName="vpo_regulated">
                                            Does any other VPO regulate the Agency?
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <kt-vpos-table *ngIf="agencyForm.controls.vpo_regulated.value" class="col-12 mt-4" [vpos]="agency.vpos"></kt-vpos-table>
                        </div>
                        <div class="form-group kt-form-group row section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase d-flex align-items-center">
                                    <span>professional requirements</span>
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-md-12 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput rows="4"
                                            [placeholder]="'AGENCY.FORM.FIELD.DESCRIPTIONS.DESCRIPTION_EXPERTISE_ON_MARKET.PLACEHOLDER' | translate"
                                            [(ngModel)]="agency.description" formControlName="description"></textarea>
                                    <mat-error
                                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                            field: ('AGENCY.FORM.FIELD.DESCRIPTIONS.DESCRIPTION_EXPERTISE_ON_MARKET.NAME' | translate)
                                        }"
                                    >
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput rows="4" [placeholder]="'AGENCY.FORM.FIELD.DESCRIPTIONS.SOURCE_DATA.PLACEHOLDER' | translate" [(ngModel)]="agency.source_of_data"
                                            formControlName="source_of_data"></textarea>
                                    <mat-error
                                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                            field: ('AGENCY.FORM.FIELD.DESCRIPTIONS.SOURCE_DATA.NAME' | translate)
                                        }"
                                    >
                                    </mat-error>
        
                                    <mat-icon
                                            matSuffix
                                            class="cursor-pointer pt-1"
                                            (click)="$event.stopPropagation();"
                                            container="body"
                                            [popoverTitle]="'TOOLTIP.AGENCY.SOURCE_OF_DATA.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.AGENCY.SOURCE_OF_DATA.DESCRIPTION' | translate">help
                                    </mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput rows="4" [placeholder]="'AGENCY.FORM.FIELD.DESCRIPTIONS.COMPLAINTS_HANDLING_PROCEDURE.PLACEHOLDER' | translate"
                                            [(ngModel)]="agency.complain_of_procedure" formControlName="complain_of_procedure"></textarea>
                                    <mat-error
                                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                            field: ('AGENCY.FORM.FIELD.DESCRIPTIONS.COMPLAINTS_HANDLING_PROCEDURE.NAME' | translate)
                                        }"
                                    >
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group kt-form-group row section-margin-top-60">
                            <kt-other-documents-table class="col-12" [agency]="agency" [readonly]="!(canEdit$|async)"></kt-other-documents-table>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>{{ 'AGENCY.FORM.FIELD.DESCRIPTIONS.SECTION_TITLE' | translate }}</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        <div class="form-group kt-form-group row section-margin-top-60">
                            <kt-additional-terms-table class="col-12" [terms]="agency.additionalTerms" [readonly]="!(canEdit$|async)"></kt-additional-terms-table>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Payment</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <kt-post-valuation-delivery-service
                            [readonly]="!(canEdit$|async)"
                            [parentTypeID]="1" 
                            [postValuationDeliveryServicetData]="agency.postValuationDeliveryServicetData"
                        ></kt-post-valuation-delivery-service>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Invoicing</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <div class="form-group kt-form-group row section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase d-flex align-items-center">
                                    <span>bank & legal information</span>
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-12 mt-4">
                                <div class="row">
                                    <div class="col-12">
                                        <h6>
                                            Payment information
                                        </h6>
                                    </div>
                                    <ckeditor [readOnly]="!(canEdit$|async)" class="col-12 mt-4" #paymentInfo [config]="editorConfig" [(ngModel)]="paymentInfoData" formControlName="payment_info"></ckeditor>
                                </div>
                            </div>
                            <div class="col-12 mt-12">
                                <div class="row">
                                    <div class="col-12">
                                        <h6>
                                            Disclaimer
                                        </h6>
                                    </div>
                                    <ckeditor [readOnly]="!(canEdit$|async)" class="col-12 mt-4" #disclaimer [config]="editorConfig" [(ngModel)]="disclaimerData" formControlName="disclaimer"></ckeditor>
                                </div>
                            </div>
                        </div>

                        <kt-reg-numbers-list
                            [readonly]="!(canEdit$|async)"
                            [(regNumbersSubject)]="regNumbersSubject">
                        </kt-reg-numbers-list>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Pictures & Documents</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
        
                        <div class="kt-portlet-head-progress" *ngIf="(progress$ | async) > 0">
                            <mat-progress-bar mode="determinate" color="war" [value]="progress$ | async "></mat-progress-bar>
                        </div>

                        <div class="form-group kt-form-group row section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase d-flex align-items-center">
                                    <span>logo & cover picture</span>
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-12 mt-4">
                                <div class="row">
                                    <input #fileInputImg type="file" (change)="uploadFile($event)" style="display: none" accept="image/*"/>
                                    <div class="col-12">
                                        <div class="mb-4 example-container mat-elevation-z0 ">
                                            <mat-table #table [dataSource]="dataSource">
                                                <ng-container matColumnDef="type">
                                                    <mat-header-cell *matHeaderCellDef>{{ 'AGENCY.FORM.DATATABLE.PICTURES.TYPE' | translate }}</mat-header-cell>
                                                    <mat-cell *matCellDef="let file">
                                                        <img class="mat-table-icon" [attr.src]="setIcon(getFileName(file.field))" alt="">
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="name">
                                                    <mat-header-cell *matHeaderCellDef>{{ 'AGENCY.FORM.DATATABLE.PICTURES.TITLE' | translate }}</mat-header-cell>
                                                    <mat-cell *matCellDef="let file"> {{file.name}}</mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="file_name">
                                                    <mat-header-cell *matHeaderCellDef>{{ 'AGENCY.FORM.DATATABLE.PICTURES.FILE_NAME' | translate }}</mat-header-cell>
                                                    <mat-cell *matCellDef="let file"> {{getFileName(file.field)}}</mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="actions">
                                                    <mat-header-cell *matHeaderCellDef>{{ 'AGENCY.FORM.DATATABLE.PICTURES.ACTIONS' | translate }}</mat-header-cell>
                                                    <mat-cell *matCellDef="let file">
                                                        <ng-container *ngIf="canEdit$|async">
                                                            <button mat-icon-button
                                                                    color="primary"
                                                                    [matTooltip]="'AGENCY.FORM.BUTTON.UPLOAD_FILE.TOOLTIP' | translate"
                                                                    (click)="addFiles(file.field)"
                                                                    [disabled]="(progress$ | async) > 0"
                                                                    *ngIf="getFileName(file.field) == awConst.NOFILE"
                                                                    type="button">
                                                                <mat-icon>cloud_upload</mat-icon>
                                                            </button>&nbsp;
                                                            <button mat-icon-button
                                                                    color="warn"
                                                                    [matTooltip]="'AGENCY.FORM.BUTTON.DELETE_FILE.TOOLTIP' | translate"
                                                                    (click)="deleteUploadedFile(file.field)"
                                                                    type="button"
                                                                    *ngIf="getFileName(file.field) != awConst.NOFILE"
                                                            >
                                                                <mat-icon>delete</mat-icon>
                                                            </button>&nbsp;
                                                        </ng-container>
                                                        <button mat-icon-button
                                                                [matTooltip]="'AGENCY.FORM.BUTTON.PREVIEW_FILE.TOOLTIP' | translate"
                                                                (click)="previewUploadedFile(file.field)"
                                                                type="button"
                                                                *ngIf="getFileName(file.field) != awConst.NOFILE">
                                                            <mat-icon *ngIf="file.type == 'jpeg'">remove_red_eye</mat-icon>
                                                            <mat-icon *ngIf="file.type == 'pdf'">get_app</mat-icon>
                                                        </button>&nbsp;
                                                    </mat-cell>
                                                </ng-container>
                                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                                <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'required_file' : getFileName(row.field) == awConst.NOFILE}">
                                                </mat-row>
                                            </mat-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="form-group kt-form-group row section-margin-top-60">
                            <kt-report-designs-table class="col-12" [schemes]="agency.reportSchemes" [readonly]="!(canEdit$|async)"></kt-report-designs-table> 
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Settings</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        <div class="form-group kt-form-group row section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase d-flex align-items-center">
                                    <span>standards</span>
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select placeholder="Standard floor numbering scheme" formControlName="floor_numbering_scheme_id">
                                                <mat-option *ngFor="let item of floorNumberingSchemes" [value]="item.id">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                Standard floor numbering scheme is <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>
    </kt-portlet-body>
</kt-portlet>   

