import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllApproachesToValuesRequested, AllLandTenuresRequested, AllMethodsToValuesRequested, AllPremiseOfValuesRequested, AllTenureRequested, ApproachesToValue, MethodsToValue, PremiseOfValue, selectAllApproachesToValues, selectAllLandTenures, selectAllMethodsToValues, selectAllPremiseOfValues, selectAllTenure } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ITpValuation } from 'src/app/core/template/_models/tp-template.model';
import { TenureType } from 'src/app/views/pages/shared_components/ac-valuation/eventtype';

interface IData {
  customTp$: Observable<boolean>,
  baseOfValueId$: Observable<number>,
  tenureType$: Observable<TenureType>
  item: ITpValuation
}

@Component({
  selector: 'kt-add-valuation-modal',
  templateUrl: './add-valuation-modal.component.html',
  styleUrls: ['./add-valuation-modal.component.scss']
})
export class AddValuationModalComponent implements OnInit, OnDestroy {
  title: string = '';
  formGroup: UntypedFormGroup
  methodsToValues: MethodsToValue[] = [];
  approachesToValues: ApproachesToValue[] = [];
  premiseOfValues: PremiseOfValue[] = [];
  item: ITpValuation;
  baseOfValueId$: Observable<number>;
  tenureType$: Observable<TenureType>;
  tenureType = TenureType;
  tenures: any[] = [];
  landTenures: any[] = [];
  private _onDestroy$: Subject<void> = new Subject();
  public filteredApproachesToValuesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredMethodsToValuesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  constructor(
    private dialogRef: MatDialogRef<AddValuationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IData,
    private fb: UntypedFormBuilder,
    private store: Store<AppState>
  ) { 
    if (data.item) {
      this.item = data.item;
      this.title = 'Edit Valuation';
    } else {
      this.item = {
        id: undefined,
        methods_to_value_id: undefined,
        methods_to_value_name: null,
        approaches_to_value_id: undefined,
        approaches_to_value_name: null,
        tenure_id: undefined,
        tenure_custom: null,
        premise_of_value_id: undefined
      };
      this.title = 'New Valuation';
    }
    this.baseOfValueId$ = this.data.baseOfValueId$;
    this.tenureType$ = this.data.tenureType$;
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      methods_to_value: [this.item.methods_to_value_id, Validators.required],
      approaches_to_value: [this.item.approaches_to_value_id, Validators.required],
      premise_of_value_id: [this.item.premise_of_value_id],
      tenure_id: [this.item.tenure_id],
      tenure_custom: [this.item.tenure_custom]
    });

    this.store.dispatch(new AllApproachesToValuesRequested());
    this.store.select(selectAllApproachesToValues).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.approachesToValues = [];
      if (res) {
        this.approachesToValues = res;
        this.filteredApproachesToValuesData.next(this.approachesToValues.slice());
      }
    });

    this.store.dispatch(new AllMethodsToValuesRequested());
    combineLatest([
      this.store.select(selectAllMethodsToValues),
      this.data.customTp$
    ]).pipe(takeUntil(this._onDestroy$)).subscribe(([res, customTp]) => {
      this.methodsToValues = [];
      if (res) {
        this.methodsToValues = res;
        const methodsToValues = this.methodsToValues.map(mtv => {
          const disabled = !(customTp || (mtv.id == 2 || mtv.id == 9))
          return {
            ...mtv,
            disabled,
            text: disabled ? `${mtv.name} (Coming soon)` : mtv.name
          }
        });
        this.filteredMethodsToValuesData.next(methodsToValues);
      }
    })

    this.store.dispatch(new AllPremiseOfValuesRequested())
    this.store
      .select(selectAllPremiseOfValues)
      .pipe(takeUntil(this._onDestroy$)).subscribe(res => {
        if (!res) {
            this.premiseOfValues = [];
            return;
        }
        this.premiseOfValues = res;
    });

    this.store.dispatch(new AllTenureRequested())
    combineLatest([
        this.store.select(selectAllTenure),
        this.baseOfValueId$
    ]).pipe(takeUntil(this._onDestroy$))
      .subscribe(([tenures, baseOfValueId]) => {
        this.tenures = [];
        if (tenures) {
            this.tenures = tenures.map(t => ({
                ...t,
                disabled: baseOfValueId == 2 && t.id != 1
            }))
        }
    });    

    this.store.dispatch(new AllLandTenuresRequested())
    this.store.select(selectAllLandTenures).pipe(takeUntil(this._onDestroy$)).subscribe(tenures => {
        this.landTenures = [];
        if (tenures) {
            this.landTenures = tenures;
        }
    })
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    const controls = this.formGroup.controls;
    if (this.formGroup.invalid) {
      Object.keys(controls).forEach(cName => {
        if (controls[cName].invalid) {
          controls[cName].markAsTouched();
        }
      })
      return;
    }

    const methodsToValueId = Number(controls.methods_to_value.value);
    const methodsToValueName = this.methodsToValues.find(mtv => mtv.id === methodsToValueId)?.name;
    const approachesToValueId = Number(controls.approaches_to_value.value);
    const approachesToValueName = this.approachesToValues.find(atv => atv.id === approachesToValueId)?.name;

    const valuation: ITpValuation = {
      id: this.item.id,
      methods_to_value_id: methodsToValueId,
      methods_to_value_name: methodsToValueName,
      approaches_to_value_id: approachesToValueId,
      approaches_to_value_name: approachesToValueName,
      tenure_id: controls.tenure_id.value,
      tenure_custom: controls.tenure_custom.value,
      premise_of_value_id: controls.premise_of_value_id.value,
    };

    this.dialogRef.close(valuation);
  }
}
