import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { awConst } from 'src/app/app.constants';
import { KeyPlaceModel, selectKeyPlaceActionLoading, selectKeyPlaceById, KeyPlaceUpdated, KeyPlaceOnServerCreated, selectLastCreatedKeyPlaceId } from 'src/app/core/admin';
import { UpdatePreviouslyCreatedKeyPlaceId } from 'src/app/core/admin/_actions/key-place.actions';
import { FileUploadService } from 'src/app/core/file-upload';
import { KeyCategoryModel, AllKeyCategoriesRequested, selectAllKeyCategories } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { TypesUtilsService, LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { SubheaderService, LayoutConfigService } from 'src/app/core/_base/layout';
import { ValuationCheckerModalComponent } from 'src/app/views/pages/comparable/_sub/valuation-checker-modal/valuation-checker-modal.component';
import { ImageViewerDialogComponent } from 'src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kt-keyplace-modal',
  templateUrl: './keyplace-modal.component.html',
  styleUrls: ['./keyplace-modal.component.scss']
})
export class KeyplaceModalComponent implements OnInit {
    @ViewChild('mapTooltip') mapTooltip: ElementRef;
    @ViewChild('map', {static: true}) map: google.maps.Map;

    @ViewChild('fileInputImg') fileInputImg: ElementRef;
    @ViewChild('search')
    public searchElementRef: ElementRef;
    awConst = awConst;

    keyPlace: KeyPlaceModel;
    oldKeyPlace: KeyPlaceModel;

    keyPlaceForm: UntypedFormGroup;
    hasFormErrors = false;

    allCategories: KeyCategoryModel[] = [];
    marker: google.maps.Marker;
    geoCoder = new google.maps.Geocoder;
    lat = environment.mapCenter.lat;
    lng = environment.mapCenter.lng;
    zoom = 15;
    centerLat = new BehaviorSubject<number>(environment.mapCenter.lat);
    centerLng = new BehaviorSubject<number>(environment.mapCenter.lng);

    cityOfLocation = '';
    isShown = true;

    error: any;

    displayedColumns = ['type', 'name', 'file_name', 'actions'];
    dataSource = new MatTableDataSource([
        {
            name: 'Picture of the Landmark',
            type: 'jpeg',
            field: 'picture'
        }
    ]);
    loading$: Observable<boolean>;
    progressValue = 0;
    progress$: Observable<number>;
    progressSubject = new BehaviorSubject<number>(0);

    // Private properties
    private componentSubscriptions: Subscription;

    private subscriptions: Subscription[] = [];

    // Workaround
    private mapClickListener: google.maps.MapsEventListener;
    // private valuationId: number | null = null;

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param fb: FormBuilder
     * @param location
     * @param subheaderService
     * @param assetClassResidentialService
     * @param layoutUtilsService
     * @param fileUploadService
     * @param dialog: MatDialog
     * @param store: Store<AppState>
     * @param typesUtilsService: TypesUtilsService
     * @param mapsAPILoader: MapsAPILoader
     * @param ngZone: NgZone
     * @param layoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private subheaderService: SubheaderService,
                // private assetClassResidentialService: AssetClassResidentialsService,
                public typesUtilsService: TypesUtilsService,
                private layoutUtilsService: LayoutUtilsService,
                private fileUploadService: FileUploadService,
                private dialog: MatDialog,
                private store: Store<AppState>,
                private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService,
                private dialogRef: MatDialogRef<KeyplaceModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                //   this.valuationId = data.valuationId;
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectKeyPlaceActionLoading));
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(select(selectKeyPlaceById(id))).subscribe(res => {
                    if (res) {
                        this.oldKeyPlace = Object.assign({}, res);
                        this.keyPlace = Object.assign({}, this.oldKeyPlace);
                        this.centerLat.next(Number(this.keyPlace.latitude));
                        this.centerLng.next(Number(this.keyPlace.longitude));

                        this.lng = this.centerLng.value;
                        this.lat = this.centerLat.value;

                        this.getWhat3words(new google.maps.LatLng(this.centerLat.value, this.centerLng.value));
                        this.initKeyPlace();
                    } else {
                        this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
                        return;
                    }
                });
            } else {
                this.isShown = false;
                this.keyPlace = new KeyPlaceModel();
                this.keyPlace.clear();
                this.oldKeyPlace = Object.assign({}, this.keyPlace);
                this.oldKeyPlace.latitude = this.lat;
                this.oldKeyPlace.longitude = this.lng;
                this.initKeyPlace();
            }
        });
        this.subscriptions.push(routeSubscription);


        this.store.dispatch(new AllKeyCategoriesRequested());
        const categorySubscription = this.store.pipe(select(selectAllKeyCategories))
            .subscribe((res: KeyCategoryModel[]) => {
                this.allCategories = res;
            });

        this.subscriptions.push(categorySubscription);
        this.progress$ = this.progressSubject.asObservable();

        this.mapsAPILoader.load().then(() => {
            this.geoCoder = new google.maps.Geocoder();

            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                types: ['(cities)']
            });
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    // get the place result
                    const place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    // verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    if (place.formatted_address === 'Ulaanbaatar, Mongolia') {
                        this.centerLat.next(47.91868658952473);
                        this.centerLng.next(106.91766668255616);
                        this.zoom = 15;
                    } else {
                        // set latitude, longitude and zoom
                        this.centerLat.next(place.geometry.location.lat());
                        this.centerLng.next(place.geometry.location.lng());
                        this.zoom = 12;
                    }
                });
            });
        });
    }

    mapReady(event: any) {
        this.map = event;
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('Settings'));
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('Settings'));
        
        // Workaround click event on new agm
        this.mapClickListener = this.map.addListener('rightclick', (e: google.maps.MouseEvent) => {
            this.ngZone.run(() => {
                this.mapRightClick(e);
            })
        })
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }

        // Workaround
        if (this.mapClickListener) {
            this.mapClickListener.remove();
        }
    }

    initKeyPlace() {
        this.createForm();
        if (!this.keyPlace.id) {
            this.subheaderService.setTitle('New KeyPlace');
            this.subheaderService.setBreadcrumbs([
                {title: 'Admin management', page: `../default/admin-management`},
                {title: 'KeyPlaces', page: `../default/admin-management/keyPlaces`},
                {title: 'Add KeyPlace', page: `../default/admin-management/keyPlaces/add`}
            ]);
            return;
        }
        this.subheaderService.setTitle('Edit KeyPlace');
        this.subheaderService.setBreadcrumbs([
            {title: 'Admin management', page: `../default/admin-management`},
            {title: 'KeyPlaces', page: `../default/admin-management/keyPlaces`},
            {title: 'Add KeyPlace', page: `../default/ecommerce/products/edit`}
        ]);
    }


    createForm() {
        this.keyPlaceForm = this.fb.group({
            name: [this.keyPlace.name, Validators.required],
            what3words: [{value: '', disabled: true}, Validators.required],
            picture: [this.keyPlace.picture, Validators.required],
            category_id: [this.keyPlace.category_id, Validators.required],
            description: [this.keyPlace.description, Validators.required],
            latitude: [this.keyPlace.latitude, Validators.required],
            longitude: [this.keyPlace.longitude, Validators.required],
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.keyPlace && this.keyPlace.id > 0) {
            return this.translate.instant('LANDMARK.FORM.TITLE.EDIT', {name: this.keyPlace.name});
        }

        return this.translate.instant('LANDMARK.FORM.TITLE.NEW');
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.keyPlaceForm.controls;
        /** check form */
        if (this.keyPlaceForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const _keyPlace = this.prepareKeyPlace();
        const dialogRef = this.dialog.open(ValuationCheckerModalComponent, {
          width: '400px',
          disableClose: true
        })
        dialogRef.afterClosed().subscribe(res => {
          if (res == undefined) {
            return;
          }
        //   _keyPlace.valuation_id = res ? this.valuationId : null;
          if (_keyPlace.id > 0) {
              this.updateKeyPlace(_keyPlace);
          } else {
              this.createKeyPlace(_keyPlace);
          }
        })
    }

    cancel() {
      this.dialogRef.close();
    }

    /**
     * Update keyPlace
     *
     * @param _keyPlace: KeyPlaceModel
     */
    updateKeyPlace(_keyPlace: KeyPlaceModel) {
        const updateKeyPlace: Update<KeyPlaceModel> = {
            id: _keyPlace.id,
            changes: _keyPlace
        };
        this.store.dispatch(new KeyPlaceUpdated({
            partialKeyPlace: updateKeyPlace,
            keyPlace: _keyPlace
        }));
        this.oldKeyPlace = _keyPlace;
        this.layoutUtilsService.showActionNotification(
            this.translate.instant('GENERAL.MESSAGE.SAVE_CHANGES'), MessageType.Update, 3000, true, false);
    }

    /**
     * Create keyPlace
     *
     * @param _keyPlace: KeyPlaceModel
     */
    createKeyPlace(_keyPlace: KeyPlaceModel) {

        this.store.dispatch(new KeyPlaceOnServerCreated({
            keyPlace: _keyPlace,
        }));
        this.oldKeyPlace = _keyPlace;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedKeyPlaceId),
            distinctUntilChanged(),
            switchMap(res => {
                if (!res) {
                    return of(undefined)
                }
                this.store.dispatch(new UpdatePreviouslyCreatedKeyPlaceId());
                return this.store.select(selectKeyPlaceById(res))
            })
        ).subscribe(keyPlace => {
            if (!keyPlace) {
                return;
            }
            this.dialogRef.close(keyPlace);
        });
    }

    prepareKeyPlace() {
        const controls = this.keyPlaceForm.controls;
        const _keyPlace = new KeyPlaceModel();
        _keyPlace.clear();
        _keyPlace.id = this.keyPlace.id;
        _keyPlace.name = controls.name.value;
        _keyPlace.category_id = controls.category_id.value;
        _keyPlace.description = controls.description.value;
        _keyPlace.longitude = this.lng;
        _keyPlace.latitude = this.lat;
        _keyPlace.picture = controls.picture.value;
        _keyPlace.what3words = controls.what3words.value;
        _keyPlace.city_of_location = this.cityOfLocation;
        return _keyPlace;
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    back() {
        if (this.keyPlace.id) {
            this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
            return;
        }
        this.router.navigate(['../'], {relativeTo: this.activatedRoute});
        // this.location.back();
    }

    isFormValid() {
        return true;
    }

    mapRightClick($event: google.maps.MouseEvent) {
        this.lat = $event.latLng.lat();
        this.lng = $event.latLng.lng();
        this.centerLat.next(this.lat);
        this.centerLng.next(this.lng);
        this.keyPlaceForm.controls.latitude.patchValue($event.latLng.lat());
        this.keyPlaceForm.controls.longitude.patchValue($event.latLng.lng());
        this.getWhat3words(new google.maps.LatLng($event.latLng.lat(), $event.latLng.lng()));
        this.isShown = true;
    }

    markerDragEnd($event: google.maps.MouseEvent) {
        this.lat = $event.latLng.lat();
        this.lng = $event.latLng.lng();

        this.centerLat.next(this.lat);
        this.centerLng.next(this.lng);

        this.keyPlaceForm.controls.latitude.patchValue($event.latLng.lat());
        this.keyPlaceForm.controls.longitude.patchValue($event.latLng.lng());
        this.getWhat3words(new google.maps.LatLng($event.latLng.lat(), $event.latLng.lng()));
    }

    getWhat3words(latLng: google.maps.LatLng): void {
        this.layoutUtilsService.getWhat3Word(latLng).subscribe(res => {
            this.keyPlaceForm.controls.what3words.patchValue(res.words);
        });

        this.geoCoder.geocode({location: {lat: latLng.lat(), lng: latLng.lng()}}, (results, status) => {
            if (status && results && results.length > 0) {
                this.cityOfLocation = results.length > 1 ? results[results.length - 2].formatted_address : results[0].formatted_address;
            } else {
                // window.alert('No results found');
            }
        });
    }

    // file fuctions
    // upload
    uploadFile(event) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            this.upload(file, file.name);
        }
    }

    upload(file: File, name: string) {
        const formData = new FormData();
        formData.append('file', file, name);
        formData.append('path', 'key_place/');

        this.progressSubject.next(1);
        this.fileUploadService.upload2path(formData).subscribe(
            (res) => {
                if (res && res.status) {
                    if (res.status === 'progress') {
                        this.progressSubject.next(res.message);
                    }
                    if (res.status === 'done') {
                        this.keyPlaceForm.controls.picture.patchValue(res.filePath);
                        this.keyPlaceForm.controls.picture.updateValueAndValidity();
                        this.keyPlace.picture = res.filePath;

                        this.fileInputImg.nativeElement.value = '';
                        this.progressSubject.next(0);
                    }
                }
            },
            (err) => this.error = err
        );

    }

    addFiles() {
        this.fileInputImg.nativeElement.click();
    }

    deleteUploadedFile() {
        this.keyPlace.picture = '';
    }

    previewUploadedFile() {
        this.dialog.open(ImageViewerDialogComponent, {
            data: {
                picture: this.keyPlace.picture,
                type: 'jpg'
            }
        });
    }
}

