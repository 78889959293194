import {
    Component, OnDestroy, OnInit, Input
} from '@angular/core';
import { UntypedFormBuilder, } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutConfigService, SubheaderService } from '../../../../core/_base/layout';
import { LayoutUtilsService, TypesUtilsService } from '../../../../core/_base/crud';
import { AppState } from '../../../../core/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FileUploadService } from '../../../../core/file-upload/_services';
import { TranslateService } from '@ngx-translate/core';
import {ValuationModel} from '../../../../core/asset_class';
import { AddValuationDialogComponent } from '../add-valuation-dialog/add-valuation-dialog.component';
import { AllApproachesToValuesRequested, AllBaseOfValuesRequested, AllLandTenuresRequested, AllMethodsToValuesRequested, AllPremiseOfValuesRequested, AllTenureRequested, ApproachesToValue, BaseOfValue, MethodsToValue, PremiseOfValue, selectAllApproachesToValues, selectAllBaseOfValues, selectAllLandTenures, selectAllMethodsToValues, selectAllPremiseOfValues, selectAllTenure, Tenure } from 'src/app/core/linked-tables';
import { PropertyTypes } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { TenureType } from './eventtype';
import { ConsiderationTypeService } from 'src/app/core/v2/services';

@Component({
    selector: 'kt-ac-valuation-alt',
    templateUrl: './ac-valuation.component.html',
    styleUrls: ['./ac-valuation.component.scss'],
})
export class AcValuationComponent implements OnInit, OnDestroy {

    @Input() loadingSubject = new BehaviorSubject<boolean>(false);
    @Input() valuationsSubject: BehaviorSubject<ValuationModel[]>;
    @Input() customTp$: Observable<number>;
    @Input() propertyType$: Observable<number>;

    displayedColumns = ['base_of_value_name', 'premise_of_value_name', 'approaches_to_value_name', 'methods_to_value_name', 'tenure', 'consideration_type', 'actions'];
    dataSource = new MatTableDataSource();

    tenures: any[] = [];
    landTenures: any[] = [];
    premiseOfValues: PremiseOfValue[] = [];
    tenureType$: Observable<TenureType>;
    tenureType = TenureType;
    methodsToValues: MethodsToValue[];
    approachesToValues: ApproachesToValue[];
    baseOfValues: BaseOfValue[]
    public filteredMethodsToValuesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public filteredApproachesToValuesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    sizes: ValuationModel[];

    private subscriptions: Subscription[] = [];
    methodsToValueMap = {}

    considerationTypes$ = this.considerationTypeService.fetchListing()

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param fileUploadService: FileUploadService
     * @param dialog: MatDialog
     * @param layoutUtilsService: LayoutUtilsService
     * @param store: Store
     * @param layoutConfigService: LayoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private fileUploadService: FileUploadService,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService,
                private considerationTypeService: ConsiderationTypeService
            ) {
        // this.saveState = false;
    }

    ngOnInit() {
        this.sizes = [];
        // this.valuationsSubject.subscribe(valuations => {
        //     this.displayColumns(valuations);
        // })
        this.dataSource = new MatTableDataSource(this.valuationsSubject.value);
        this.tenureType$ = this.propertyType$.pipe(map(propertyType => {
            if (propertyType == PropertyTypes.Commercial) {
                return TenureType.Default;
            }
            if (propertyType == PropertyTypes.Residential) {
                return TenureType.Default;
            }
            if (propertyType == PropertyTypes.Land) {
                return TenureType.Land;
            }
            if (propertyType == PropertyTypes.Custom) {
                return TenureType.Custom;
            }
            return TenureType.Default;
        }))
        
        const valuationsSubjectSubscription = this.valuationsSubject.asObservable()
            .pipe(
                distinctUntilChanged(),
                skip(1))
            .subscribe(res => this.dataSource.data = res);
        this.subscriptions.push(valuationsSubjectSubscription);

        this.store.dispatch(new AllBaseOfValuesRequested());
        const baseOfValuesSubscribe = this.store.pipe(
            select(selectAllBaseOfValues))
            .subscribe(res => {
                this.baseOfValues = [];
                if (res) {
                    this.baseOfValues = res;
                }
            });
        this.subscriptions.push(baseOfValuesSubscribe);

        this.store.dispatch(new AllTenureRequested())
        const tenureSub = this.store.select(selectAllTenure)
            .subscribe((tenures) => {
                this.tenures = [];
                if (tenures) {
                    this.tenures = tenures
                }
            });    
        this.subscriptions.push(tenureSub);

        this.store.dispatch(new AllApproachesToValuesRequested());
        const approachesToValuesSubscribe = this.store.pipe(
            select(selectAllApproachesToValues))
            .subscribe(res => {
                this.approachesToValues = [];
                if (res) {
                    this.approachesToValues = res;
                    res.forEach(item => {
                        this.methodsToValueMap[item.id] = item.methods_to_value.map(mtv => {
                            const disabled = [7, 8, 10, 13, 15, 16, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 33].includes(mtv.id);
                            return {
                                ...mtv,
                                disabled,
                                text: disabled ? `${mtv.name} (Coming soon)` : mtv.name
                            }
                        })
                    })
                    this.filteredApproachesToValuesData.next(this.approachesToValues.slice());
                }
            });
        this.subscriptions.push(approachesToValuesSubscribe);

        this.store.dispatch(new AllLandTenuresRequested())
        const landTenureSub = this.store.select(selectAllLandTenures).subscribe(tenures => {
            this.landTenures = [];
            if (tenures) {
                this.landTenures = tenures;
            }
        })
        this.subscriptions.push(landTenureSub);

        this.store.dispatch(new AllPremiseOfValuesRequested())
        const premiseOfValueSub = this.store.select(selectAllPremiseOfValues).subscribe(res => {
            if (!res) {
                this.premiseOfValues = [];
                return;
            }
            this.premiseOfValues = res;
        });
        this.subscriptions.push(premiseOfValueSub);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    addItem() {
        const _acValuation = new ValuationModel();
        _acValuation.clear();
        const _data = Object.assign([], this.valuationsSubject.value);
        const temp: ValuationModel = Object.assign({}, _acValuation);
        _data.push(temp);
        this.valuationsSubject.next(_data);
    }
    
    
    deleteItem(_item: ValuationModel) {
        const _data = this.valuationsSubject.value.filter(obj => obj !== _item);
        this.valuationsSubject.next(_data);
    }

    liquidationValueAlreadyExists() {
        return this.valuationsSubject.value.findIndex(item => item.base_of_value_id == 7) > -1
    }
}
