import { LocalVpgaModel } from "../../asset_class/_models/local-vpga.model";
import { DefaultAssumption, Departure, SourceExternalReference, SpecialAssumption } from "../../linked-tables";
import { BaseTemplateDataModel } from "./base-template.model";

export class TpTemplateModel implements BaseTemplateDataModel {
    id: number;
    name: string;
    description: string;

    type_id: number;
    property_type_id: number;
    top_property_type_id: number;
    
    custom_asset_class_name: string;
    custom_property_type: string;
    custom_tenure: string;

    premise_of_value_id: number;
    tenure_id: number;

    percentage: number;
    discount: number;
    discount_descr: string;

    country_id: number;
    city_id: number;
    zip_code: string;

    included_all_equipment: number;
    included_all_equipment_descr: string;
    included_furniture: number;
    included_furniture_descr: string;
    natural_accident_consideration: number;
    natural_accident_consideration_descr: string;
    material_accident_consideration: number;
    material_accident_consideration_descr: string;
    diligence_check: number;
    diligence_check_descr: string;
    type_of_inspection: number;
    instructed_to_measure: number;
    standard_measurement_id: number;
    measurement_methodology_id: number;
    purpose_measurement_id: number;
    intended_lender: number;
    specific_req_on_intended_lender: number;
    specific_req_on_intended_lender_descr: string;
    expected_limitations_restrictions: number;
    limitations_and_restrictions: string;

    valuations: ITpValuation[];

    country_currency: string;
    reporting_currency: string;
    currency_exchange: string;

    selectedAssumptions: ITpTemplateSelectedAssumption[];
    customAssumptions: DefaultAssumption[];

    selectedSpecialAssumptions: ITpTemplateSelectedSpecialAssumption[];
    customSpecialAssumptions: SpecialAssumption[];

    selectedDepartures: ITpTemplateSelectedDeparture[];
    customDepartures: Departure[];

    vpgas: LocalVpgaModel[];

    external_refs: ITpTemplateExternalRef[];

    updated_at: Date;
    created_at: Date;

    clear() {
        this.id = undefined;
        this.name = null;
        this.description = null;

        this.type_id = undefined;
        this.property_type_id = undefined;

        this.custom_asset_class_name = null;
        this.custom_property_type = null;
        this.custom_tenure = null;

        this.premise_of_value_id = undefined;
        this.tenure_id = undefined;

        this.percentage = undefined;
        this.discount = undefined;
        this.discount_descr = null;

        this.country_id = undefined;
        this.city_id = undefined;
        this.zip_code = null;

        this.included_all_equipment = 1;
        this.included_all_equipment_descr = null;
        this.included_furniture = 0;
        this.included_furniture_descr = null;
        this.natural_accident_consideration = 0;
        this.natural_accident_consideration_descr = null;
        this.material_accident_consideration = 0;
        this.material_accident_consideration_descr = null;
        this.diligence_check = 0;
        this.diligence_check_descr = null;
        this.type_of_inspection = 1;
        this.instructed_to_measure = 0;
        this.standard_measurement_id = undefined;
        this.measurement_methodology_id = undefined;
        this.purpose_measurement_id = undefined;
        this.intended_lender = 1;
        this.specific_req_on_intended_lender = 0;
        this.specific_req_on_intended_lender_descr = null;
        this.expected_limitations_restrictions = 0;
        this.limitations_and_restrictions = null;

        this.country_currency = null;
        this.reporting_currency = null;
        this.currency_exchange = null;

        this.valuations = [];

        this.selectedAssumptions = [];
        this.customAssumptions = [];

        this.selectedSpecialAssumptions = [];
        this.customSpecialAssumptions = [];

        this.selectedDepartures = [];
        this.customDepartures = [];

        this.external_refs = [];

        this.vpgas = [];
    }
}

export interface ITpValuation {
    id: number;
    methods_to_value_id: number;
    methods_to_value_name: string;
    approaches_to_value_id: number;
    approaches_to_value_name: string;
    
    tenure_id: number;
    tenure_custom: string;
    // interest_valued: number;
    // discount: number;
    // comment: string;
    premise_of_value_id: number;
}

export interface ITpTemplateSelectedAssumption {
    type: number;
    assumption_id: number;
    assumption_name: string;
}

export interface ITpTemplateSelectedSpecialAssumption {
    type: number;
    assumption_id: number;
    assumption_name: string;
}

export interface ITpTemplateSelectedDeparture {
    type: number;
    departure_id: number;
    valuation_standard_id: number;
    departure_name: string;
    valuation_standard_name: string;
}

export interface ITpTemplateExternalRef {
    id: number;
    source_external_reference_id: number,
    source_external_reference: SourceExternalReference,
    reference_available: number,
    assumption: number,
    assumption_descr: string,
    special_assumption: number,
    special_assumption_descr: string
}