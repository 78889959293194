<div class="row" [formGroup]="formGroup" [ngClass]="{'form-disabled': readonly}">
    <div class="col-lg-4" *ngIf="propertySubTypeID == 0">
        <div class="row">
            <div class="col-lg-12">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select formControlName="indoor_garage" 
                        [placeholder]="'Indoor Garage'">
                        <mat-option *ngFor="let item of garageRange" [value]="item.value">
                            {{item.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Indoor garage <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-12" *ngIf="isComplexForm">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput formControlName="indoor_garage_comment" placeholder="Garage comments" />
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="col-lg-4" *ngIf="propertySubTypeID == 0">
        <div class="row">
            <div class="col-lg-12">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select formControlName="outdoor_garage" 
                        [placeholder]="'Outdoor parking'">
                        <mat-option *ngFor="let item of garageRange" [value]="item.value">
                            {{item.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Outdoor parking <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-12" *ngIf="isComplexForm">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput formControlName="outdoor_garage_comment" placeholder="Outdoor parking comments" />
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="col-lg-4" *ngIf="propertySubTypeID != 0">
        <div class="row">
            <div class="col-lg-12">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput formControlName="dedicated_indoor_garage" placeholder="Dedicated Indoor garage" />
                    <mat-error>
                        Dedicated Indoor garage <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-12" *ngIf="isComplexForm">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput formControlName="indoor_garage_comment" placeholder="Dedicated Indoor Garage comments" />
                </mat-form-field>
            </div>
        </div>
    </div>


    <div class="col-lg-4" *ngIf="propertySubTypeID != 0">
        <div class="row">
            <div class="col-lg-12">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput formControlName="dedicated_outdoor_garage" placeholder="Dedicated Outdoor garage" />
                    <mat-error>
                        Dedicated Outdoor garage <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-12" *ngIf="isComplexForm">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput formControlName="outdoor_garage_comment" placeholder="Dedicated Outdoor Garage comments" />
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="col-lg-4" *ngIf="propertySubTypeID != 5">
        <div class="row">
            <ng-container *ngIf="propertySubTypeID == 17">
                <div class="col-lg-12">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select formControlName="garden_id" placeholder="Garden">
                            <mat-option *ngFor="let item of gardens" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            Garden <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-12" *ngIf="isComplexForm">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput formControlName="garden_comment" placeholder="Garden comments" />
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-container *ngIf="propertySubTypeID == 7 || propertySubTypeID == 11 || propertySubTypeID == -1">
                <div class="col-lg-12">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select formControlName="outdoor_space_id" placeholder="Outdoor space">
                            <mat-option *ngFor="let item of outdoorSpaces" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            Outdoor space <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-12" *ngIf="isComplexForm">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput formControlName="outdoor_space_comment" placeholder="Outdoor space comments" />
                    </mat-form-field>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="col-lg-12" *ngIf="propertySubTypeID == 5 || propertySubTypeID == 1 || propertySubTypeID == 3">
        <div>
            <label class="external-area-label-gray">{{ 'APARTMENT.FORM.FIELD.CHARACTERISTICS.EXTERNAL_AREAS.TITLE' | translate }}</label>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                <div *ngFor="let externalArea of externalAreas; let i = index"
                    class="col-4 kt-margin-bottom-20-mobile pt-2">
                    <mat-checkbox
                            [checked]="setSelectedExternalArea(externalArea)"
                            (change)="updateCheckedOptions(externalArea, $event)"
                    >
                    <!-- <mat-checkbox> -->
                        {{externalArea.name}}
                    </mat-checkbox>
                </div>
                </div>
                <mat-error class="tag-custom-error mt-2" *ngIf="externalAreasInValid$ | async">
                    At least one <strong>required</strong>
                </mat-error>
            </div>
            <div class="col-lg-12" *ngIf="isComplexForm">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput formControlName="external_area_comment" placeholder="External area comments" />
                </mat-form-field>
            </div>
        </div>
    </div>
</div>