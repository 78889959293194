import { BaseTemplateDataModel } from "./base-template.model";

export class ToeTemplateModel implements BaseTemplateDataModel {
    id: number;
    name: string;
    description: string;
    agency_id: number;

    status_of_valuer: number;

    purpose_of_valuation_id: number;
    purpose_of_valuation_reason: string;

    base_of_value_id: number;

    restrictions_on_use: number;
    restrictions_on_use_intended_users: string;

    responsibilities_liabilities: number;
    responsibilities_liabilities_descr: string;

    limitations_on_liability: number;
    limitations_on_liability_descr: string;

    generic_one: number;
    generic_one_descr: string;

    valuation_standards: ToeTemplateValuationStandard[];

    unit_of_measurement_id: number;
    law_country_id: number;

    client_id: number;
    contact_id: number;

    members: ITeamMember[];
    selected_agency_documents: number[];

    // Conflict of Interests
    has_prev_knowledge: number;
    has_financial_interest: number;

    previous_work: number;
    previous_work_description: string;
    previous_work_coi: number;
    previous_work_type_of_conflict_id: number;
    previous_work_appropriate_parties: string;
    previous_work_reason_continue: string;
    previous_work_description_b: string;

    personal_work: number;
    personal_work_description: string;
    personal_work_coi: number;
    personal_work_type_of_conflict_id: number;
    personal_work_appropriate_parties: string;
    personal_work_reason_continue: string;
    personal_work_description_b: string;

    employees_knows_each_other: number;
    employees_knows_each_other_description: string;
    employees_knows_each_other_coi: number;
    employees_knows_each_other_type_of_conflict_id: number;
    employees_knows_each_other_appropriate_parties: string;
    employees_knows_each_other_reason_continue: string;
    employees_knows_each_other_description_b: string;

    interest_valuation: number;
    interest_valuation_description: string;

    mad_acting: number;
    mad_acting_description: string;

    asset_previously_valuated: number;
    asset_previously_valuated_description: string;

    mad_third_party: number;
    mad_third_party_description: string;
    mad_third_party_coi: number;
    mad_third_party_type_of_conflict_id: number;
    mad_third_party_appropriate_parties: string;
    mad_third_party_reason_continue: string;
    mad_third_party_description_b: string;

    employees_knowledge: number;
    employees_knowledge_description: string;

    conflicted_projects: number;
    conflicted_projects_description: string;
    conflicted_projects_b: number;
    conflicted_projects_coi: number;
    conflicted_projects_type_of_conflict_id: number;
    conflicted_projects_appropriate_parties: string;
    conflicted_projects_reason_continue: string;
    conflicted_projects_description_b: string;

    common_staff: number;
    common_staff_description: string;
    common_staff_coi: number;
    common_staff_type_of_conflict_id: number;
    common_staff_appropriate_parties: string;
    common_staff_reason_continue: string;
    common_staff_description_b: string;

    fee_sharing: number;
    fee_sharing_description: string;
    fee_sharing_coi: number;
    fee_sharing_type_of_conflict_id: number;
    fee_sharing_appropriate_parties: string;
    fee_sharing_reason_continue: string;
    fee_sharing_description_b: string;

    valuation_significant_revenue: number;
    valuation_significant_revenue_description: string;
    valuation_significant_revenue_coi: number;
    valuation_significant_revenue_type_of_conflict_id: number;
    valuation_significant_revenue_appropriate_parties: string;
    valuation_significant_revenue_reason_continue: string;
    valuation_significant_revenue_description_b: string;

    verbal_value: number;
    verbal_value_description: string;

    valuing_for_lender: number;
    valuing_for_lender_description: string;
    valuing_for_lender_coi: number;
    valuing_for_lender_type_of_conflict_id: number;
    valuing_for_lender_appropriate_parties: string;
    valuing_for_lender_reason_continue: string;
    valuing_for_lender_description_b: string;

    long_standing_professional: number;
    long_standing_professional_description: string;

    introducing_transaction: number;
    introducing_transaction_description: string;

    financial_interest: number;
    financial_interest_description: string;
    financial_interest_b: number;
    financial_interest_coi: number;
    financial_interest_type_of_conflict_id: number;
    financial_interest_appropriate_parties: string;
    financial_interest_reason_continue: string;
    financial_interest_description_b: string;

    acting_for: number;
    acting_for_description: string;
    acting_for_coi: number;
    acting_for_type_of_conflict_id: number;
    acting_for_appropriate_parties: string;
    acting_for_reason_continue: string;
    acting_for_description_b: string;

    acting_for_borrower: number;
    acting_for_borrower_description: string;

    retained_to_act: number;
    retained_to_act_description: string;

    recently_acted: number;
    recently_acted_description: string;

    professional_advice: number;
    professional_advice_description: string;

    providing_development_consultancy: number;
    providing_development_consultancy_description: string;
    providing_development_consultancy_coi: number;
    providing_development_consultancy_type_of_conflict_id: number;
    providing_development_consultancy_appropriate_parties: string;
    providing_development_consultancy_reason_continue: string;
    providing_development_consultancy_description_b: string;

    valuer_identify_conflict: string;
    valuer_identify_conflict_description: string;
    valuer_identify_conflict_coi: number;
    valuer_identify_conflict_type_of_conflict_id: number;
    valuer_identify_conflict_appropriate_parties: string;
    valuer_identify_conflict_reason_continue: string;
    valuer_identify_conflict_description_b: string;

    valuer_doubts_ability: number;
    valuer_doubts_ability_description: string;
    valuer_doubts_ability_type_of_conflict_id: number;
    valuer_doubts_ability_appropriate_parties: string;
    valuer_doubts_ability_reason_continue: string;

    // Disclosures
    client_informed_complain: number;

    not_having_the_level: number;
    not_having_the_level_description: string;

    valuer_employ_another_firm: number;
    valuer_employ_another_firm_description: string;

    valuation_relied_on_other_parties: number;
    valuation_relied_on_other_parties_description: string;

    asset_previously_valued: number;
    asset_previously_valued_description: string;
    asset_previously_valued_by_the_member: string;

    valuer_involved_in_purchase: number;
    valuer_involved_in_purchase_description: string;
    valuer_involved_in_purchase_files: IFile[];

    valuer_provided_valuations: number;
    valuer_provided_valuations_description: string;

    valuer_signatory: number;
    valuer_signatory_description: string;

    valuer_involved_professional_services: string;
    valuer_involved_professional_services_description: string;

    valuer_involved_12_month_before: number;
    valuer_involved_12_month_before_description: string;

    purpose_of_valuation: number;
    purpose_of_valuation_description: string;

    // Delivery details
    deliveries: number[];
    support_clarifications: string;

    payment: ITemplatePayment;
    paymentTerms: ITemplatePaymentTerm[];
    paymentTaxes: ITemplatePaymentTaxes[];

    otherDocuments: IFile[];

    created_at: Date;
    updated_at: Date;

    constructor() {
        this.id = undefined;
        this.name = null;
        this.description = null;
        this.agency_id = undefined;

        this.status_of_valuer = 0;

        this.purpose_of_valuation_id = 0;
        this.purpose_of_valuation_reason = null;

        this.base_of_value_id = 0;
        
        this.restrictions_on_use = 0;
        this.restrictions_on_use_intended_users = null;

        this.responsibilities_liabilities = 0;
        this.responsibilities_liabilities_descr = null;

        this.limitations_on_liability = 0;
        this.limitations_on_liability_descr = null;

        this.generic_one = 0;
        this.generic_one_descr = null;

        this.valuation_standards = [];

        this.unit_of_measurement_id = 0;
        this.law_country_id = 0;

        this.client_id = 0;
        this.contact_id = 0;

        this.members = [];

        this.has_prev_knowledge = 0;
        this.has_financial_interest = 0;

        this.previous_work = 0;
        this.previous_work_description = '';
        this.previous_work_coi = 0;
        this.previous_work_type_of_conflict_id = 0;
        this.previous_work_appropriate_parties = '';
        this.previous_work_reason_continue = '';
        this.previous_work_description_b = '';

        this.personal_work = 0;
        this.personal_work_description = '';
        this.personal_work_coi = 0;
        this.personal_work_type_of_conflict_id = 0;
        this.personal_work_appropriate_parties = '';
        this.personal_work_reason_continue = '';
        this.personal_work_description_b

        this.employees_knows_each_other = 0;
        this.employees_knows_each_other_description = '';
        this.employees_knows_each_other_coi = 0;
        this.employees_knows_each_other_type_of_conflict_id = 0;
        this.employees_knows_each_other_appropriate_parties = '';
        this.employees_knows_each_other_reason_continue = '';
        this.employees_knows_each_other_description_b = '';

        this.interest_valuation = 0;
        this.interest_valuation_description = '';

        this.mad_acting = 0;
        this.mad_acting_description = '';

        this.asset_previously_valuated = 0;
        this.asset_previously_valuated_description = '';

        this.mad_third_party = 0;
        this.mad_third_party_description = '';
        this.mad_third_party_coi = 0;
        this.mad_third_party_type_of_conflict_id = 0;
        this.mad_third_party_appropriate_parties = '';
        this.mad_third_party_reason_continue = '';
        this.mad_third_party_description_b = '';

        this.employees_knowledge = 0;
        this.employees_knowledge_description = '';

        this.conflicted_projects = 0;
        this.conflicted_projects_description = '';
        this.conflicted_projects_b = 0;
        this.conflicted_projects_coi = 0;
        this.conflicted_projects_type_of_conflict_id = 0;
        this.conflicted_projects_appropriate_parties = '';
        this.conflicted_projects_reason_continue = '';
        this.conflicted_projects_description_b = '';

        this.common_staff = 0;
        this.common_staff_description = '';
        this.common_staff_coi = 0;
        this.common_staff_type_of_conflict_id = 0;
        this.common_staff_appropriate_parties = '';
        this.common_staff_reason_continue = '';
        this.common_staff_description_b = '';

        this.fee_sharing = 0;
        this.fee_sharing_description = '';
        this.fee_sharing_coi = 0;
        this.fee_sharing_type_of_conflict_id = 0;
        this.fee_sharing_appropriate_parties = '';
        this.fee_sharing_reason_continue = '';
        this.fee_sharing_description_b = '';

        this.valuation_significant_revenue = 0;
        this.valuation_significant_revenue_description = '';
        this.valuation_significant_revenue_coi = 0;
        this.valuation_significant_revenue_type_of_conflict_id = 0;
        this.valuation_significant_revenue_appropriate_parties = '';
        this.valuation_significant_revenue_reason_continue = '';
        this.valuation_significant_revenue_description_b = '';

        this.verbal_value = 0;
        this.verbal_value_description = '';

        this.valuing_for_lender = 0;
        this.valuing_for_lender_description = '';
        this.valuing_for_lender_coi = 0;
        this.valuing_for_lender_type_of_conflict_id = 0;
        this.valuing_for_lender_appropriate_parties = '';
        this.valuing_for_lender_reason_continue = '';

        this.long_standing_professional = 0;
        this.long_standing_professional_description = '';

        this.introducing_transaction = 0;
        this.introducing_transaction_description = '';

        this.financial_interest = 0;
        this.financial_interest_description = '';
        this.financial_interest_b = 0;
        this.financial_interest_coi = 0;
        this.financial_interest_type_of_conflict_id = 0;
        this.financial_interest_appropriate_parties = '';
        this.financial_interest_reason_continue = '';

        this.acting_for = 0;
        this.acting_for_description = '';
        this.acting_for_coi = 0;
        this.acting_for_type_of_conflict_id = 0;
        this.acting_for_appropriate_parties = '';
        this.acting_for_reason_continue = '';

        this.acting_for_borrower = 0;
        this.acting_for_borrower_description = '';

        this.retained_to_act = 0;
        this.retained_to_act_description = '';

        this.recently_acted = 0;
        this.recently_acted_description = '';

        this.professional_advice = 0;
        this.professional_advice_description = '';

        this.providing_development_consultancy = 0;
        this.providing_development_consultancy_description = '';
        this.providing_development_consultancy_coi = 0;
        this.providing_development_consultancy_type_of_conflict_id = 0;
        this.providing_development_consultancy_appropriate_parties = '';
        this.providing_development_consultancy_reason_continue = '';

        this.valuer_identify_conflict = '';
        this.valuer_identify_conflict_description = '';
        this.valuer_identify_conflict_coi = 0;
        this.valuer_identify_conflict_type_of_conflict_id = 0;
        this.valuer_identify_conflict_appropriate_parties = '';
        this.valuer_identify_conflict_reason_continue = '';

        this.valuer_doubts_ability = 0;
        this.valuer_doubts_ability_description = '';
        this.valuer_doubts_ability_type_of_conflict_id = 0;
        this.valuer_doubts_ability_appropriate_parties = '';
        this.valuer_doubts_ability_reason_continue = '';

        this.client_informed_complain = 0;

        this.not_having_the_level = 0;
        this.not_having_the_level_description = '';

        this.valuer_employ_another_firm = 0;
        this.valuer_employ_another_firm_description = '';

        this.valuation_relied_on_other_parties = 0;
        this.valuation_relied_on_other_parties_description = '';

        this.asset_previously_valued = 0;
        this.asset_previously_valued_description = '';
        this.asset_previously_valued_by_the_member = '';

        this.valuer_involved_in_purchase = 0;
        this.valuer_involved_in_purchase_description = '';
        this.valuer_involved_in_purchase_files = [];

        this.valuer_provided_valuations = 0;
        this.valuer_provided_valuations_description = '';

        this.valuer_signatory = 0;
        this.valuer_signatory_description = '';

        this.valuer_involved_professional_services = '';
        this.valuer_involved_professional_services_description = '';

        this.valuer_involved_12_month_before = 0;
        this.valuer_involved_12_month_before_description = '';

        this.purpose_of_valuation = 0;
        this.purpose_of_valuation_description = '';

        this.deliveries = []
        this.support_clarifications = '';

        this.payment = {
            id: undefined,
            template_id: undefined,
            discount: 0,
            vat: 0,
            vat_other: 0,
            currency: '',
            total_price: 0
        }
        this.paymentTerms = [];
        this.paymentTaxes = [];

        this.otherDocuments = [];

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}

export interface ToeTemplateValuationStandard {
    id: number;
    template_id: number;
    valuation_standard_id: number;
}

export interface ITeamMember {
    id: number;
    template_id: number;
    worker_id: number;
    lead_valuer: number;
    selected_document: any[];
    worker?: any;
    assigned_default_tasks: number[];
    selected_certificate: any[];
}

export interface ITemplatePayment {
    id: number;
    template_id: number;
    discount: number;
    vat: number;
    vat_other: number;
    currency: string;
    total_price: number;
}

export interface ITemplatePaymentTerm {
    id: number;
    template_id: number;
    title: string;
    percent: number;
    amount: number;
    order: number;
    payment_date: string;
}

export interface ITemplatePaymentTaxes {
    id: number;
    template_id: number
    name: string;
    qty: number;
    amount: number;
}

export interface IFile {
    id: number;
    path: string;
    name: string;
    title: string;
    descr: string;
}