import {
    Component, OnDestroy, OnInit, Input, AfterViewInit, ChangeDetectorRef
} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, merge, Subscription} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {LayoutUtilsService, TypesUtilsService} from '../../../../../core/_base/crud';
import {AppState} from '../../../../../core/reducers';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {FileUploadService} from '../../../../../core/file-upload/_services';
import {AddPaymentTermDialogComponent} from '../add-payment-term-dialog/add-payment-term-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {PaymentTermModel} from '../../../../../core/asset_class';

@Component({
    selector: 'kt-ac-payment-terms-alt2',
    templateUrl: './ac-payment-terms-alt.component.html',
    styleUrls: ['./ac-payment-terms-alt.component.scss'],
})
export class AcPaymentTermsAltComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() loadingSubject = new BehaviorSubject<boolean>(false);
    @Input() paymentTermsSubject: BehaviorSubject<PaymentTermModel[]>;
    @Input() totalPrice: BehaviorSubject<number>;
    @Input() currencySubject: BehaviorSubject<string>;
    @Input() isTemplate: boolean;
    @Input() readonly: boolean;
    @Input() instruction_date: Date;
    @Input() timezoneOffset: string | null;

    displayedColumns = ['title', 'payment_date', 'percent', 'amount', 'actions'];
    dataSource = new MatTableDataSource<PaymentTermModel>();
    paymentTermMin = 1;
    paymentTermMax = 10;
    formGroup: UntypedFormGroup;
    prevDate: Date;
    nextDate: Date;
    termsPercent = 0;
    paymentDateValid = true;
    message = "A payment term's date cannot be earlier than the preceding payment term's date";

    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param fileUploadService: FileUploadService
     * @param dialog: MatDialog
     * @param layoutUtilsService: LayoutUtilsService
     * @param store: Store
     * @param layoutConfigService: LayoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private fileUploadService: FileUploadService,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService,
                private changeDetectorRefs: ChangeDetectorRef) {
        // this.saveState = false;
    }

    ngOnInit() {
        this.formGroup = this.fb.group({
            payment_term: [this.paymentTermsSubject.value.length, Validators.compose([Validators.min(this.paymentTermMin),Validators.max(this.paymentTermMax)])],
            terms: this.fb.array([])
        });

        this.formGroup.get('terms').valueChanges.subscribe(changes => {
            let _data = Object.assign([], this.paymentTermsSubject.value);
            this.termsPercent = 0;
            const length = this.terms.length;

            changes.forEach((element, index) => {
                this.termsPercent += Number(element.percent);
                element.amount =  Number(this.totalPrice.value) * element.percent / 100;

                _data = _data.map((term, i) => {
                    let temp = Object.assign({}, term);
                    if (i == index) {
                        const _paymentDate = element.payment_date ? new Date(element.payment_date) : null;
                        temp.payment_date = _paymentDate ? this.typesUtilsService.dateFormat(_paymentDate) : null;
                        temp.percent = element.percent;
                        temp.amount = Number(this.totalPrice.value) * element.percent / 100;
                    }
                    return temp;
                });
            });
            
            for (let index = 0; index < changes.length; index++) {
                if (changes[index].payment_date == null) {
                    this.paymentDateValid = false;
                    break;
                } else {
                    this.paymentDateValid = true;
                }
                if (index < (length - 1)) {
                    this.dateCompare(changes[index].payment_date, changes[index + 1].payment_date, index);
                    if (!this.paymentDateValid) {
                        break;
                    }
                } else if (index != 0) {
                    this.dateCompare(changes[index - 1].payment_date, changes[index].payment_date, index);
                    if (!this.paymentDateValid) {
                        break;
                    }
                }
            }

            this.paymentTermsSubject.next(_data);
        });

        if (this.isTemplate) {
            this.displayedColumns = ['title', 'percent', 'amount', 'actions']
        }
        if (this.paymentTermsSubject.value.length == 0) {
            const _data: PaymentTermModel[] = [];
            const temp = new PaymentTermModel();
            temp.clear();
            temp.title = 'Final Payment Term';
            temp.percent = 100;
            temp.amount = Number(this.totalPrice.value) * temp.percent / 100;
            temp.payment_date = null;
            temp.order = 1;
            _data.push(temp);

            this.paymentTermsSubject.next(_data);
            this.formGroup.controls.payment_term.setValue(1);
        }
        let terms = this.paymentTermsSubject.value.slice()
        terms.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
        this.dataSource = new MatTableDataSource(terms);
        this.paymentTermsSubject.next(terms);

        this.paymentTermsSubject.value.forEach(element => {
            const termForm = this.fb.group({
                percent: [element.percent, Validators.required],
                payment_date: [element.payment_date ? this.typesUtilsService.getDateFromString(element.payment_date) : null, Validators.required],
                title: [element.title],
                amount: [element.amount],
                order: [element.order],
            });
          
            this.terms.push(termForm);
        });
    }

    ngAfterViewInit() {
        const paymentTaxesSubjectSubscription = this.paymentTermsSubject.subscribe(res => {
            let terms = res.slice();
            terms.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            this.dataSource.data = terms;
            this.changeDetectorRefs.detectChanges();
        });
        this.subscriptions.push(paymentTaxesSubjectSubscription);

        const totalPriceSubscription = this.totalPrice.asObservable()
            .pipe(
                distinctUntilChanged())
            .subscribe(totalPriceChanges => {
                const _data = Object.assign([], this.paymentTermsSubject.value);
                const tempTerms = _data.map(term => {
                    let temp = Object.assign({}, term);
                    temp.amount = Number(totalPriceChanges) * temp.percent / 100;
                    return temp;
                });
                this.paymentTermsSubject.next(tempTerms);
            });
        this.subscriptions.push(totalPriceSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    addItem(termlength: number) {
        if (termlength == 0) {
            this.formGroup.controls.payment_term.setValue(1);
            termlength = 1;
        }
        let value = termlength;
        let _data = Object.assign([], this.paymentTermsSubject.value);

        if (this.paymentTermsSubject.value.length < value) {
            // adding new element
            let paymentTermCnt = value > this.paymentTermMax ? this.paymentTermMax : value;
            for (let index = this.terms.length; index < paymentTermCnt; index++) {
                let newPayment = new PaymentTermModel();
                newPayment.clear();
                let temp: PaymentTermModel = Object.assign({}, newPayment);
                temp.title = 'Payment Term-' + this.terms.length;
                temp.amount = Number(this.totalPrice.value) * temp.percent / 100;
                temp.order = this.terms.length;
                _data.splice(this.paymentTermsSubject.value.length - 1, 0, temp);

                const termForm = this.fb.group({
                    percent: [temp.percent, Validators.required],
                    payment_date: [temp.payment_date, Validators.required],
                    title: [temp.title],
                    amount: [temp.amount],
                    order: [temp.order],
                });
              
                this.terms.insert(this.terms.length - 1, termForm);
                // editing last elements order
                _data = _data.map((term, index) => {
                    let temp = Object.assign({}, term);
                    temp.order = (index + 1);
                    return temp;
                });
            }
            this.paymentTermsSubject.next(_data);
        } else {
            // subtract
            for (let i = this.terms.length; i > value; i--) {
                _data.splice(0, 1);
                this.terms.removeAt(0);
            }

            _data = _data.map((term, index) => {
                if (_data.length != (index + 1)) {
                    let temp = Object.assign({}, term);
                    temp.title = 'Term-' + (index + 1);
                    temp.order = (index + 1);
                    return temp;
                } else {
                    let temp = Object.assign({}, term);
                    temp.order = (index + 1);
                    return temp;
                }
            });
            this.paymentTermsSubject.next(_data);
        }
    }

    clearDate(i) {
        this.paymentDateValid = false;
        const fg = this.terms.controls[i] as UntypedFormGroup;
        fg.controls.payment_date.setValue(null);
        let _data = Object.assign([], this.paymentTermsSubject.value);
        _data = _data.map((term, index) => {
            let temp = Object.assign({}, term);
            if (i == index) {
                temp.payment_date = null;
            }
            return temp;
        });
        this.paymentTermsSubject.next(_data);
    }

    get terms() {
        return this.formGroup.controls['terms'] as UntypedFormArray;
    }

    dateCompare(d1, d2, index) {
        let instruction_date = new Date(this.instruction_date);
        instruction_date.setHours(0,0,0,0);
        let date1 = new Date(d1);
        let date2 = new Date(d2);
        date1.setHours(0,0,0,0);
        date2.setHours(0,0,0,0);
    
        if (date1 > date2) {
            this.paymentDateValid = false;
            this.message = "A payment term's date cannot be earlier than the preceding payment term's date";
        } else if (date1 <= date2) {
            this.paymentDateValid = true;
            if (index == 0 && instruction_date <= date1) {
                this.paymentDateValid = true;
            } else if (index == 0 && instruction_date > date1) {
                this.paymentDateValid = false;
                this.message = '1st term date cannot be earlier than instruction date: ' + this.typesUtilsService.getDateStringFromDate(instruction_date);
            }
        } else {
            this.paymentDateValid = false;
            this.message = "A payment term's date cannot be earlier than the preceding payment term's date";
        }
    }
}
