// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
// CRUD
import { HttpUtilsService } from '../../_base/crud';
// Models
import { environment } from '../../../../environments/environment';
import { ConflictModel } from '../_models/conflict.model';
import { ConflictType } from '../_static-data/conflict-type';
import { ConflictOfInterestModel } from '../_models/conflict-of-interest.model';
import { ToeModel } from '../';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class ConflictService {


    API_CONFLICT_UR = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/conflict`;
    API_DISCLOSURE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/disclosure`;
    constructor(
        private http: HttpClient, 
        // private authService: MadAuthService
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {

        this.conflictResult$ = this.conflictResultSubject.asObservable();
        this.disclosureResult$ = this.disclosureResultSubject.asObservable();
        this.conflictData$ = this.conflictDataSubject.asObservable();
        this.disclosureData$ = this.disclosureDataSubject.asObservable();
    }

    conflictResult$: Observable<ConflictModel[]>;
    disclosureResult$: Observable<ConflictModel[]>;

    conflictData$: Observable<ConflictOfInterestModel[]>;
    disclosureData$: Observable<ConflictOfInterestModel[]>;

    conflictResultSubject = new BehaviorSubject<ConflictModel[]>([]);
    disclosureResultSubject = new BehaviorSubject<ConflictModel[]>([]);

    conflictDataSubject = new BehaviorSubject<ConflictOfInterestModel[]>([]);
    disclosureDataSubject = new BehaviorSubject<ConflictOfInterestModel[]>([]);


    allConflicts: ConflictOfInterestModel[] = [];
    allDisclosures: ConflictOfInterestModel[] = [];
    fileCntSubject = new BehaviorSubject<number>(0);

    purposeValuationIndex = 0;
    valuationReliedOnOtherPartiesIndex = undefined;

    exclusiveConflicts = [];

    exclusiveDisclosures = [
        'asset_previously_valued',
        'valuer_involved_in_purchase',
        'valuer_provided_valuations',
        'valuer_signatory',
        'valuer_involved_professional_services',
        'valuer_involved_12_month_before',
    ];

    static hasConflict(toe: ToeModel): boolean {
        const list = [
            'previous_work',
            'personal_work',
            'employees_knows_each_other',
            'interest_valuation',
            'mad_acting',
            'asset_previously_valuated',
            'mad_third_party',
            'employees_knowledge',
            'conflicted_projects',
            'common_staff',
            'fee_sharing',
            'valuation_significant_revenue',
            'verbal_value',
            'valuing_for_lender',
            'long_standing_professional',
            'introducing_transaction',
            'financial_interest',
            'acting_for',
            'acting_for_borrower',
            'retained_to_act',
            'recently_acted',
            'professional_advice',
            'providing_development_consultancy',
            'client_informed_complain',
            'valuer_identify_conflict',
            'valuer_doubts_ability',
        ];

        for (const item of list) {
            if (toe[item] == 1 || toe[item] == 2) {
                return true;
            }
        }
        return false;
    }


    /*      conflict functions      */

    setConflictData(_coi: ConflictOfInterestModel[]) {
        this.allConflicts = _coi;
        this.conflictDataSubject.next(this.allConflicts);
    }

    setConflictResult(conflicts: ConflictModel[]) {
        this.conflictResultSubject.next(Object.assign([], conflicts));
    }

    updateConflictResult(_conflict: ConflictModel) {
        const items = this.conflictResultSubject.value;
        items.forEach((item, index) => {
            if (item.prefix == _conflict.prefix) {
                items[index] = _conflict;
            }
        });
        this.conflictResultSubject.next(Object.assign([], items));
    }

    getConflict(prefix: string): ConflictModel {
        const _new = new ConflictModel();
        _new.clear();
        let items = [];
        if (this.conflictResultSubject && this.conflictResultSubject.value) {
            items = this.conflictResultSubject.value;
        }
        return items.find(item => item.prefix == prefix) || _new;
    }


    changeValidConflicts() {
        const _conflicts = Object.assign([], this.conflictResultSubject.value) as ConflictModel[];
        let cnt = 0;
        let isValid: boolean;
        _conflicts.forEach((value, index) => {
            const explainOptionPrefix = ConflictType.conflictTypes[value.optionType - 1].explainOptionPrefix;
            const furtherExplainOptionPrefix = ConflictType.conflictTypes[value.optionType_a - 1].explainOptionPrefix;

            _conflicts[index]._isValid = explainOptionPrefix.indexOf(value.optionPrefix) > -1 ? false : true;
            // Check if option prefix is Yes
            if (!_conflicts[index]._isValid) {
                // Check if second option type is 6
                if (value.optionType_a === 6) {
                    _conflicts[index]._isValid = true;
                } else {
                    _conflicts[index]._isValid = furtherExplainOptionPrefix.indexOf(value.optionPrefix_a) > -1
                        ? false : true;
                    // Check if second option prefix is Yes or potential
                    if (!_conflicts[index]._isValid) {
                        isValid = value.text && value.text.length >= 20 ? true : false;
                        isValid = isValid && value.text2 && value.text2.length >= 20
                            && value.appropriate_parties
                            && value.reason_continue ? true : false;
                        _conflicts[index]._isValid = isValid;
                        if (!isValid) {
                            cnt++;
                        }
                    }
                }
            }
        }
        );
        this.setConflictResult(_conflicts);
        return cnt < 1;
    }


    /*          disclosure functions        */

    setDisclosureData(_dis: ConflictOfInterestModel[]) {
        this.allDisclosures = _dis.filter(dis => dis.name !== "valuation_relied_on_other_parties");
        this.reloadDisclosure()
    }

    setDisclosureResult(disclosures: ConflictModel[]) {
        this.disclosureResultSubject.next(Object.assign([], disclosures));
    }


    updateDisclosureResult(_disclosure: ConflictModel) {
        const items = this.disclosureResultSubject.value;
        items.forEach((item, index) => {
            if (item.prefix == _disclosure.prefix) {
                items[index] = _disclosure;
            }
        });
        this.disclosureResultSubject.next(Object.assign([], items));
    }


    getDisclosure(prefix: string): ConflictModel {
        const _new = new ConflictModel();
        _new.clear();
        let items = [];
        if (this.disclosureResultSubject && this.disclosureResultSubject.value) {
            items = this.disclosureResultSubject.value;
        }
        return items.find(item => item.prefix == prefix) || _new;
    }


    changedPurposeValuation(index: number) {

        if (index === this.purposeValuationIndex) {
            return;
        }
        this.purposeValuationIndex = index;
        this.reloadDisclosure();
    }

    reloadDisclosure() {
        const items: ConflictOfInterestModel[] = [];
        this.allDisclosures.forEach(value => {
            if (this.valuationReliedOnOtherPartiesIndex < 1 && this.exclusiveDisclosures.indexOf(value.name) > -1) {
                return;
            }

            if (this.purposeValuationIndex != 8 && value.name == 'purpose_of_valuation') {
                return;
            }
            items.push(value);
        });
        this.disclosureDataSubject.next(items);
    }

    changeValidOfDisclosures() {

        const _disclosureData = Object.assign([], this.disclosureDataSubject.value) as ConflictOfInterestModel[];
        const _disclosures = Object.assign([], this.disclosureResultSubject.value) as ConflictModel[];
        let cnt = 0;
        _disclosureData.forEach((data, index) => {

            const value = _disclosures.find(el => el.prefix == data.name);
            const explainOptionPrefix = ConflictType.conflictTypes[value.optionType - 1].explainOptionPrefix;

            _disclosures[index]._isValid = explainOptionPrefix.indexOf(value.optionPrefix) > -1
                ? value.prefix == 'purpose_of_valuation'
                    ? value.text != "" && value.text != undefined
                    : (value.text && value.text.length >= 20)
                : true;
            if (!_disclosures[index]._isValid) {
                if (value.prefix == 'purpose_of_valuation' && value.index < 1) {
                    _disclosures[index]._isValid = true;
                } else {
                    cnt++;
                }
            }
        }
        );
        this.disclosureResultSubject.next(_disclosures);
        return cnt < 1;
    }

    changedValueOfDisclosure(name: string, index: number) {
        if (name == 'valuation_relied_on_other_parties') {
            // if (index === this.valuationReliedOnOtherPartiesIndex) {
            //     return;
            // }
            this.valuationReliedOnOtherPartiesIndex = index;

            this.reloadDisclosure();
        }
    }

    /*    API functions   */

    // READ
    getAllConflict(): Observable<any> {
        // const httpHeaders = this.authService.getAuthHeaders();
        const httpHeaders = this.authService.getAuthHeaders()
        return this.http.get<any>(this.API_CONFLICT_UR, { headers: httpHeaders });
    }

    // READ
    getAllDisclosure(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_DISCLOSURE_URL, { headers: httpHeaders });
    }

    // other
    valuerDoubtsAbilityValue(): boolean {
        const items = this.conflictResultSubject.value;
        for (const item of items) {
            // items
            if (item.prefix == 'valuer_doubts_ability' && item.index != 0) {
                return true;
            }
        }

        return false;
    }
}
