// Angular
import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {NotificationService} from '../../../../../core/auth/_services';
import {AppState} from '../../../../../core/reducers';
import {select, Store} from '@ngrx/store';
import {selectNewNotificationCount, selectNotificationInStore} from '../../../../../core/auth/_selectors/notification.selectors';
import {QueryResultsModel} from '../../../../../core/_base/crud';
import {NotificationModel} from '../../../../../core/auth/_models/notification.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Update} from '@ngrx/entity';
import {NotificationClear, NotificationDeleted, NotificationUpdated} from '../../../../../core/auth/_actions/notificaiton.actions';
import {Router} from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
    selector: 'kt-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['notification.component.scss']
})
export class NotificationComponent implements OnInit {

    // Show dot on top of the icon
    @Input() dot: string;

    // Show pulse on icon
    @Input() pulse: boolean;

    @Input() pulseLight: boolean;

    // Set icon class name
    @Input() icon = 'flaticon2-bell-alarm-symbol';
    @Input() iconType: '' | 'success';

    // Set true to icon as SVG or false as icon class
    @Input() useSVG: boolean;

    // Set bg image path
    @Input() bgImage: string;

    // Set skin color, default to light
    @Input() skin: 'light' | 'dark' = 'light';

    @Input() type: 'brand' | 'success' = 'success';


    cnt: Observable<number> = this.store.pipe(select(selectNewNotificationCount));
    pulsing$ = this.cnt.pipe(map(cnt => cnt > 0))
    entitySubject = new BehaviorSubject<NotificationModel[]>([]);
// <!--<i *ngIf="[1, 4].indexOf(item.type) >= 0" class="flaticon2-add kt-font-success"></i>-->
//     <!--<i *ngIf="[1, 4, 10, 11, 12, 21, 22, 23, 24, 25, 26, 27, 28, 29, 31, 32, 33, 35, 36].indexOf(item.type) < 0"-->
//     <!--class="flaticon-users-1 kt-font-warning"></i>-->
//     <!--<i *ngIf="[21, 22, 23].indexOf(item.type) >= 0" class="flaticon-upload kt-font-primary"></i>-->
//     <!--<i *ngIf="[31, 32].indexOf(item.type) >= 0" class="flaticon-exclamation-1 kt-font-info"></i>-->
//     <!--<i *ngIf="[10].indexOf(item.type) >= 0" class="flaticon-edit kt-font-primary"></i>-->
//     <!--<i *ngIf="[35, 36].indexOf(item.type) >= 0" class="flaticon-reply kt-font-info"></i>-->
//     <!--<i *ngIf="[11, 12, 24, 25, 26, 27, 28, 29, 33].indexOf(item.type) >= 0" class="flaticon-delete-1 kt-font-danger"></i>-->

    /**
     * Component constructor
     *
     * @param sanitizer: DomSanitizer
     * @param service: NotificationService
     */
    constructor(private sanitizer: DomSanitizer,
                private router: Router,
                private service: NotificationService,
                private store: Store<AppState>) {
    }

    ngOnInit(): void {

        this.store.pipe(select(selectNotificationInStore))
            .subscribe((response: QueryResultsModel) => {
                this.entitySubject.next(response.items);
            });

        this.cnt = this.store.pipe(select(selectNewNotificationCount));
    }

    goToLink(item: NotificationModel) {

        // myDrop.close();
        if (item.is_new) {
            const oldItem = Object.assign({}, item) as NotificationModel;
            oldItem.is_new = false;
            const updatedItem: Update<NotificationModel> = {
                id: oldItem.id,
                changes: oldItem
            };

            this.store.dispatch(new NotificationUpdated({
                partialItem: updatedItem,
                item: oldItem
            }));
        }
        if (item.link && item.link.length > 0) {
            this.router.navigateByUrl(item.link);
        }

    }

    removeOne(id) {
        this.store.dispatch(new NotificationDeleted({id}));
    }

    removeAll() {
        this.store.dispatch(new NotificationClear());
        this.service.flush().subscribe(res => {
        });
    }


    backGroundStyle(): string {
        if (!this.bgImage) {
            return 'none';
        }

        return 'url(' + this.bgImage + ')';
    }
}
