import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { LandmarksService } from './landmarks.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { AllKeyCategoriesRequested, KeyCategoryModel, selectAllKeyCategories } from 'src/app/core/linked-tables';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { AllKeyPlacesRequested, KeyPlaceModel, KeyPlacesPageRequested, selectAllKeyPlaces } from 'src/app/core/admin';
import { QueryParamsModel } from 'src/app/core/_base/crud';
import { environment } from 'src/environments/environment';
import { awConst } from 'src/app/app.constants';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { KeyplaceModalComponent } from '../valuation-process-edit/_sub/keyplace-tab/keyplace-modal/keyplace-modal.component';
import { MapsService } from 'src/app/core/map/maps.service';

@Component({
  selector: 'kt-landmarks-page',
  templateUrl: './landmarks-page.component.html',
  styleUrls: ['./landmarks-page.component.scss'],
  providers: [LandmarksService]
})
export class LandmarksPageComponent implements OnInit {
  private _centerLat$ = new BehaviorSubject<number>(null)
  centerLat$ = combineLatest([
    this._centerLat$,
    this.service.targetPropertyLocation$.pipe(
      filter(center => center !== null),
      map(center => center.latitude)
    )
  ]).pipe(map(([initial, def]) => {
    return initial == null ? def : initial
  }))
  private _centerLng$ = new BehaviorSubject<number>(null)
  centerLng$ = combineLatest([
    this._centerLng$,
    this.service.targetPropertyLocation$.pipe(
      filter(center => center !== null),
      map(center => center.longitude)
    )
  ]).pipe(map(([initial, def]) => {
    return initial == null ? def : initial
  }))
  zoom = 15

  private _loading$ = new BehaviorSubject(false);
  loading$ = this._loading$.asObservable();

  allSelected$ = this.service.allSelected$
  categories$ = this.service.categories$

  landmarks$ = this.service.landmarks$


  tpMapIcon = {
    labelOrigin: { x: 18, y: 22 },
    url: './assets/media/icons/pin_red.svg',
    scaledSize: {
      width: 44,
      height: 44
    }
  };

  form = new FormGroup({})
  previous;
  baseMediaUrl = environment.baseMediaUrl;

  dataSource = this.service.selectedLandmarks$
  assetClassId: number;
  constructor(
    private service: LandmarksService,
    private activetedRoute: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog,
    private mapsService: MapsService
  ) { 
    this.activetedRoute.params.pipe(
      tap(params => this.assetClassId = Number(params.asset_class_id)),
      switchMap(params => this.service.fetchInvestigationLandmarks(Number(params.asset_class_id)))
    ).subscribe()
  }

  ngOnInit(): void {
    this.service.fetchLandmarks()
  }

  ngOnDestroy(): void {
  }

  onChangeAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.service.setAll()
    } else {
      this.service.unsetAll()
    }
  }

  onChangeCategory(event: MatCheckboxChange, id: number) {
    if (event.checked) {
      this.service.check(id)
    } else {
      this.service.uncheck(id)
    }
  }

  onAddLandmark() {
    const dialogRef = this.dialog.open(KeyplaceModalComponent, {
      width: '80vw',
      data: {
        // valuationId: this.valuation.valuationId,
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return
      }
      this._addKeyPlace(res);
    })
  }

  getIcon(landmark) {
    const isSelected = this.service.selectedLandmarks$.value.find(el => el.id == landmark.id);
    return {
      labelOrigin: { x: 18, y: 22 },
      url: isSelected ? './assets/media/icons/blue_pin_star.svg' : './assets/media/icons/pin_star.svg',
      scaledSize: {
        width: 44,
        height: 44
      }
    };
  }

  showInfo(landMark, infowindow) {
    this._centerLat$.next(Number(landMark.latitude))
    this._centerLng$.next(Number(landMark.longitude))

    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
    infowindow.open();
  }

  public imgOnError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }

  addKeyPlace(keyPlace, infoWindow) {
    this._addKeyPlace(keyPlace);
    infoWindow.close();
  }

  private _addKeyPlace(keyPlace) {
    this.service.addLandmark(keyPlace);
    // let _item = Object.assign([], this._landmarks);

    // if (this.alreadyAdded(keyPlace)) {
    //   _item = this.selectedkeyPlaces.filter((item) => {
    //     return item.id != keyPlace.id;
    //   })
    // } else {
    //   _item = [...this.selectedkeyPlaces, keyPlace];
    // }
    // this.store.dispatch(new UpdateValuationLandMarks({ landmarks: _item }));
  }

  alreadyAdded(keyPlace) {
    return this.service.alreadyAdded(keyPlace)
    // if (this.selectedkeyPlaces.length == 0) {
    //   return false;
    // }
    // return this.selectedkeyPlaces.filter(item => item.id == keyPlace.id).length;
  }

  deleteKeyplace(keyPlace) {
    this.service.deleteLandmark(keyPlace)
  }

  onSubmit() {
    this.service.submit(this.assetClassId).subscribe(_ => {
      this.location.back()
    })
  }
}
