import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { values } from 'lodash';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { AssetClassModel, ValuationModel } from 'src/app/core/asset_class';
import { DraftStatementReportModel } from 'src/app/core/asset_class/_models/draft-statement-report.model';
import { ValuationReportModel } from 'src/app/core/asset_class/_models/valuation-report.model';
import { ExchangeDirection, ReportingProcessData } from 'src/app/core/reporting/_models/reporting_process.model';
import { ToeModel } from 'src/app/core/toe';
import { SupportingValuerModel } from 'src/app/core/toe/_models/supporting-valuer.model';
import { ValuationDataService } from 'src/app/core/valuation';
import { ValuationProcessService } from 'src/app/core/valuation-process';
import { AdjustmentValuationService } from 'src/app/core/valuation/_services/valuation.service';
import { Member } from '../../types';
import * as _ from 'lodash';
import { GroupedValuations } from '../../reporting-process.utils';
@Component({
  selector: 'kt-general-valuation-matters',
  templateUrl: './general-valuation-matters.component.html',
  styleUrls: ['./general-valuation-matters.component.scss']
})
export class GeneralValuationMattersComponent implements OnInit, OnDestroy {
  @Input()
  readonly: boolean;
  @Input()
  valuations: ValuationModel[];
  @Input()
  valuationsWithScenario: {valuation: any, valuation_index: number, scenario: {
      scenario_number: number
  }}[] = []
  @Input()
  groupedValuations: Array<GroupedValuations> = []
  @Input()
  targetProperty: AssetClassModel;
  @Input()
  toe: ToeModel;
  @Input()
  members: Member[];
  @Input()
  reporting: ReportingProcessData;
  @Input()
  draftReport: ValuationReportModel;

  public parentFormGroup: AbstractControl;
  public numbering: number[];
  public defaultNumbering = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  private subscriptions: Subscription[] = [];
  private exchangeDirections: {
    [id: number]: ExchangeDirection
  } = {};
  public baseCurrency: {
    [id: number]: string
  } = {};
  public quoteCurrency: {
    [id: number]: string
  } = {};

  public valuationData: {
    totalValue: number;
    val: ValuationModel;
  }[] = [];
  public convertedValues: {
    [id: number]: number;
  } = {};

  public _chooseValuations: any[] = []
  public showJustificationField: {} = {}




  constructor(
    public controlContainer: ControlContainer,
    private valuationDataService: ValuationProcessService,
    private valuationService: AdjustmentValuationService
  ) { }

  ngOnInit(): void {
    this.numbering = this._numbering(
      this.groupedValuations.length,
      this.draftReport != null,
      this.reporting.draft_is_shared_to_client == 1,
      this.targetProperty.country_currency !== this.targetProperty.reporting_currency,
      this.toe.purpose_of_valuation_id == 13,
      this.hasManyScenario(this.groupedValuations),
      this.hasOnlyOneScenario(this.groupedValuations)
    ); 
    // this.defaultNumbering
    //   .map(i => {
    //     if (this.valuations.length == 1) {
    //       return i - 1;
    //     }
    //     return i;
    //   })
    //   .map((i, indx) => {
    //     if (indx <= 3) {
    //       return i;
    //     }
    //     if (this.targetProperty.country_currency === this.targetProperty.reporting_currency) {
    //       return i - 1;
    //     }
    //     return i;
    //   });

    const obs: Observable<any>[] = [];
    this.valuations.forEach(val => {
      this.convertedValues[val.id] = undefined;
      obs.push(this.valuationDataService.summaryData(this.targetProperty.id, val.id))
    });
    combineLatest(obs).subscribe(res => {
      if (!res) {
        return
      }
      res.forEach(val => {
        // const totals = this.valuationService.computeTotals(val.valuations, val.size, val.capital_allowance);

        const valuation = this.valuations.find(v => v.id == val.id);
        if (valuation == undefined) {
          // Do Nothing
        } else {
          const exchangeRate = this.reporting.exchange_rates.find(r => r.val_id == valuation.id);
          if (exchangeRate) {
            this.exchangeDirections[valuation.id] = exchangeRate.exchange_direction;
            switch (exchangeRate.exchange_direction) {
              case 'country-to-report':
                this.convertedValues[valuation.id] = val.tpValue * exchangeRate.exchange_rate;
                this.baseCurrency[valuation.id] = this.targetProperty.country_currency;
                this.quoteCurrency[valuation.id] = this.targetProperty.reporting_currency;
                break;
              case 'report-to-country':
                this.convertedValues[valuation.id] = val.tpValue * (exchangeRate.exchange_rate ? 1/exchangeRate.exchange_rate : undefined);
                this.baseCurrency[valuation.id] = this.targetProperty.reporting_currency;
                this.quoteCurrency[valuation.id] = this.targetProperty.country_currency;
                break;
            }
          } else {
            this.convertedValues[valuation.id] = undefined;
            this.exchangeDirections[valuation.id] = 'country-to-report';
            this._setupCurrencies(valuation.id);
          }
          this.valuationData.push({
            totalValue: val.tpValue,
            val: valuation
          });
        }
      })
    });

    this.parentFormGroup = this.controlContainer.control;
    const controls = (this.parentFormGroup as UntypedFormGroup).controls;
    const changeInMarketSub = controls.change_in_market.valueChanges.subscribe(val => {
      if (val == 1) {
        controls.change_in_market_justification.setValidators([Validators.required]);
      } else {
        controls.change_in_market_justification.setValidators([]);
      }
      controls.change_in_market_justification.updateValueAndValidity();
    });
    this.subscriptions.push(changeInMarketSub);

    const valuationScenarioFormGroup = (this.parentFormGroup as UntypedFormGroup).controls.valuation_scenario as UntypedFormGroup
    const valScenarioSub = valuationScenarioFormGroup.valueChanges.pipe(
      startWith(valuationScenarioFormGroup.value)
    ).subscribe(_ => {
      this.showJustificationField = this.groupedValuations.reduce((acc, gVal) => {
        const control = valuationScenarioFormGroup.get(`valuation-scenario-${gVal.valuation_index}`)
        if (!control) {
          return acc;
        }
        const valWithTask = gVal.valuations.find(v => v.valuation.id == control.value);
        let val = gVal.valuations[0].valuation
        if (valWithTask) {
          val = valWithTask.valuation
        }
        acc[gVal.valuation_index] = {
          show: val.methods_to_value_id != val.original_methods_to_value_id,
          current: val.methods_to_value_name,
          original: val.original_methods_to_value_name
        }
        const justControl = valuationScenarioFormGroup.get(`valuation-scenario-justification-${gVal.valuation_index}`)
        if (acc[gVal.valuation_index].show) {
          justControl.setValidators([Validators.required])
        } else {
          justControl.setValidators(null)
        }
        return acc
      }, {})

      if (this.groupedValuations.length == 1) {
        const valControl = (this.parentFormGroup as UntypedFormGroup).controls.valuation
        const control = valuationScenarioFormGroup.get(`valuation-scenario-${this.groupedValuations[0].valuation_index}`)
        if (control) {
          valControl.setValue(control.value)
          valControl.updateValueAndValidity()
        }
      }

      this._chooseValuations = this.groupedValuations
        .map(gVal => {
          const control = valuationScenarioFormGroup.get(`valuation-scenario-${gVal.valuation_index}`)
          if (!control) {
            return gVal.valuations[0].valuation
          }
          const val = gVal.valuations.find(v => v.valuation.id == control.value)
          if (!val) {
            return gVal.valuations[0].valuation
          }
          return val.valuation;
        })
    })
    this.subscriptions.push(valScenarioSub)

    const draftIsSharedSub = controls.draft_is_shared_to_client.valueChanges.subscribe(val => {
      this.numbering = this._numbering(
        this.groupedValuations.length,
        this.draftReport != null,
        val == 1,
        this.targetProperty.country_currency !== this.targetProperty.reporting_currency,
        this.toe.purpose_of_valuation_id == 13,
        this.hasManyScenario(this.groupedValuations),
        this.hasOnlyOneScenario(this.groupedValuations)
      )
    });
    this.subscriptions.push(draftIsSharedSub);
    const baseOfValueSub = controls.base_of_value_adopted.valueChanges.pipe(startWith(controls.base_of_value_adopted.value)).subscribe(val => {
      if (val == 0) {
        controls.base_of_value_adopted_desc.setValidators(Validators.required);
      } else {
        controls.base_of_value_adopted_desc.clearValidators();
      }
      controls.base_of_value_adopted_desc.updateValueAndValidity();
    });
    this.subscriptions.push(baseOfValueSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  reverseExchangeStatus(valID: number, totalValue: number) {
    const egrp = (this.parentFormGroup as UntypedFormGroup).get('val_exchange') as UntypedFormGroup;
    const control = egrp.get(valID + '_exchange_rate');
    const currentRate = Number(control.value);
    const newRate = currentRate ? 1 / currentRate : undefined;
    switch (this.exchangeDirections[valID]) {
      case 'country-to-report':
        this.exchangeDirections[valID] = 'report-to-country';
        break;
      case 'report-to-country':
        this.exchangeDirections[valID] = 'country-to-report';
        break;
    }
    this._setupCurrencies(valID);
    control.setValue(currentRate ? 1 / currentRate : undefined);
    this._convert(valID, newRate, totalValue);
  }

  public getExchangeDirections(): {[id: number]: ExchangeDirection} {
    return this.exchangeDirections;
  }
  private _setupCurrencies(valID: number) {
    switch (this.exchangeDirections[valID])  {
      case 'country-to-report':
        this.baseCurrency[valID] = this.targetProperty.country_currency;
        this.quoteCurrency[valID] = this.targetProperty.reporting_currency;
        break;
      case 'report-to-country':
        this.baseCurrency[valID] = this.targetProperty.reporting_currency;
        this.quoteCurrency[valID] = this.targetProperty.country_currency;
        break;
    }
  }

  signatureControl(member: SupportingValuerModel) {
    const formGroup = (this.parentFormGroup as UntypedFormGroup).controls.signatures as UntypedFormGroup;
    return formGroup.get(`member-${member.id}`);
  }

  valuationScenarioControl(valuation_index: number) {
    const formGroup = (this.parentFormGroup as UntypedFormGroup).controls.valuation_scenario as UntypedFormGroup;
    return formGroup.get(`valuation-scenario-${valuation_index}`)
  }

  valuationScenarioJustificationControl(valuation_index: number) {
    const formGroup = (this.parentFormGroup as UntypedFormGroup).controls.valuation_scenario as UntypedFormGroup;
    return formGroup.get(`valuation-scenario-justification-${valuation_index}`)
  }

  get hasTeamSignaturesControl() {
    return (this.parentFormGroup as UntypedFormGroup).controls.has_team_signatures;
  }

  get valuationJustificationControl() {
    return (this.parentFormGroup as UntypedFormGroup).controls.valuation_justification;
  }

  get valuationDateControl() {
    return (this.parentFormGroup as UntypedFormGroup).controls.valuation_date;
  }

  get changeInMarketControl() {
    return (this.parentFormGroup as UntypedFormGroup).controls.change_in_market;
  }

  get changeInMarketJustificationControl() {
    return (this.parentFormGroup as UntypedFormGroup).controls.change_in_market_justification;
  }

  get draftIsSharedControl() {
    return (this.parentFormGroup as UntypedFormGroup).controls.draft_is_shared_to_client;
  }

  get hasMaterialChangeControl() {
    return (this.parentFormGroup as UntypedFormGroup).controls.has_material_change;
  }

  get baseOfValueAdoptedControl() {
    return (this.parentFormGroup as UntypedFormGroup).controls.base_of_value_adopted;
  }

  clearDate(_control) {
    _control.setValue(null);
    _control.updateValueAndValidity();
  }

  onFocusOut($event, valID: number, totalValue: number) {
    const egrp = (this.parentFormGroup as UntypedFormGroup).get('val_exchange') as UntypedFormGroup;
    const control = egrp.get(valID + '_exchange_rate');
    const rate = Number(control.value);
    this._convert(valID, rate, totalValue);
  }

  private _convert(valID: number, rate: number, totalValue: number) {
    switch(this.exchangeDirections[valID]) {
      case 'country-to-report':
        this.convertedValues[valID] = totalValue * rate;
        break;
      case 'report-to-country':
        this.convertedValues[valID] = totalValue * (1 / rate);
        break;
    }
  }

  private _numbering(valNum: number, draftGenerated: boolean, draftShared: boolean, currencyDiff: boolean, purpose: boolean, hasManyScenario: boolean, hasOnlyOneScenario: boolean): number[] {
    const modifier = (i: number, index: number, predicate: boolean, prevIndx: number) => {
      if (index <= prevIndx || predicate) {
        return i;
      }
      return i - 1;
    }
    return this.defaultNumbering
      .map(i => {
        return valNum == 1 
          ? hasManyScenario 
            ? i - 1
            : i - 2
          : hasOnlyOneScenario
            ? i - 1
            : i;
      })
      .map((i, indx) => modifier(i, indx, draftGenerated, 5))
      .map((i, indx) => modifier(i, indx, draftShared, 6))
      .map((i, indx) => modifier(i, indx, currencyDiff, 7))
      .map((i, indx) => modifier(i, indx, purpose, 8));
  }

  public hasManyScenario(groupedValuations: Array<GroupedValuations>): boolean {
    return groupedValuations
      .filter(gVal => gVal.valuations.length > 1)
      .length > 0
  }

  public hasOnlyOneScenario(groupedValuations: Array<GroupedValuations>): boolean {
    return groupedValuations
      .filter(gVal => gVal.valuations.length > 1)
      .length == 0
  }
}


