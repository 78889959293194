<!-- <div class="kt-login-head kt_login_wrapper">
  <span class="kt-login-signup-label">Don't have an account yet?</span>&nbsp;&nbsp;
  <a href="/mad-auth/register" class="kt-link kt-login-signup-link">
    Sign Up
  </a>
</div> -->

<div class="kt-login-body">
  <div class="kt-login-form">
    <!-- <h1>
      <a href="https://www.interval-soft.com/" title="Login Page">
        Login Page
      </a>
    </h1> -->
    <div class="mad-login-form">
      <img src="assets/media/interval/logo.png" class="interval-blue-logo" alt="InterVal logo">
      <div id="login-embed"></div>
    </div>
  </div>
</div>