import { LeaseDurationType } from "../types";

export function durationCalculation(value: number, duration_type: LeaseDurationType) {
  if (!value) return null
  if (duration_type === 'months') return value / 12
  return value
}

export function calculateHeadlineRent(amount: number, rent_input_amount_type: string, rent_basis_id: number, size: number) {
  let _amount = 0;
  if (rent_input_amount_type === 'total') {
    _amount = amount
  } else if (rent_input_amount_type === 'per_units') {
    const sizeUnit = size ? size : 0
    _amount = amount * sizeUnit
  }


  if (rent_basis_id === 1) {
    return _amount * 12
  } else if (rent_basis_id === 2) {
    return _amount * 4
  } else {
    return _amount
  }
}

export function calculateYearsPurchaseAtPercentRate(rate: number, year: number): number {
  const rateInVal = rate / 100;
  let temp = 1 + rateInVal;
  temp = Math.pow(temp, -year);
  temp = 1 - temp;
  return temp / rateInVal;
}

export function calculatePresentValueAtPercentRate(rate: number, year: number): number {
  if (rate === null || rate === 0) {
    return NaN;
  }
  const rateInVal = rate / 100;
  let temp = 1 + rateInVal;
  return Math.pow(temp, -year);
}

export function roundTo4Decimals(val: number): number {
  return Math.round(val * 10000) / 10000;
}