/** Angular */
import {Injectable} from '@angular/core';
import {awConst} from 'src/app/app.constants';

@Injectable()
export class TypesUtilsService {
    /**
     * Convert number to string and adding '0' before
     *
     * @param value: number
     */
    padNumber(value: number) {
        if (this.isNumber(value)) {
            return `0${value}`.slice(-2);
        } else {
            return '';
        }
    }

    /**
     * Checking value type equals to Number
     *
     * @param value: any
     */
    isNumber(value: any): boolean {
        return !isNaN(this.toInteger(value));
    }

    /**
     * Covert value to number
     *
     * @param value: any
     */
    toInteger(value: any): number {
        return parseInt(`${value}`, 10);
    }

    /**
     * Convert date to string with 'MM/dd/yyyy' format
     *
     * @param date: Date
     */
    dateFormat(date: Date): string {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        if (date) {
            return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
            // return `${month}/${day}/${year}`;
        }

        return '';
    }

    /**
     * Convert Date to string with custom format 'MM/dd/yyyy'
     *
     * @param date: any
     */
    dateCustomFormat(date: any): string {
        let stringDate = '';
        if (date) {
            stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + '/' : '';
            stringDate += this.isNumber(date.day) ? this.padNumber(date.day) + '/' : '';

            stringDate += date.year;
        }
        return stringDate;
    }

    /**
     * Convert string to DateFormatter (For Reactive Forms Validators)
     *
     * @param dateInStr: string (format => 'MM/dd/yyyy')
     */
    getDateFormatterFromString(dateInStr: string): any {
        if (dateInStr && dateInStr.length > 0) {
            const dateParts = dateInStr.trim().split('/');
            return [
                {
                    year: this.toInteger(dateParts[2]),
                    month: this.toInteger(dateParts[0]),
                    day: this.toInteger(dateParts[1])
                }
            ];
        }

        const date = new Date();
        return [
            {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDay()
            }
        ];
    }

    /**
     * Convert string to Date
     *
     * @param dateInStr: string (format => 'MM/dd/yyyy')
     */
    getDateFromString(dateInStr: string = ''): Date {
        if (dateInStr && dateInStr.length > 0) {
            const datePart = dateInStr.trim().split(' ');
            if (datePart[0] && datePart[0].length > 0) {
                const dateParts = datePart[0].trim().split('-');
                const year = this.toInteger(dateParts[0]);
                const month = this.toInteger(dateParts[1]);
                const day = this.toInteger(dateParts[2]);
                // tslint:disable-next-line:prefer-const
                let result = new Date();
                result.setFullYear(year);
                result.setMonth(month - 1);
                result.setDate(day);
                return result;
            }
        }

        return new Date();
    }


    /**
     * Convert Date to string with format 'MM/dd/yyyy'
     * @param date: Date?
     */
    getDateStringFromDate(date: Date = new Date()): string {
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const dateToday = date.getDate();
        // return `${month}/${dateToday}/${year}`;
        return `${year}-${month > 9 ? month : '0' + month}-${dateToday > 9 ? dateToday : '0' + dateToday}`;
    }


    getAssetClassTypeName(type) {
        let assetClassTypeName = '';
        switch (type) {
            case '1':
                assetClassTypeName = 'Apartment';
                break;
            case '2':
                assetClassTypeName = 'Parking';
                break;
            case '3':
                assetClassTypeName = 'Office';
                break;
            case '5':
                assetClassTypeName = 'Retail';
                break;
            case '12':
                assetClassTypeName = 'Office'; // exclusive
                break;
            case '7':
                assetClassTypeName = 'Warehouse';
                break;
            case '17':
                assetClassTypeName = 'House';
                break;
            default:
                assetClassTypeName = 'Residential';
                break;
        }
        return assetClassTypeName;
    }

    /**
     * Convert Date to string with format 'MM/dd/yyyy'
     * @param type: Date?
     */

    setIcon(type) {
        let ext = 'doc';
        switch (type) {
            case 'png':
                ext = 'png';
                break;
            case 'jpeg':
                ext = 'jpg';
                break;
            case 'jpg':
                ext = 'jpg';
                break;
            case 'gif':
                ext = 'gif';
                break;
            case 'pdf':
                ext = 'pdf';
                break;
            case 'xls':
                ext = 'xls';
                break;
            case 'xlsx':
                ext = 'xls';
                break;
            case 'rtf':
                ext = 'doc';
                break;
            case 'doc':
                ext = 'doc';
                break;
            case 'docx':
                ext = 'doc';
                break;
            case 'mp3':
                ext = 'mp3';
                break;
            default:
                ext = 'file';
                break;
        }
        return './assets/media/files-alt/' + ext + '.svg';
    }

    setIconByName(name) {
        let ext = 'doc';
        const type = name.substring(name.indexOf('.') + 1, name.length).toLowerCase();
        switch (type) {
            case 'png':
                ext = 'png';
                break;
            case 'jpeg':
                ext = 'jpg';
                break;
            case 'jpg':
                ext = 'jpg';
                break;
            case 'gif':
                ext = 'gif';
                break;
            case 'pdf':
                ext = 'pdf';
                break;
            case 'xls':
                ext = 'xls';
                break;
            case 'xlsx':
                ext = 'xls';
                break;
            case 'rtf':
                ext = 'doc';
                break;
            case 'doc':
                ext = 'doc';
                break;
            case 'docx':
                ext = 'doc';
                break;
            case 'mp3':
                ext = 'mp3';
                break;
            default:
                ext = 'file';
                break;
        }
        return './assets/media/files-alt/' + ext + '.svg';
    }

    getFileType(name) {
        return name.substring(name.lastIndexOf('.') + 1, name.length).toLowerCase();
    }

    excludeExtention(name) {
        return name.substring(0, name.indexOf('.')).toLowerCase();
    }

    getFileNameFromPath(name) {
        return (name && name.length > 0) ? name.split('/').pop().replace(/^\d+_/, '') : awConst.NOFILE;
    }

    getInspectionTabErrorMap(errorFields, tabHeaders) {
        const lastIndex = tabHeaders.length - 1;
        let section_tab = [
            {cname: 'information_date', label: 'Date of Information', section: 'Details', tab_index: 0},
            {cname: 'source_of_information', label: 'Source of Information', section: 'Details', tab_index: 0},
            {cname: 'inspection_date', label: 'Date of Inspection', section: 'Details', tab_index: 0},
            {cname: 'time_of_inspection', label: 'Time of Inspection', section: 'Details', tab_index: 0},
            {cname: 'duration_of_inspection', label: 'Duration Of Inspection', section: 'Details', tab_index: 0},
            {cname: 'participants', label: 'Participants', section: 'Details', tab_index: 0},
            {cname: 'limitations_desc', label: 'Limitations During the Inspection', section: 'Details', tab_index: 0},

            {cname: 'draw', label: 'Draw map below', section: 'Location', tab_index: 1},
            {cname: 'city_id', label: 'City', section: 'Location', tab_index: 1},
            {cname: 'latitude', label: 'Map location', section: 'Location', tab_index: 1},
            {cname: 'location_grade_id', label: 'Location grade', section: 'Location', tab_index: 1},
            {cname: 'address', label: 'Address', section: 'Location', tab_index: 1},
            {cname: 'location_surrounding', label: 'Location and Surroundings Description', section: 'Location', tab_index: 1},

            {cname: 'building_form_building_type', label: 'Building Type', section: 'The Building', tab_index: 1},
            {cname: 'building_form_completion_year', label: 'Completion Year', section: 'The Building', tab_index: 1},
            {cname: 'building_form_building_grade_id', label: 'Building Grade', section: 'The Building', tab_index: 1},

            {cname: 'land_parcel_name', label: 'Land parcel ID', section: 'Parcel identification', tab_index: 1},
            {cname: 'name', label: 'Land parcel ID', section: 'Parcel identification', tab_index: 1},
            {cname: 'coordinate_reference_system_id', label: 'Coordinate reference system', section: 'Parcel identification', tab_index: 1},
            {cname: 'property_type_id', label: 'Property sub-type', section: 'Parcel identification', tab_index: 1},

            {cname: 'completion_year', label: 'Completion year', section: 'The Building', tab_index: 1},
            {cname: 'building_type', label: 'Building type', section: 'The Building', tab_index: 1},
            {cname: 'building_grade_id', label: 'Building grade', section: 'The Building', tab_index: 1},

            {cname: 'sub_type_category_id', label: 'Sub-Type Category', section: 'About the Property', tab_index: 2},
            {cname: 'sub_category_id', label: 'Sub Category', section: 'About the Property', tab_index: 2},
            {cname: 'property_grade_id', label: 'Property Grade', section: 'About the Property', tab_index: 2},
            {cname: 'general_desc', label: 'Property general description', section: 'About the Property', tab_index: 2},
            {cname: 'completion_year_about', label: 'Completion year', section: 'About the Property', tab_index: 2},
            {cname: 'state_of_repair_id', label: 'State of Repair', section: 'About the Property', tab_index: 2},
            {cname: 'handover_standard_id', label: 'Handover Standard', section: 'About the Property', tab_index: 2},

            {cname: 'window_type_id', label: 'Windows', section: 'Internal aspect', tab_index: 2},
            {cname: 'ceiling_id', label: 'Ceilings', section: 'Internal aspect', tab_index: 2},
            {cname: 'walls_and_partition_id', label: 'Walls and partitions', section: 'Internal aspect', tab_index: 2},
            {cname: 'floor_type_id', label: 'Floors', section: 'Internal aspect', tab_index: 2},
            {cname: 'woodwork', label: 'Woodwork', section: 'Internal aspect', tab_index: 2},
            {cname: 'bathroom_fitting_id', label: 'Bathroom fittings', section: 'Internal aspect', tab_index: 2},
            {cname: 'height', label: 'Height', section: 'Internal aspect', tab_index: 2},
            {cname: 'cubic_content', label: 'Cubic content', section: 'Internal aspect', tab_index: 2},
            {cname: 'storage_type_id', label: 'Storage type', section: 'Internal aspect', tab_index: 2},
            {cname: 'shop_front_type_id', label: 'Shop front type', section: 'Internal aspect', tab_index: 2},
            {cname: 'window_type_intern', label: 'Window type', section: 'Internal aspect', tab_index: 2},

            {cname: 'chimney_stack_id', label: 'Chimney stack', section: 'External aspect', tab_index: 2},
            {cname: 'roof_covering_id', label: 'Roof cover', section: 'External aspect', tab_index: 2},
            {cname: 'rainwater_pipe_id', label: 'Rainwater pipe', section: 'External aspect', tab_index: 2},
            {cname: 'outside_door_id', label: 'Outside door', section: 'External aspect', tab_index: 2},
            {cname: 'mainwall_id', label: 'Main wall', section: 'External aspect', tab_index: 2},
            {cname: 'window_type_id', label: 'Window type', section: 'External aspect', tab_index: 2},
            {cname: 'conservatory_id', label: 'Conservatory', section: 'External aspect', tab_index: 2},
            {cname: 'other_joinery_id', label: 'Other joinery', section: 'External aspect', tab_index: 2},
            {cname: 'loading_dock', label: 'Loading dock', section: 'External aspect', tab_index: 2},
            {cname: 'external_facade_id', label: 'External facade', section: 'External aspect', tab_index: 2},

            {cname: 'grounds', label: 'External Area', section: 'Grounds & external areas', tab_index: 2},            
            {cname: 'indoor_garage', label: 'Indoor garage', section: 'Grounds', tab_index: 2},
            {cname: 'outdoor_garage', label: 'Outdoor garage', section: 'Grounds', tab_index: 2},
            {cname: 'garden_id', label: 'Garden', section: 'Grounds', tab_index: 2},
            {cname: 'outdoor_space_id', label: 'Outdoor space', section: 'Grounds', tab_index: 2},
            {cname: 'external_area', label: 'External Area', section: 'Grounds', tab_index: 2},

            {cname: 'measurement_date', label: 'Date of Measurement', section: 'Measurement Information', tab_index: 2},
            {cname: 'unit_measurement_id', label: 'Unit of Measurement', section: 'Measurement Information', tab_index: 2},
            {cname: 'measurement_standard_id', label: 'Measurement Standard', section: 'Measurement Information', tab_index: 2},
            {cname: 'purpose_of_the_measurement_instruction', label: 'Purpose of the Measurement Instruction', section: 'Measurement Information', tab_index: 2},
            {cname: 'measurement_methodology_adopted', label: 'Measurement Methodology Adopted', section: 'Measurement Information', tab_index: 2},
            {cname: 'scale_of_plan', label: 'Scale of Plan', section: 'Measurement Information', tab_index: 2},
            {cname: 'floorpans', label: 'Floor area schedule with relevant areas cross referenced to floor plans', section: 'Measurement Information', tab_index: 2},
            {cname: 'size_modules', label: 'Invalid Size', section: 'Measurement Information', tab_index: 2},

            {cname: 'no_condition_document', label: 'At least 1 document required', section: 'Condition Rating', tab_index: 3},
            {cname: 'no_condition_document_title', label: 'Document title is required', section: 'Condition Rating', tab_index: 3},

            {cname: 'no_picture', label: 'At least 1 picture required', section: 'Property Pictures', tab_index: lastIndex},
            {cname: 'no_picture_title', label: 'Picture title is required', section: 'Property Pictures', tab_index: lastIndex},
            {cname: 'no_picture_build', label: 'At least 1 picture required', section: 'Building Pictures', tab_index: lastIndex},
            {cname: 'no_picture_title_build', label: 'Picture title is required', section: 'Building Pictures', tab_index: lastIndex}
        ]
        let filteredErrors = section_tab.filter(item => errorFields.includes(item.cname)).sort((a, b) => a.tab_index - b.tab_index)
        let groupedBytab = [];
      
        for (let index = 0; index < tabHeaders.length; index++) {
            if (section_tab.find(item => item.tab_index == index) != undefined) {
                groupedBytab.push({tab_index: index, tab_cnames: filteredErrors.filter(item => item.tab_index == index)})
            }
        }
          
        return groupedBytab;
    }

    getCompTabErrorMap(isComplex, errorFields, tabHeaders, acType) {
        const ac_types1 = [7, 11, 17, undefined];
        const ac_types2 = [1, 3, 5];
        const ac_types3 = [2];

        let section_tab = [
            {cname: 'draw', label: 'Draw map below', section: 'Location', tab_index: 0},
            {cname: 'city_id', label: 'City', section: 'Location', tab_index: 0},
            {cname: 'latitude', label: 'Map location', section: 'Location', tab_index: 0},
            {cname: 'location_grade_id', label: 'Location grade', section: 'Location', tab_index: 0},
            {cname: 'address', label: 'Address', section: 'Location', tab_index: 0},

            {cname: 'land_parcel_name', label: 'Land parcel ID', section: 'Parcel identification', tab_index: 0},
            {cname: 'name', label: 'Land parcel ID', section: 'Parcel identification', tab_index: 0},
            {cname: 'coordinate_reference_system_id', label: 'Coordinate reference system', section: 'Parcel identification', tab_index: 0},
            {cname: 'property_type_id', label: 'Property sub-type', section: 'Parcel identification', tab_index: 0},

      
            {cname: 'sub_type_category_id', label: 'Sub-type category', section: 'About the property', tab_index: 1},
            {cname: 'sub_category_id', label: 'Sub-category', section: 'About the property', tab_index: 1},
            {cname: 'handover_standard_id', label: 'Handover standard', section: 'About the property', tab_index: 1},
            {cname: 'floor_details', label: 'Floor location', section: 'About the property', tab_index: 1},
            {cname: 'state_of_repair_id', label: 'State of repair', section: 'About the property', tab_index: 1},
            {cname: 'number_of_units', label: 'Number of units', section: 'About the property', tab_index: 1},
            {cname: 'parking_type_id', label: 'Parking type', section: 'About the property', tab_index: 1},

            {cname: 'property_grade_id', label: 'Property grade', section: 'About the property', tab_index: 1},

            {cname: 'above_floors', label: 'Number of floors', section: 'About the property', tab_index: 1},
      
            {cname: 'window_type_id', label: 'Windows', section: 'Internal aspect', tab_index: 1},
            {cname: 'ceiling_id', label: 'Ceilings', section: 'Internal aspect', tab_index: 1},
            {cname: 'walls_and_partition_id', label: 'Walls and partitions', section: 'Internal aspect', tab_index: 1},
            {cname: 'floor_type_id', label: 'Floors', section: 'Internal aspect', tab_index: 1},
            {cname: 'woodwork', label: 'Woodwork', section: 'Internal aspect', tab_index: 1},
            {cname: 'bathroom_fitting_id', label: 'Bathroom fittings', section: 'Internal aspect', tab_index: 1},
            {cname: 'height', label: 'Height', section: 'Internal aspect', tab_index: 1},
            {cname: 'cubic_content', label: 'Cubic content', section: 'Internal aspect', tab_index: 1},
            {cname: 'storage_type_id', label: 'Storage type', section: 'Internal aspect', tab_index: 1},
            {cname: 'shop_front_type_id', label: 'Shop front type', section: 'Internal aspect', tab_index: 1},

            {cname: 'chimney_stack_id', label: 'Chimney stack', section: 'External aspect', tab_index: 1},
            {cname: 'roof_covering_id', label: 'Roof cover', section: 'External aspect', tab_index: 1},
            {cname: 'rainwater_pipe_id', label: 'Rainwater pipe', section: 'External aspect', tab_index: 1},
            {cname: 'outside_door_id', label: 'Outside door', section: 'External aspect', tab_index: 1},
            {cname: 'mainwall_id', label: 'Main wall', section: 'External aspect', tab_index: 1},
            {cname: 'window_type_id', label: 'Window type', section: 'External aspect', tab_index: 1},
            {cname: 'conservatory_id', label: 'Conservatory', section: 'External aspect', tab_index: 1},
            {cname: 'other_joinery_id', label: 'Other joinery', section: 'External aspect', tab_index: 1},
            {cname: 'loading_dock', label: 'Loading dock', section: 'External aspect', tab_index: 1},
            {cname: 'external_facade_id', label: 'External facade', section: 'External aspect', tab_index: 1},
      
            {cname: 'size', label: 'Size', section: 'Sizes', tab_index: 1},

            {cname: 'grounds', label: 'External Area', section: 'Grounds & external areas', tab_index: 1},            
            {cname: 'indoor_garage', label: 'Indoor garage', section: 'Grounds', tab_index: 1},
            {cname: 'outdoor_garage', label: 'Outdoor garage', section: 'Grounds', tab_index: 1},
            {cname: 'garden_id', label: 'Garden', section: 'Grounds', tab_index: 1},
            {cname: 'outdoor_space_id', label: 'Outdoor space', section: 'Grounds', tab_index: 1},
            
            {cname: 'consideration', label: 'Considerations', section: 'Considerations', tab_index: 2},
      
            {cname: 'pic', label: 'Property picture', section: 'Property pictures', tab_index: 3},
            {cname: 'b_pic', label: 'Building picture', section: 'Building pictures', tab_index: 3},
        ]

        if (ac_types1.includes(acType)) {
            section_tab = [...section_tab, ...[
                {cname: 'completion_year', label: 'Completion year', section: 'About the property', tab_index: 1},
                {cname: 'building_type', label: 'Building type', section: 'About the property', tab_index: 1},
                {cname: 'building_grade_id', label: 'Building grade', section: 'About the property', tab_index: 1},
            ]]
        } else {
            section_tab = [...section_tab, ...[
                {cname: 'completion_year', label: 'Completion year', section: 'Building', tab_index: 0},
                {cname: 'building_type', label: 'Building type', section: 'Building', tab_index: isComplex ? 0 : 1},
                {cname: 'building_grade_id', label: 'Building grade', section: 'Building', tab_index: 0},
            ]]
        }

        if (ac_types2.includes(acType)) {
            section_tab = [...section_tab, ...[
                {cname: 'building_form_building_type', label: 'Building Type', section: 'The Building', tab_index: 0},
                {cname: 'building_form_completion_year', label: 'Completion Year', section: 'The Building', tab_index: 0},
                {cname: 'building_form_building_grade_id', label: 'Building Grade', section: 'The Building', tab_index: 0},
            ]];
        }
        if (ac_types3.includes(acType)) {
            section_tab = [...section_tab, ...[
                {cname: 'building_form_building_type', label: 'Building Type', section: 'The Building', tab_index: 1},
                {cname: 'building_form_completion_year', label: 'Completion Year', section: 'The Building', tab_index: 1},
                {cname: 'building_form_building_grade_id', label: 'Building Grade', section: 'The Building', tab_index: 1},
            ]];
        }
        let filteredErrors = section_tab.filter(item => errorFields.includes(item.cname)).sort((a, b) => a.tab_index - b.tab_index)
        let groupedBytab = [];
      
        for (let index = 0; index < tabHeaders.length; index++) {
            if (section_tab.find(item => item.tab_index == index) != undefined) {
                groupedBytab.push({tab_index: index, tab_cnames: filteredErrors.filter(item => item.tab_index == index)})
            }
        }
          
        return groupedBytab;
    }

    getAgencyTabErrorMap(errorFields, tabHeaders) {
        let section_tab = [
            {cname: 'name', label: 'Agency Name', section: 'Information', tab_index: 0},
            {cname: 'legal_name', label: 'Legal Name', section: 'Information', tab_index: 0},
            {cname: 'email', label: 'Email', section: 'Information', tab_index: 0},
            {cname: 'phone', label: 'Phone number', section: 'Information', tab_index: 0},
            {cname: 'address', label: 'Address', section: 'Information', tab_index: 0},
            {cname: 'country_id', label: 'Country', section: 'Information', tab_index: 0},
            {cname: 'city_id', label: 'City', section: 'Information', tab_index: 0},
      
            {cname: 'registration_number', label: 'RICS Registration number', section: 'Memberships', tab_index: 1},
            {cname: 'description', label: 'Description/Expertise on this market', section: 'Descriptions', tab_index: 1},
            {cname: 'source_of_data', label: 'Source of data', section: 'Descriptions', tab_index: 1},
            {cname: 'complain_of_procedure', label: 'Complaints handling procedure', section: 'Descriptions', tab_index: 1},
            
            {cname: 'hourly_rate', label: 'Hourly Rate', section: 'Payment', tab_index: 3},
            {cname: 'currency', label: 'Currency', section: 'Payment', tab_index: 3},

            {cname: 'floor_numbering_scheme_id', label: 'Standard Floor Numbering Scheme', section: 'Settings', tab_index: 6},
        ]

        let filteredErrors = section_tab.filter(item => errorFields.includes(item.cname)).sort((a, b) => a.tab_index - b.tab_index)
        let groupedBytab = [];
      
        for (let index = 0; index < tabHeaders.length; index++) {
            if (section_tab.find(item => item.tab_index == index) != undefined) {
                groupedBytab.push({tab_index: index, tab_cnames: filteredErrors.filter(item => item.tab_index == index)})
            }
        }
          
        return groupedBytab;
    }

    getUserTabErrorMap(errorFields, tabHeaders) {
        let section_tab = [
            {cname: 'email', label: 'Email', section: 'Basic info', tab_index: 0},
            {cname: 'first_name', label: 'Firstname', section: 'Basic info', tab_index: 0},
            {cname: 'last_name', label: 'Lastname', section: 'Basic info', tab_index: 0},
            {cname: 'role_id', label: 'Role', section: 'Basic info', tab_index: 0},
            {cname: 'qualification_id', label: 'Qualification', section: 'Basic info', tab_index: 0},
            {cname: 'valuer_experience', label: 'Experience of valuer', section: 'Basic info', tab_index: 0},
            {cname: 'insurance_policy', label: 'Insurance policy', section: 'Basic info', tab_index: 0},
            {cname: 'standard_limitations', label: 'Standard limitations', section: 'Basic info', tab_index: 0},
      
            {cname: 'description', label: 'Description/Expertise on this market', section: 'Descriptions', tab_index: 1},
            {cname: 'source_of_data', label: 'Source of data', section: 'Descriptions', tab_index: 1},
            {cname: 'complain_of_procedure', label: 'Complaints handling procedure', section: 'Descriptions', tab_index: 1},
            {cname: 'certificate_required', label: 'At least one membership is required', section: 'Descriptions', tab_index: 1},
            {cname: 'certificates_prime_ref', label: 'At least one membership with prime reference', section: 'Memberships', tab_index: 1},
            
            {cname: 'consideration', label: 'Considerations', section: 'Pictures & documents', tab_index: 2},
      
            {cname: '', label: '', section: 'Settings', tab_index: 3},
        ]

        let filteredErrors = section_tab.filter(item => errorFields.includes(item.cname)).sort((a, b) => a.tab_index - b.tab_index)
        let groupedBytab = [];
      
        for (let index = 0; index < tabHeaders.length; index++) {
            if (section_tab.find(item => item.tab_index == index) != undefined) {
                groupedBytab.push({tab_index: index, tab_cnames: filteredErrors.filter(item => item.tab_index == index)})
            }
        }
          
        return groupedBytab;
    }

    getBuildingTabErrorMap(isComplex, errorFields, tabHeaders) {
        let section_tab = [
            {cname: 'draw', label: 'Draw map below', section: 'Location', tab_index: 0},
            {cname: 'city_id', label: 'City', section: 'Location', tab_index: 0},
            {cname: 'latitude', label: 'Map location', section: 'Location', tab_index: 0},
            {cname: 'location_grade_id', label: 'Location grade', section: 'Location', tab_index: 0},
            {cname: 'address', label: 'Address', section: 'Location', tab_index: 0},

            {cname: 'land_parcel_name', label: 'Land parcel ID', section: 'Parcel identification', tab_index: 0},
            {cname: 'name', label: 'Land parcel ID', section: 'Parcel identification', tab_index: 0},
            {cname: 'coordinate_reference_system_id', label: 'Coordinate reference system', section: 'Parcel identification', tab_index: 0},
            {cname: 'property_type_id', label: 'Property sub-type', section: 'Parcel identification', tab_index: 0},

            {cname: 'completion_year', label: 'Completion year', section: 'About the building', tab_index: 1},
            {cname: 'building_type', label: 'Building type', section: 'About the building', tab_index: 1},
            {cname: 'building_grade_id', label: 'Building grade', section: 'About the building', tab_index: 1},
      
            {cname: 'description', label: 'Description/Expertise on this market', section: 'Descriptions', tab_index: 1},
            {cname: 'source_of_data', label: 'Source of data', section: 'Descriptions', tab_index: 1},
            {cname: 'complain_of_procedure', label: 'Complaints handling procedure', section: 'Descriptions', tab_index: 1},
            {cname: 'sub_type_category_id', label: 'Sub-type category', section: 'About the property', tab_index: 1},
            {cname: 'sub_category_id', label: 'Sub-category', section: 'About the property', tab_index: 1},
            {cname: 'handover_standard_id', label: 'Handover standard', section: 'About the property', tab_index: 1},
            {cname: 'floor_details', label: 'Floor location', section: 'About the property', tab_index: 1},
            {cname: 'state_of_repair_id', label: 'State of repair', section: 'About the property', tab_index: 1},
            {cname: 'number_of_units', label: 'Number of units', section: 'About the property', tab_index: 1},
            {cname: 'parking_type_id', label: 'Parking type', section: 'About the property', tab_index: 1},

            {cname: 'property_grade_id', label: 'Property grade', section: 'About the property', tab_index: 1},

            {cname: 'above_floors', label: 'Number of floors', section: 'Internal aspect', tab_index: 1},
            {cname: 'other_aspects', label: 'Other (Please specify)', section: '', tab_index: 1},
      
            {cname: 'window_type_id', label: 'Windows', section: 'Internal aspect', tab_index: 1},
            {cname: 'ceiling_id', label: 'Ceilings', section: 'Internal aspect', tab_index: 1},
            {cname: 'walls_and_partition_id', label: 'Walls and partitions', section: 'Internal aspect', tab_index: 1},
            {cname: 'floor_type_id', label: 'Floors', section: 'Internal aspect', tab_index: 1},
            {cname: 'woodwork', label: 'Woodwork', section: 'Internal aspect', tab_index: 1},
            {cname: 'bathroom_fitting_id', label: 'Bathroom fittings', section: 'Internal aspect', tab_index: 1},
            {cname: 'height', label: 'Height', section: 'Internal aspect', tab_index: 1},
            {cname: 'cubic_content', label: 'Cubic content', section: 'Internal aspect', tab_index: 1},
            {cname: 'storage_type_id', label: 'Storage type', section: 'Internal aspect', tab_index: 1},
            {cname: 'shop_front_type_id', label: 'Shop front type', section: 'Internal aspect', tab_index: 1},
            

            {cname: 'chimney_stack_id', label: 'Chimney stack', section: 'External aspect', tab_index: 1},
            {cname: 'roof_covering_id', label: 'Roof cover', section: 'External aspect', tab_index: 1},
            {cname: 'rainwater_pipe_id', label: 'Rainwater pipe', section: 'External aspect', tab_index: 1},
            {cname: 'outside_door_id', label: 'Outside door', section: 'External aspect', tab_index: 1},
            {cname: 'mainwall_id', label: 'Main wall', section: 'External aspect', tab_index: 1},
            {cname: 'window_type_id', label: 'Window type', section: 'External aspect', tab_index: 1},
            {cname: 'conservatory_id', label: 'Conservatory', section: 'External aspect', tab_index: 1},
            {cname: 'other_joinery_id', label: 'Other joinery', section: 'External aspect', tab_index: 1},
            {cname: 'loading_dock', label: 'Loading dock', section: 'External aspect', tab_index: 1},
            {cname: 'external_facade_id', label: 'External facade', section: 'External aspect', tab_index: 1},
      
            {cname: 'size', label: 'Size', section: 'Sizes', tab_index: 1},

            {cname: 'grounds', label: 'External Area', section: 'Grounds & external areas', tab_index: 1},            
            {cname: 'indoor_garage', label: 'Indoor garage', section: 'Grounds', tab_index: 1},
            {cname: 'outdoor_garage', label: 'Outdoor garage', section: 'Grounds', tab_index: 1},
            {cname: 'garden_id', label: 'Garden', section: 'Grounds', tab_index: 1},
            {cname: 'outdoor_space_id', label: 'Outdoor space', section: 'Grounds', tab_index: 1},
            
            {cname: 'pic', label: 'Building picture', section: 'Pictures & documents', tab_index: 2},
      
        ]

        let filteredErrors = section_tab.filter(item => errorFields.includes(item.cname)).sort((a, b) => a.tab_index - b.tab_index)
        let groupedBytab = [];
      
        for (let index = 0; index < tabHeaders.length; index++) {
            if (section_tab.find(item => item.tab_index == index) != undefined) {
                groupedBytab.push({tab_index: index, tab_cnames: filteredErrors.filter(item => item.tab_index == index)})
            }
        }
          
        return groupedBytab;
    }

    compareTwoDate(d1: Date, d2: Date) {
        const date1 = new Date(d1.getTime());
        const date2 = new Date(d2.getTime());
        date1.setUTCHours(0, 0, 0, 0);
        date2.setUTCHours(0, 0, 0, 0);
        if (date1.getTime() == date2.getTime()) {
            return 0;
        }
        if (date1.getTime() > date2.getTime()) {
            return 1
        }
        return -1;
    }
}
