<!--begin::Notifications-->
<div ngbDropdown placement="bottom-right" autoClose="outside" class="dropdown dropdown-fluid">
    <!--begin::Toggle-->
    <div ngbDropdownToggle class="topbar-item">
        <ng-container *ngIf="useSVG">
            <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1" [ngClass]="{'pulse pulse-primary': (pulsing$ | async)}">
                <span class="svg-icon svg-icon-xl svg-icon-primary" [inlineSVG]="icon"></span>
                <span class="pulse-ring"></span>
                <span class="badge badge-pill badge-danger mad-badge-notify" [hidden]="(cnt | async )== 0">{{cnt | async}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="!useSVG">
            <i [ngClass]="icon"></i>
        </ng-container>
    </div>
    <!--end::Toggle-->

    <!--begin::Dropdown-->
    <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
        <form>
            <!--begin::Header-->
            <div class="d-flex flex-column pt-10 bp-10 bgi-size-cover bgi-no-repeat rounded-top"
                 [ngStyle]="{'background-image': backGroundStyle()}"
            style="height: 120px;">
                <!--begin::Title-->
                <h4 class="d-flex flex-center rounded-top">
                    <span class="text-white">User Notifications</span>
                    <span class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
                          (click)="removeAll()" [hidden]="(entitySubject | async  ).length == 0">Clear All
					</span>
                </h4>
                <!--end::Title-->
                <!--begin::Tabs-->
                <!--<ul ktTabClickEvent-->
                <!--class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-2 px-6"-->
                <!--role="tablist">-->
                <!--<li class="nav-item">-->
                <!--<a (click)="tab.select('tab-id-1')" class="nav-link active show" data-toggle="tab"-->
                <!--href="javascript:;" role="tab" aria-selected="true">Alerts</a>-->
                <!--</li>-->
                <!--<li class="nav-item">-->
                <!--<a (click)="tab.select('tab-id-2')" class="nav-link" data-toggle="tab" href="javascript:;"-->
                <!--role="tab" aria-selected="false">Events</a>-->
                <!--</li>-->
                <!--<li class="nav-item">-->
                <!--<a (click)="tab.select('tab-id-3')" class="nav-link" data-toggle="tab" href="javascript:;"-->
                <!--role="tab" aria-selected="false">Logs</a>-->
                <!--</li>-->
                <!--</ul>-->
                <!--end::Tabs-->
            </div>
            <!--end::Header-->

            <ngb-tabset #tab="ngbTabset" class="tab-content">
                <ngb-tab id="tab-id-1" class="tab-pane">
                    <ng-template ngbTabContent>
                        <div class="p-8">
                            <div class="pr-7 mr-n7 scroll" [perfectScrollbar]="{wheelPropagation: false}"
                                 [ngStyle]="{'max-height': '300px', 'position': 'relative'}">
                                <div class="d-flex align-items-center mb-6"
                                     *ngFor="let item of (entitySubject| async)">
                                    <div class="symbol symbol-40 symbol-light-primary mr-5">
										<span class="symbol-label">
											<!--<span [inlineSVG]="icons[item.type]"-->
                                            <!--class="svg-icon svg-icon-lg svg-icon-primary"></span>-->

                                            <i *ngIf="[1, 4].indexOf(item.type) >= 0" class="flaticon2-add kt-font-success"></i>
                                            <i *ngIf="[1, 4, 10, 11, 12, 21, 22, 23, 24, 25, 26, 27, 28, 29, 31, 32, 33, 35, 36].indexOf(item.type) < 0"
                                               class="flaticon-users-1 kt-font-warning"></i>
                                            <i *ngIf="[21, 22, 23].indexOf(item.type) >= 0" class="flaticon-upload kt-font-primary"></i>
                                            <i *ngIf="[31, 32].indexOf(item.type) >= 0" class="flaticon-exclamation-1 kt-font-info"></i>
                                            <i *ngIf="[10].indexOf(item.type) >= 0" class="flaticon-edit kt-font-primary"></i>
                                            <i *ngIf="[35, 36].indexOf(item.type) >= 0" class="flaticon-reply kt-font-info"></i>
                                            <i *ngIf="[11, 12, 24, 25, 26, 27, 28, 29, 33].indexOf(item.type) >= 0" class="flaticon-delete-1 kt-font-danger"></i>
										</span>
                                    </div>
                                    <div class="d-flex flex-column font-weight-bold">
                                        <a (click)="goToLink(item)" class="text-dark text-hover-primary mb-1 font-size-lg">
                                            {{item.title}}
                                        </a>
                                        <span class="text-muted">
                                            <!--{{item.created_at}}-->
                                            {{item.created_at | dateAgo}}
										</span>
                                    </div>
                                    <span class="float-right cursor-pointer" (click)="removeOne(item.id)" matTooltip="Remove notification">
                                        <i class="flaticon2-trash kt-font-hover-dark"></i>
                                    </span>
                                </div>

                                <div class="d-flex flex-center text-center text-muted"
                                     *ngIf="!(entitySubject|async).length"
                                     style="min-height: 200px;">
                                    You have no notifications at the moment.
                                </div>
                                <!--end::Nav-->
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </form>
    </div>
    <!--end::Dropdown-->
</div>
<!--end::Notifications-->