import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpEventType, HttpHeaderResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { User } from "./mad-auth.store";
import { MadPermission } from "./mad-permission";
import { ApiFilter, DeleteMadRole, MadRole, MadRoleCollectionResponse, MadRoleResourceResponse } from "./mad-roles.store";
import { MadUser } from "./mad-user";
import { SubdomainService } from "../_base/subdomain.service";


@Injectable()
export class MadAuthService {
  private _outseta: any;
  private _user$ = new BehaviorSubject<MadUser | null>(null);

  API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/auth`
  API_MULTIMEDIA_LOGIN_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/multimedia/login`;
  API_MULTIMEDIA_ME = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/multimedia/me`;
  API_USERS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/users`;
  constructor(
    private _http: HttpClient,
    @Inject(DOCUMENT) private _document: Document,
    private _router: Router,
    private subDomainService: SubdomainService
  ) {
    this.load();
  }

  /** Auth */
  getToken(): string {
    return this._outseta.getAccessToken()
  }

  getAuthHeaders(): HttpHeaders {
    const accessToken = this.getToken();
    if (accessToken) {
      return new HttpHeaders({
        'Content-Type': "application/json",
        'Authorization': `Bearer ${accessToken}`
      })
    }
    return new HttpHeaders({
      'Content-Type': "application/json"
    })
  }

  getAuthHeadersForUpload(): HttpHeaders {
    const accessToken = this.getToken();
    if (accessToken) {
      return new HttpHeaders({
        'Authorization': `Bearer ${accessToken}`
      })
    }
    return new HttpHeaders()
  }

  async login(token: string): Promise<User> {
    this._outseta.setAccessToken(token)
    const _user = await this._outseta.getUser()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
    const res = await this._http.get<any>(`${this.API_URL}/users/${_user.Uid}`, { headers }).toPromise()
    const madUser = res.data
    const user: User = {
      ...madUser,
      provider: {
        uid: _user.Uid,
        email: _user.Email,
        plan_uid: _user.Account?.CurrentSubscription?.Plan?.Uid,
        subscription_uid: _user.Account?.CurrentSubscription?.Uid
      },
    }
    return user
  }

  async setAccessToken(token: string) {
    this._outseta.setAccessToken(token);
    const user = await this._outseta.getUser();
    return user;
  }

  logout() {
    this._outseta.setAccessToken(null)
  }


  isLoggedIn(): Observable<boolean> {
    return this._user$.pipe(map(user => {
      return user !== null
    }))
  }

  checkPermission(permissions: Array<MadPermission>): Observable<boolean> {
    const headers = this.getAuthHeaders()
    return this._http.post<{ data: boolean }>(`${this.API_URL}/check-permissions`, { permissions }, {
      headers
    }).pipe(map(res => res.data))
  }

  openProfile() {
    this._outseta.profile.open({tab: 'profile'})
  }

  
  multimediaLogin(url: string, pwd: string, byPassAbort: boolean): Observable<any> {
      return this._http.post<User>(this.API_MULTIMEDIA_LOGIN_URL, {url, pwd, byPassAbort});
  }

  getMultimediaByToken(): Observable<any> {
      const multimediaToken = localStorage.getItem('multimediaToken');
      const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + multimediaToken);
      return this._http.get<User>(this.API_MULTIMEDIA_ME, {headers: httpHeaders});
  }

  /** Outseta */
  load() {
    const window = getWindow()
    this._outseta = window['Outseta']
  }

  public loadRegisterScript(renderer2: Renderer2) {
    let script_option = renderer2.createElement('script');
    script_option.text = `
      var o_signup_options = {
        "id": "Outseta",
        "domain": "interval.outseta.com",
        "load": "auth",
        "auth": {
          "widgetMode": "register",
          "planUid": "B9l1rvW8",
          "planPaymentTerm": "month",
          "skipPlanOptions": true,
          "id": "signup_embed",
          "mode": "embed",
          "selector": "#signup-embed",
        }
      }
    `
    renderer2.appendChild(this._document.body, script_option)

    let script_src = renderer2.createElement('script');
    script_src.src = 'https://cdn.outseta.com/outseta.min.js'
    script_src.dataset.options = "o_signup_options"
    renderer2.appendChild(this._document.body, script_src)
  }

  public loadLoginScript(renderer2: Renderer2, url: string) {
    let script_option = renderer2.createElement('script');
    script_option.text = `
      var o_login_options = {
        "id": "Outseta",
        "domain": "interval.outseta.com",
        "load": "auth",
        "auth": {
          "widgetMode": "login",
          "id": "login_embed",
          "mode": "embed",
          "selector": "#login-embed",
          "authenticationCallbackUrl": "${url}"
        }
      };
    `
    renderer2.appendChild(this._document.body, script_option);

    let script_src = renderer2.createElement('script');
    script_src.src = 'https://cdn.outseta.com/outseta.min.js'
    script_src.dataset.options = "o_login_options"
    renderer2.appendChild(this._document.body, script_src);
  }

  public loadLoginScriptProd(renderer2: Renderer2)
  {
    let script_option = renderer2.createElement('script');
    script_option.text = `
      var o_login_options = {
        "id": "Outseta",
        "domain": "interval.outseta.com",
        "load": "auth",
        "auth": {
          "widgetMode": "login",
          "id": "login_embed",
          "mode": "embed",
          "selector": "#login-embed",
        }
      };
    `
    renderer2.appendChild(this._document.body, script_option);

    let script_src = renderer2.createElement('script');
    script_src.src = 'https://cdn.outseta.com/outseta.min.js'
    script_src.dataset.options = "o_login_options"
    renderer2.appendChild(this._document.body, script_src);
  }

  /** Roles CRUD */
  getRoles(filter: ApiFilter): Observable<{ items: MadRole[], total: number }> {
    let params = new HttpParams()
    Object.entries(filter).forEach(([k, v]) => {
      if (v != undefined && v != null) {
        params = params.set(k, v)
      }
    })
    const url = `${this.API_URL}/mad-roles`
    return this._http.get<MadRoleCollectionResponse>(url, { params })
      .pipe(map(res => ({
        items: res.data,
        total: res.pagination.total
      })))
  }

  createRole(role: MadRole): Observable<MadRole> {
    const url = `${this.API_URL}/mad-roles`
    return this._http.post<MadRoleResourceResponse>(url, role)
      .pipe(map(res => res.data))
  }

  updateRole(role: MadRole): Observable<MadRole> {
    const url = `${this.API_URL}/mad-roles/${role.id}`
    return this._http.put<MadRoleResourceResponse>(url, role)
      .pipe(map(res => res.data))
  }

  deleteRole(id: number): Observable<any> {
    const url = `${this.API_URL}/mad-roles/${id}`
    return this._http.delete(url, {headers: this.getAuthHeaders()});
  }

  private _getAuthHeaderForUpload(): HttpHeaders {
    const accessToken = this.getToken();
    if (accessToken) {
      return new HttpHeaders({
        'Authorization': `Bearer ${accessToken}`
      })
    }
    return new HttpHeaders({
    })
  }
  public upload(data: FormData) {
      const headers = this._getAuthHeaderForUpload()
      const uploadURL = `${this.API_USERS_URL}/upload`;

      return this._http.post<any>(uploadURL, data, {
          headers,
          reportProgress: true,
          observe: 'events',
      }).pipe(map((event) => {
              switch (event.type) {

                  case HttpEventType.UploadProgress:
                      const progress = Math.round(100 * event.loaded / event.total);
                      return {status: 'progress', message: progress};

                  case HttpEventType.Response:
                      return event.body;
                  default:
                      return `Unhandled event: ${event.type}`;
              }
          })
      );
  }
}

function getWindow(): any {
  return window
}