import {
    AfterViewInit,
    ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren,
    ViewEncapsulation, ChangeDetectorRef, HostListener, ElementRef
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject, Subscription, throwError} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {LayoutUtilsService, MessageType, QueryParamsModel, TypesUtilsService} from '../../../../core/_base/crud';
import {LayoutConfigService, SubheaderService} from '../../../../core/_base/layout';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Location, LocationStrategy} from '@angular/common';
import {AppState} from '../../../../core/reducers';
import {ToeModel, CoiDetailsTPModel, ToeStatusUpdated, ToeTpMultimediaModel} from '../../../../core/toe';
import {skip, take, takeUntil, delay, catchError, startWith, filter, map} from 'rxjs/operators';
import {each, findIndex} from 'lodash';
import {
    AllClientsRequested,
    Client,
    ClientContactModel,
    selectAllClients,
    selectAllContacts,
    CountryModel,
    selectAllCountries,
    AgencyModel,
    AllCountriesRequested, AllCitiesRequested,
} from '../../../../core/admin';
import {selectValuersInStore, User} from '../../../../core/auth';
import {AllUsersOfRoleRequested } from '../../../../core/auth';
import { currentUser } from 'src/app/core/mad-auth/mad-auth.store';
import {
    AllConflictRequested, selectAllConflicts, ConflictOfInterestModel, ToeOnServerCreated,
    selectLastCreatedToeId, selectToesActionLoading, selectToeById, ToeService, ToeUpdated, ConflictService, ToeStandardModel,
    ToeReportService, AllToeReportsRequested, selectAllToeReports, ToeReport, ToeReportOnServerCreated, selectLastCreatedToeReportId,
    ToeReportUpdated,
} from '../../../../core/toe';
import {
    AllPurposeOfValuationsRequested,
    AllReportDeliveryMethodsRequested,
    PurposeOfValuation,
    ReportDeliveryMethod,
    selectAllPurposeOfValuations,
    selectAllReportDeliveryMethods,
    AllValuationStandardRequested,
    AllIvsStandardsRequested,
    AllRicsStandardsRequested,
    AllUnitMeasurementsRequested,
    ValuationStandard,
    IvsStandard,
    RicsStandard,
    UnitMeasurement,
    selectAllValuationStandard,
    selectAllIvsStandards,
    selectAllRicsStandards,
    selectAllUnitMeasurements,
} from '../../../../core/linked-tables';
import {
    AllContactsRequested,
    AgencyService,
} from '../../../../core/admin';
import {ConflictType} from '../../../../core/toe/_static-data/conflict-type';
import {CookieService} from 'ngx-cookie-service';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {MatTableDataSource} from '@angular/material/table';
import {ConflictModel} from '../../../../core/toe/_models/conflict.model';
import {SupportingValuerModel} from '../../../../core/toe/_models/supporting-valuer.model';
import {AssignmentModel} from '../../../../core/assignment';
import {Update} from '@ngrx/entity';
import {TranslateService} from '@ngx-translate/core';
import {FileUploadService} from '../../../../core/file-upload/_services';
import {ConflictDetailComponent} from '../_sub/conflict-detail/conflict-detail.component';
import {UploadFileComponent} from '../../shared_components/upload-file/upload-file.component';
import {
    AllAssetClassSERRequested,
    AssetClassDefaultAssumptionModel, AssetClassDepartureModel, AssetClassesPageRequested, AssetClassModel,
    AssetClassSourceExternalReferenceModel,
    AssetClassSpecialAssumptionModel, PaymentModel,
    PaymentTaxModel, PaymentTermModel, selectAssetClassByToeId, selectAssetClassesPageLoading,
    ValuationModel
} from '../../../../core/asset_class';
import {SupportValuerListComponent} from '../_sub/support-valuer-list/support-valuer-list.component';
import {SignedToeDocModel} from '../../../../core/file-upload';
import {CountryData} from '../../admin-management/countries/countries-list/local-data';
import {FileDialogComponent} from '../_sub/file-dialog/file-dialog.component';
import {TpAddDialogComponent} from '../_sub/tp-add-dialog/tp-add-dialog.component';
import {SignedToeDialogComponent} from '../_sub/signed-toe-dialog/signed-toe-dialog.component';
import {environment} from '../../../../../environments/environment';
import {ImageViewerDialogComponent} from '../../shared_components/image_viewer/image-viewer.dialog.component';
import {ToeReportEditDialogComponent} from '../../../partials/content/crud/toe-report-edit-dialog/toe-report-edit-dialog.component';
import {GeneratedReportEditDialog} from '../_sub/generated-report-edit-dialog/generated-report-edit-dialog.component';
import { FilterCoiService } from './filter-coi.service';
import { MatRadioChange } from '@angular/material/radio';
import { SaveState } from 'src/app/core/meta';
import { SaveToeData, SaveToeDialogComponent } from '../_sub/save-toe-dialog/save-toe-dialog.component';
import { AddToeTemplate, ToeTemplateModel, TpTemplateModel, UpdateToeTemplate } from 'src/app/core/template';
import { FileDialogV2Component } from '../_sub/file-dialog-v2/file-dialog-v2.component';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';
import { TpTemplateListModalComponent } from '../_sub/tp-template-list-modal/tp-template-list-modal.component';
import { TpTemplateModalComponent } from '../../template/templates/tp-template/_sub/tp-template-modal/tp-template-modal.component';
import { LocalVpgaModel } from 'src/app/core/asset_class/_models/local-vpga.model';
import { IToeTeamMember } from '../../shared_components/toe-members/toe-members.component';
import { AcPaymentTermsAltComponent } from '../../shared_components/payment/ac-payment-terms-alt/ac-payment-terms-alt.component';
import { TabHeader } from '../../shared_components/tab-header/tab-header.component';
import { PropertyTypes } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { ClientModalComponent } from '../../shared_components/client/client-modal/client-modal.component';
import { LoadConflictOfAgencyAlreadyPerformedValuationOnTheTP, LoadConflictOfAgencyHasPreviousInvolvementWithTheClient, LoadConflictOfLeadValuerAlreadyPerformedValuationOnTheTP, LoadConflictOfLeadValuerHasPreviousInvolvementWithTheClient, ResetToeConflictsState } from 'src/app/core/toe/_actions/toe-conflict.actions';
import { selectHasToeConflicts } from 'src/app/core/toe/_selectors/toe-conflicts.selectors';
import { ToeConflictsModalComponent } from '../_sub/toe-conflicts-modal/toe-conflicts-modal.component';
import { ToeConflictTpQuery } from 'src/app/core/toe/_models/toe-conflicts-model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TimezoneService } from 'src/app/core/system-settings/timezone.service';
import { PostValuationDeliveryServiceComponent } from '../../shared_components/post-valuation-delivery-service/post-valuation-delivery-service.component';
import { CoreStandardsService } from 'src/app/core/linked-tables/_services/core-standards.service';
import { SupportValuerTemplateListModalComponent } from '../_sub/support-valuer-template-list-modal/support-valuer-template-list-modal.component';
import { Quote } from 'src/app/core/v2/types/quote';
import file from 'src/assets/plugins/formvalidation/src/js/validators/file';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'kt-toe-edit',
    templateUrl: './toe-edit.component.html',
    styleUrls: ['./toe-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})

export class ToeEditComponent implements OnInit, OnDestroy, AfterViewInit {
    readonly CONSTANTS = CONSTANTS;
    // Public properties
    assignmentId: number;
    assignment: AssignmentModel;
    boolTrue = true;
    toe: ToeModel;
    oldToe: ToeModel;
    oldToeAdditionalFiles = [];
    selectedTab = 0;
    loading$: Observable<boolean>;
    tpListLoading$: Observable<boolean>;
    toeForm1: UntypedFormGroup;
    toeForm2: UntypedFormGroup;
    agencyInfo: AgencyModel;
    isComplete: boolean = false;

    // Permissions
    canValidate = false;
    canGenerateToeReport = false;
    isLVSubject = new BehaviorSubject<boolean>(false);
    isPMSubject = new BehaviorSubject<boolean>(false);

    @ViewChild('fileInput') fileInput: ElementRef;

    instructionDate: Date;
    draftDate: Date;
    finalDate: Date;

    hasFormErrors = false;
    generating = false;
    delivered = false;
    tpChanged = false;
    paymentFormChange = false;
    errorMessage = this.translate.instant('TOE.GENERAL_WARNING');

    @ViewChildren('cdComponents') cdComponents: QueryList<ConflictDetailComponent>;
    @ViewChildren('ddComponents') ddComponents: QueryList<ConflictDetailComponent>;

    @ViewChild(UploadFileComponent)
    public uploadFileComponent: UploadFileComponent;

    // step 1
    clients: Client[] = [];

    contacts: ClientContactModel[] = [];
    valuers: User[] = [];
    targetProperties: AssetClassModel[] = [];
    coiProperties: CoiDetailsTPModel[] = [];
    valuersSubject = new BehaviorSubject<SupportingValuerModel[]>([]);
    oldValuersSubject = [];
    currentUser: User;

    ivs: IvsStandard[] = [];
    rics: RicsStandard[] = [];
    units: UnitMeasurement[] = [];

    toeStandards: ToeStandardModel[] = [];

    statuOfValuer = [
        {label: 'External', value: 0, default: true},
        {label: 'Internal', value: 1, default: false}
    ]
    decisionsExtra: any[] = [
        {label: 'No', value: 0, default: true},
        {label: 'Yes', value: 1, default: false},
    ];

    @ViewChild(SupportValuerListComponent, {static: true}) valuerListComponent;

    // step 2

    displayedColumns = ['name', 'surface', 'target_property_type_id', 'type_of_inspection', 'valuations', 'is_complete', 'actions'];
    dataSource = new MatTableDataSource([]);
    decisions = [
        {label: 'Desktop valuation (no inspection)', value: 1, default: true},
        {label: 'External Inspection (Drive by)', value: 2, default: false},
        {label: 'Internal inspection', value: 3, default: false},
        {label: 'Full property survey', value: 4, default: false}
    ];

    // step 2 3
    selectedPrevKnowledge = 0;
    selectedFinancialInterest = 0;
    purposeOfValuationID = 0;
    ciData: ConflictOfInterestModel[] = [];
    ciTemplateData$: BehaviorSubject<ConflictOfInterestModel[]> = new BehaviorSubject([]);
    dcData: ConflictOfInterestModel[] = [];

    // step 4
    purposeOfValuations: PurposeOfValuation[] = [];
    reportDeliveryMethods: ReportDeliveryMethod[] = [];
    selectedRdm: number[] = [];
    selectedAgencyDocuments: number[] = [];
    selectedTpMultimedias: ToeTpMultimediaModel[] = [];

    // Private properties
    private subscriptions: Subscription[] = [];

    agencyDocIncludes = [
        {id: 1, name: 'Document Title'},
    ];

    leadValuerDocIncludes = [
        {id: 1, name: 'Document Title'},
        {id: 2, name: 'Organisation: Certification'},
    ];

    // searchable selection
    @ViewChild('singleSelect', {static: true}) singleSelect: MatSelect;

    /** control for the MatSelect filter keyword */
    public filterCtrl: UntypedFormControl = new UntypedFormControl();

    protected _onDestroy = new Subject<void>();
    /** list of models filtered by search keyword */
    public filteredSelectionModels: ReplaySubject<Client[]> = new ReplaySubject<Client[]>(1);

    // searchable selection
    @ViewChild('singleSelectCurrency', {static: true}) singleSelectCurrency: MatSelect;

    displayedColumnsPayment = [
        'id_custom',
        'total_price_amount',
        'discount',
        'vat',
        'vat_other',
        'exchange_rate'
    ];
    displayedColumnsBreakDown = ['asset_class_name', 'methods_to_value_name', 'approaches_to_value_name', 'total_price'];
    status = 'new';
    backTitle = this.translate.instant('TOE.TOOLTIP.BACK_TITLE');

    dataSourcePayment = new MatTableDataSource();
    dataSourceBreakDown = new MatTableDataSource();

    displayedColumnsGeneratedReport = ['created_at', 'file_size', 'toe_status', 'sent_to', 'actions'];
    dataSourceGeneratedReport = new MatTableDataSource<ToeReport>();

    // Signed Report
    loadSpinning = new BehaviorSubject<boolean>(true);
    dataSourceSignedReport = new MatTableDataSource();
    signedReports = new BehaviorSubject<SignedToeDocModel[]>([]);
    displayedColumnsSignedReport = ['upload_date', 'file_size', 'upload_by', 'check', 'version', 'actions'];
    isInProgressSub = new BehaviorSubject<boolean>(false);
    progressSub = new BehaviorSubject<number>(0);
    hasFailedSub = new BehaviorSubject<boolean>(false);
    errorSub = new BehaviorSubject<string>('');
    currentSignedDocId = new BehaviorSubject<number>(0);

    protected cancelReq = new Subject<void>();
    docs = ['xlsx', 'xls', 'doc', 'docx'];


    countriesData = CountryData.countriesWithNoDuplicateCurrency();
    allCountries: CountryModel[] = [];

    @ViewChild(AcPaymentTermsAltComponent)
    private acPaymentTermsAltComponent: AcPaymentTermsAltComponent;
    @ViewChild(PostValuationDeliveryServiceComponent, {static: false}) postValuationDeliveryServiceComponent: PostValuationDeliveryServiceComponent;
    payment: PaymentModel;
    paymentForm: UntypedFormGroup;

    paymentSummary: PaymentModel[] = [];

    paymentTaxesSubject = new BehaviorSubject<PaymentTaxModel[]>([]);
    paymentSummarySubject = new BehaviorSubject<PaymentModel[]>([]);
    valuationsSubject = new BehaviorSubject<ValuationModel[]>([]);
    paymentTermsSubject = new BehaviorSubject<PaymentTermModel[]>([]);
    public obs$: Observable<AssetClassModel[]>;
    valuationFee = 0;
    extraCost = 0;
    termsPercent = 0;

    currencySubject = new BehaviorSubject<string>('');
    unitSubject = new BehaviorSubject<string>('');
    totalPrice = new BehaviorSubject<number>(0);
    paymentTaxes: PaymentTaxModel[] = [];
    paymentTerms: PaymentTermModel[] = [];
    generatedReports = new BehaviorSubject<any[]>([]);
    saveState: boolean;
    isEdit = false;
    oldTotalPrice: number;
    checkTotalPrice = false;
    generateReportAsDraft = false;


    /** control for the MatSelect filter keyword */
    public filterCtrlCountriesData: UntypedFormControl = new UntypedFormControl();
    /** list of models filtered by search keyword */
    public filteredCountriesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    private oldTpCnt = 0;
    private additionalDocPath = null;
    public uploadedFiles = [];

    members$: BehaviorSubject<IToeTeamMember[]> = new BehaviorSubject([]);
    latestFinalReportDate: Date = new Date(null);
    tpMenus = [
        {title: 'Add Target Property', icon: 'flaticon2-add', refId: 1, disabled: false},
        {title: 'Add From Template', icon: 'flaticon2-add', refId: 2, disabled: false},
    ];
    tpMenus$ = new BehaviorSubject<number>(-1);

    selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
    selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
    tabHeaders: TabHeader[] = [
        {label: '1 - General', disabled: of(false)},
        {label: '2 - Target Properties', disabled: of(false)},
        {label: '3 - Conflict of interest', disabled: of(false)},
        {label: '4 - Disclosures', disabled: of(false)},
        {label: '5 - Delivery details', disabled: of(false)},
        {label: '6 - Payment', disabled: of(false)},
    ]

    // Template
    public isTemplate = false;
    private _templateName = null;
    private _templateDescr = null;
    private _templateID = undefined;
    private _templateAgencyID = undefined;
    public readonly = false;
    private _fromTemplate = false;
    public agencies: AgencyModel[] = [];

    hasToeConflicts$ = this.store.select(selectHasToeConflicts)
    private _toe$ = new BehaviorSubject<ToeModel>(null);
    private _assignement$ = new BehaviorSubject<AssignmentModel>(null)
    private _tps$ = new BehaviorSubject<AssetClassModel[]>([]);

    public hasMultimediaSupport = true;

    // Timzone
    public gowerningLawTimezone: string | null = null;

    // Payment
    disableSizebasedPricing = false;

    // Standards Used
    coreStandards$ = this.coreStandardsService.fetchListing().pipe(
        map(data => data.map(item => ({value: item.id, label: item.name})))
    )
    get coreStandardControl() {
        if (!this.toeForm1) {
            return null
        }
        return this.toeForm1.controls.core_standard_id
    }
    valuationStandards: ValuationStandard[] = [];

    // Quote
    private fromQuote: number = null;
    private _isFromQuote: boolean = false;

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param toeFB: FormBuilder
     * @param dialog
     * @param subheaderService: SubheaderService
     * @param layoutUtilsService: LayoutUtilsService
     * @param location
     * @param typesUtilsService
     * @param cookieService
     * @param toeService
     * @param conflictService
     * @param toeReportService
     * @param fileUploadService
     * @param store: Store<AppState>
     * @param layoutConfigService: LayoutConfigService
     * @param ref
     * @param agencyService
     * @param translate
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private toeFB: UntypedFormBuilder,
                private dialog: MatDialog,
                private subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private location: Location,
                public typesUtilsService: TypesUtilsService,
                private cookieService: CookieService,
                private toeService: ToeService,
                public conflictService: ConflictService,
                private toeReportService: ToeReportService,
                private fileUploadService: FileUploadService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private ref: ChangeDetectorRef,
                private agencyService: AgencyService,
                private translate: TranslateService,
                private filterCoiService: FilterCoiService,
                private readonlyService: ReadonlyService,
                private timezoneService: TimezoneService,
                private cdr: ChangeDetectorRef,
                private coreStandardsService: CoreStandardsService
                ) {
        this.saveState = false;
    }


    /**
     * On init
     */
    ngOnInit() {
        this.members$.subscribe(res => {
            this.valuersSubject.next(this._convertMemberToSupporting(res))

            this.valuationFee = res.reduce((prev, cur) => {
                return prev + Number(cur.total_price);
            }, 0);
        });
        this.readonly = this.readonlyService.isReadOnly();
        this.generating = false;
        window.Echo.channel('toe-report.1')
            .listen('ToeGenerateReport', (e) => {
                this.generating = false;
                localStorage.setItem('generatingReport', '0');
                this.toeReportService.getAllToeReports(this.toe.id).subscribe((res) => {
                    if (res) {
                        this.generatedReports.next(res.data.sort((a, b) =>
                            (new Date(a.created_at) < new Date(b.created_at)) ? 1 :
                                ((new Date(b.created_at) < new Date(a.created_at)) ? -1 : 0)));
                        this.toeDelivered();
                    }
                });
            });

        const userSubscription = this.store.pipe(
            // take(1),
            select(currentUser))
            .subscribe(res => {
                if (res) {
                    this.currentUser = res;
                    if (this.toeForm1 && this.toe && (this.toe.unit_of_measurement_id == undefined || this.toe.unit_of_measurement_id == null)) {
                        this.toeForm1.controls.unit_of_measurement_id.setValue(this.currentUser.settings.unit_of_measurement_id)
                    }
                }
            });
        this.subscriptions.push(userSubscription);

        // payment start
        const payment = new PaymentModel();
        payment.clear();
        this.paymentSummary.push(payment);
        this.paymentSummarySubject.next(this.paymentSummary);
        this.dataSourcePayment = new MatTableDataSource(this.paymentSummarySubject.value);
        // payment end

        this.subheaderService.setTitle('Terms of Engagement');
        this.fetchLT();

        this.instructionDate = new Date();
        this.draftDate = new Date();
        this.finalDate = new Date();

        this.loading$ = this.store.pipe(select(selectToesActionLoading));
        const tIndx = this.activatedRoute.snapshot.url.findIndex(s => s.path == 't');
        if (tIndx > -1) {
            this.isTemplate = true;
            if (this.activatedRoute.snapshot.params['template_id']) {
                this.activatedRoute.data.pipe(
                    takeUntil(this._onDestroy)
                ).subscribe(data => {
                    const template = data.template as ToeTemplateModel;
                    this._templateID = template.id;
                    this._templateName = template.name;
                    this._templateDescr = template.description;
                    this._templateAgencyID = template.agency_id;
                    if (this._templateAgencyID != -1) {
                        const agencyInfoSub = this.agencyService.getById(this._templateAgencyID).subscribe(res => {
                            this.agencyInfo = res.data;
                        })
                        this.subscriptions.push(agencyInfoSub);
                    }
                    this.isEdit = true;
                    const res = this.convertTemplateToTeoRelatedData(template, false);
                    this.oldToe = res.toe;
                    this.toe = Object.assign({}, this.oldToe);
                    this.toeStandards = Object.assign([], res.standards);
                    this.purposeOfValuationID = this.toe.purpose_of_valuation_id;
                    this.selectedFinancialInterest = this.toe.has_financial_interest;
                    this.selectedPrevKnowledge = this.toe.has_prev_knowledge;
                    this.selectedAgencyDocuments = this.toe.selected_agency_documents;

                    const _workers: SupportingValuerModel[] = res.workers;
                    this.oldValuersSubject = JSON.parse(JSON.stringify(_workers.sort((a, b) =>
                        (a.lead_valuer < b.lead_valuer) ? 1 : ((b.lead_valuer < a.lead_valuer) ? -1 : 0))));

                    this.valuersSubject.next(_workers.sort((a, b) =>
                        (a.lead_valuer < b.lead_valuer) ? 1 : ((b.lead_valuer < a.lead_valuer) ? -1 : 0)));
                    this.members$.next(this._convertSupportingToMember(this.valuersSubject.value));
                    this.selectedRdm = res.rdms;
                    // Todo
                    this.initToe();
                    this.payment = new PaymentModel();
                    this.payment.clear();
                    if (res.payment) {
                        const editedData = Object.assign({}, res.payment) as PaymentModel;
                        this.payment = Object.assign({}, editedData);
                        this.paymentTaxes = Object.assign([], res.paymentTaxes);
                        this.paymentTaxesSubject.next(this.paymentTaxes);
                        this.currencySubject.next(editedData.currency);

                        this.paymentTerms = Object.assign([], res.paymentTerms);
                        this.paymentTermsSubject.next(res.paymentTerms);
                    }
                    this.createFormPayment();
                    this.removeValidators();
                    this.uploadedFiles = this.toe.additional_files;
                })

            } else {
                this.toe = new ToeModel();
                this.toe.clear();
                this.toe.assignment_id = this.assignmentId;
                this.purposeOfValuationID = 0;
                this.selectedFinancialInterest = 0;
                this.selectedPrevKnowledge = 0;
                this.oldToe = Object.assign({}, this.toe);
                this.conflictService.reloadDisclosure();
                this.initToe();
                this.payment = new PaymentModel();
                this.payment.clear();
                this.createFormPayment();
                this.removeValidators();
            }
            const agengcySubscription = this.agencyService.getAll().subscribe(res => {
                this.agencies = [];
                if (res) {
                    this.agencies = res.data;
                }
            });
            this.subscriptions.push(agengcySubscription);
            // Template
        } else {
            const fromTemplateIndx = this.activatedRoute.snapshot.url.findIndex(s => s.path == 'add-from-t');
            const fromQuoteIndex = this.activatedRoute.snapshot.url.findIndex(s => s.path == 'add-from-quote');
            if (fromTemplateIndx > -1) {
                this._fromTemplate = true;
                this.activatedRoute.data.pipe(
                    takeUntil(this._onDestroy)
                ).subscribe(data => {
                    this.assignment = data.ass.data;
                    this.assignmentId = this.assignment.id;
                    const isProjectManager = this.currentUser.id === this.assignment.valuer_id;
                    this.isPMSubject.next(isProjectManager);
                    this.canValidate = this.canValidate || isProjectManager;

                    const template = data.template as ToeTemplateModel;
                    this._templateID = template.id;
                    this._templateName = template.name;
                    this._templateDescr = template.description;
                    this.isEdit = true;
                    const res = this.convertTemplateToTeoRelatedData(template, true);
                    this.oldToe = res.toe;
                    this.toe = Object.assign({}, this.oldToe);
                    this.toeStandards = Object.assign([], res.standards);
                    this.purposeOfValuationID = this.toe.purpose_of_valuation_id;
                    this.selectedFinancialInterest = this.toe.has_financial_interest;
                    this.selectedPrevKnowledge = this.toe.has_prev_knowledge;
                    this.additionalDocPath = (new Date()).getTime();
                    this.selectedAgencyDocuments = this.toe.selected_agency_documents;

                    const _workers: SupportingValuerModel[] = res.workers;
                    this.oldValuersSubject = JSON.parse(JSON.stringify(_workers.sort((a, b) =>
                        (a.lead_valuer < b.lead_valuer) ? 1 : ((b.lead_valuer < a.lead_valuer) ? -1 : 0))));

                    this.valuersSubject.next(_workers.sort((a, b) =>
                        (a.lead_valuer < b.lead_valuer) ? 1 : ((b.lead_valuer < a.lead_valuer) ? -1 : 0)));
                    this.members$.next(this._convertSupportingToMember(this.valuersSubject.value))
                    this.selectedRdm = res.rdms;
                    // Todo
                    this.initToe();
                    this.payment = new PaymentModel();
                    this.payment.clear();
                    if (res.payment) {
                        const editedData = Object.assign({}, res.payment) as PaymentModel;
                        this.payment = Object.assign({}, editedData);
                        this.paymentTaxes = Object.assign([], res.paymentTaxes);
                        this.paymentTaxesSubject.next(this.paymentTaxes);
                        this.currencySubject.next(editedData.currency);

                        this.paymentTerms = Object.assign([], res.paymentTerms);
                        this.paymentTermsSubject.next(res.paymentTerms);
                    }
                    this.createFormPayment();
                    this.uploadedFiles = this.toe.additional_files;
                })

            } else if (fromQuoteIndex > -1) {
                this.activatedRoute.data.pipe(
                    takeUntil(this._onDestroy)
                ).subscribe(data => {
                    this.assignment = data.ass.data;
                    this.assignmentId = this.assignment.id;
                    const isProjectManager = this.currentUser.id === this.assignment.valuer_id;
                    this.isPMSubject.next(isProjectManager)
                    this.canValidate = this.canValidate || isProjectManager

                    const quote = data.quote
                    this.fromQuote = quote.id
                    this._isFromQuote = true
                    this.isEdit = true;
                    const res = this.convertQuoteToToeRelatedData(quote)
                    this.oldToe = res.toe;
                    this.toe = Object.assign({}, this.oldToe);
                    this.toeStandards = Object.assign([], res.standards);
                    this.purposeOfValuationID = this.toe.purpose_of_valuation_id;
                    this.selectedFinancialInterest = this.toe.has_financial_interest;
                    this.selectedPrevKnowledge = this.toe.has_prev_knowledge;
                    this.additionalDocPath = (new Date()).getTime();
                    this.selectedAgencyDocuments = this.toe.selected_agency_documents;

                    const _workers: SupportingValuerModel[] = res.workers;
                    this.oldValuersSubject = JSON.parse(JSON.stringify(_workers.sort((a, b) =>
                        (a.lead_valuer < b.lead_valuer) ? 1 : ((b.lead_valuer < a.lead_valuer) ? -1 : 0))));

                    this.valuersSubject.next(_workers.sort((a, b) =>
                        (a.lead_valuer < b.lead_valuer) ? 1 : ((b.lead_valuer < a.lead_valuer) ? -1 : 0)));
                    this.members$.next(this._convertSupportingToMember(this.valuersSubject.value))
                    // Todo
                    this.initToe();
                    this.payment = new PaymentModel();
                    this.payment.clear();
                    if (res.payment) {
                        const editedData = Object.assign({}, res.payment) as PaymentModel;
                        this.payment = Object.assign({}, editedData);
                        this.paymentTaxes = Object.assign([], res.paymentTaxes);
                        this.paymentTaxesSubject.next(this.paymentTaxes);
                        this.currencySubject.next(editedData.currency);

                        this.paymentTerms = Object.assign([], res.paymentTerms);
                        this.paymentTermsSubject.next(res.paymentTerms);
                    }
                    this.createFormPayment();
                    this.uploadedFiles = this.toe.additional_files;

                    if (res.tps.length) {
                        this.targetProperties = res.tps
                        this.oldTpCnt = this.targetProperties.length
                        this._getLatestFinalReportDate(this.targetProperties)
                        this.dataSource.data = this.targetProperties
                        this._tps$.next(this.targetProperties)

                        let valuations = []
                        this.targetProperties.forEach(targetProperty => {
                            const tpValuation: ValuationModel[] = []
                            targetProperty.valuations.forEach(item => {
                                const newItem = Object.assign({}, item)
                                newItem.asset_class_name = targetProperty.name
                                tpValuation.push(newItem)
                            })
                            valuations = [...valuations, ...tpValuation]
                        })
                        this.valuationsSubject.next(valuations)
                        this.dataSourceBreakDown = new MatTableDataSource(this.valuationsSubject.value)
                    }
                })
            } else {
            const routeSubscription = combineLatest([
                this.activatedRoute.params,
                this.store.select(currentUser).pipe(filter(user => !!user))
            ]).subscribe(([params, currentUser]) => {
                const toe_id = params.toe_id;

                const routeDataSubscription = combineLatest([
                    this.activatedRoute.data,
                ]).subscribe(([data]) => {

                    // Ass data
                    this.assignment = data.ass.data;
                    this.assignmentId = this.assignment.id;
                    if (this.assignmentId) {
                        const agencySubscription = this.agencyService.getByAssignmentId(this.assignmentId).subscribe(res => {
                            this.agencyInfo = res.data;
                        });
                        this.subscriptions.push(agencySubscription);
                    }
                    const isProjectManager = currentUser.id === this.assignment.valuer_id;
                    this.isPMSubject.next(isProjectManager);
                    this.canValidate = this.canValidate || isProjectManager;

                    if (toe_id && toe_id > 0) {
                        // ToE data
                        this.oldToe = Object.assign({}, data.toe.data);
                        this.oldToeAdditionalFiles = Object.assign([], data.toe.data.additional_files);
                        this.toe = Object.assign({}, this.oldToe);
                        this.fromQuote = this.toe.from_quote ? this.toe.from_quote : null;
                        this.purposeOfValuationID = this.toe.purpose_of_valuation_id;
                        this.selectedPrevKnowledge = this.toe.has_prev_knowledge;
                        this.selectedFinancialInterest = this.toe.has_financial_interest;
                        this.initToe();
                        this.payment = new PaymentModel();
                        this.payment.clear();
                        this.toeStandards = Object.assign([], data.toe.data.standards);
                        this.selectedAgencyDocuments = this.toe.selected_agency_documents;
                        this.selectedTpMultimedias = this.toe.selected_tp_multimedias ? this.toe.selected_tp_multimedias : [];
                        if (this.toe.payment && this.toe.payment.terms_of_engagement_id) {
                            const editData = Object.assign({}, this.toe.payment) as PaymentModel;
                            this.payment = Object.assign({}, editData);
                            this.paymentTaxes = Object.assign([], this.toe.payment_taxes);
                            this.paymentTaxesSubject.next(this.paymentTaxes);
                            this.currencySubject.next(editData.currency);

                            this.paymentTerms = Object.assign([], this.toe.payment_terms_ac);
                            this.paymentTermsSubject.next(this.paymentTerms);
                        }
                        this.createFormPayment();

                        // ToE Workers
                        const _workers: SupportingValuerModel[] = Object.assign([], data.toeWorkers.data);
                        this.oldValuersSubject = JSON.parse(JSON.stringify(_workers.sort((a, b) =>
                            (a.lead_valuer < b.lead_valuer) ? 1 : ((b.lead_valuer < a.lead_valuer) ? -1 : 0))));

                        this.valuersSubject.next(_workers.sort((a, b) =>
                            (a.lead_valuer < b.lead_valuer) ? 1 : ((b.lead_valuer < a.lead_valuer) ? -1 : 0)));
                        this.members$.next(this._convertSupportingToMember(this.valuersSubject.value));
                        const leadValuer = this.valuersSubject.value.find(item => item.lead_valuer === 1);
                        const isLeadValuer = leadValuer && currentUser.id === leadValuer.worker_id;
                        this.isLVSubject.next(isLeadValuer);
                        this.canValidate = this.canValidate || isLeadValuer;

                        if (this.valuerListComponent) {
                            this.valuerListComponent.reload();
                        }
                        this.uploadedFiles = this.toe.additional_files;
                    }
                });
                this.subscriptions.push(routeDataSubscription);


                if (toe_id && toe_id > 0) {
                    this.isEdit = true;

                    // signed report start
                    const signedReportsSubscription = this.toeReportService.getAllToeSignedReports(toe_id).subscribe(res => {
                        if (res) {
                            this.signedReports.next(res.data.sort((a, b) =>
                                (new Date(a.created_at) < new Date(b.created_at)) ? 1 : ((new Date(b.created_at) < new Date(a.created_at)) ? -1 : 0)));
                            this.ref.markForCheck();
                            this.ref.detectChanges();
                        }
                    });
                    this.subscriptions.push(signedReportsSubscription);
                    // signed report end

                    this.toeService.getRdm(toe_id).subscribe(
                        res => {
                            this.selectedRdm = res;
                        });

                    const queryParams = new QueryParamsModel(
                        {},
                        'asc',
                        'id',
                        0,
                        1000
                    );
                    this.store.dispatch(new AssetClassesPageRequested({page: queryParams, toeId: toe_id}));
                    this.store.dispatch(new AllAssetClassSERRequested());
                    this.tpListLoading$ = this.store.pipe(select(selectAssetClassesPageLoading));
                    this.store.pipe(
                        select(selectAssetClassByToeId(toe_id)),
                    ).subscribe((response) => {
                        const tps: AssetClassModel[] = response.map(r => Object.assign({}, r));
                        this.targetProperties = tps;
                        this.oldTpCnt = this.targetProperties.length;
                        this._getLatestFinalReportDate(this.targetProperties);
                        this.dataSource.data = this.targetProperties;
                        this._tps$.next(this.targetProperties)

                        let valuations = [];
                        this.targetProperties.forEach(targetProperty => {
                            const tpValuaion: ValuationModel[] = [];
                            targetProperty.valuations.forEach(item => {
                                const newItem = Object.assign({}, item);
                                // if (newItem.hasOwnProperty('property_type_id') && newItem.hasOwnProperty('property_type_name')) {
                                //     newItem.property_type_id = targetProperty.property_type_id;
                                //     newItem.property_type_name = targetProperty.property_type_name;
                                // }
                                newItem.asset_class_name = targetProperty.name;
                                tpValuaion.push(newItem);
                            })
                            valuations = [...valuations, ...tpValuaion];
                        });
                        this.valuationsSubject.next(valuations);
                        this.dataSourceBreakDown = new MatTableDataSource(this.valuationsSubject.value);
                    });
                } else {
                    this.toe = new ToeModel();
                    this.toe.clear();
                    this.toe.assignment_id = this.assignmentId;
                    this.purposeOfValuationID = 0;
                    this.selectedFinancialInterest = 0;
                    this.selectedPrevKnowledge = 0;
                    this.oldToe = Object.assign({}, this.toe);
                    this.conflictService.reloadDisclosure();
                    this.initToe();
                    this.payment = new PaymentModel();
                    this.payment.clear();
                    this.createFormPayment();
                }
            });
            this.subscriptions.push(routeSubscription);

            }
        }

        this.router.events.forEach(event => {
            if (event instanceof NavigationEnd) {
                this.store.dispatch(new ResetToeConflictsState());
            }
        })


        const signedReportSubscription = this.signedReports.subscribe(params => {
            params.forEach(el => {
                if (el.checked) {
                    this.currentSignedDocId.next(el.id);
                }
            });
            this.dataSourceSignedReport.data = params;
        });
        this.subscriptions.push(signedReportSubscription);

        const generatedReportSubscription = this.generatedReports.subscribe(params => {
            this.dataSourceGeneratedReport.data = params;
        });
        this.subscriptions.push(generatedReportSubscription);


        this.filterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterSelectionModels();
            });

            
        this.valuationsSubject.subscribe(value => {
            if (this.paymentForm && this.paymentForm.controls.pricing_strategy.value != 5) {
                this.valuationFee = value.reduce((prev, cur) => {
                    return prev + Number(cur.total_price);
                }, 0);
            }
        });

        this.paymentTaxesSubject.subscribe(value => {
            this.paymentTaxes = value;
            this.extraCost = value.reduce((prev, cur) => {
                return prev + (Number(cur.amount) * Number(cur.qty));
            }, 0);
        });

        this.paymentTermsSubject.subscribe(terms => {
            this.termsPercent = terms.reduce((prev, cur) => {
                return prev + Number(cur.percent);
            }, 0);
        });

        if (!this.isTemplate && this.assignmentId)  {
            const agencySubscription = this.agencyService.getByAssignmentId(this.assignmentId).subscribe(res => {
                this.agencyInfo = res.data;
            });
            this.subscriptions.push(agencySubscription);
        } else {
            const agengcySubscription = this.agencyService.getAll().subscribe(res => {
                this.agencies = [];
                if (res) {
                    this.agencies = res.data;
                }
            });
            this.subscriptions.push(agengcySubscription);
        }

        if (localStorage.getItem('toe_form_url') && localStorage.getItem('toe_form_url') === 'dashboard') {
            this.backTitle = 'Back to the ToE Dashboard';
            localStorage.removeItem('toe_form_url');
        }

        this.valuationsSubject.subscribe(valuations => {
            const valMap: Map<string, ValuationModel[]> = new Map();
            valuations.forEach(val => {
                const key = "<name>" + val.asset_class_name + "</name><type>" + val.property_type_name + "</type>";
                if (valMap.has(key)) {
                    const vals = valMap.get(key);
                    vals.push(val);
                    valMap.set(key, vals);
                } else {
                    const vals = [];
                    vals.push(val);
                    valMap.set(key, vals);
                }
            });
            this.targetProperties = this.targetProperties.map(tp => {
                const key = "<name>" + tp.name + "</name><type>" + tp.type_name + "</type>"
                const vals = valMap.get(key);
                if (!vals) {
                    return tp;
                }
                tp.valuations = vals;
                return tp;
            });
            this._tps$.next(this.targetProperties)
        });

        this.setDefaultMultimedia();
        this.onChanges();

        this.tpMenus$.subscribe(refId => {
            switch(refId) {
                case 1:
                    this.addTP();
                    return;
                case 2:
                    this.addTpFromTemplate();
                    return;
                default:
                    return;
            }
        })
    }

    onChanges(): void {

        if (this.paymentForm) {
            this.paymentForm.valueChanges.subscribe(val => {
                this.paymentFormChange = true;
            });
        }
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sb => sb.unsubscribe());
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    protected filterSelectionModels() {
        if (!this.clients) {
            return;
        }
        // get the search keyword
        let search = this.filterCtrl.value;
        if (!search || search == '') {
            this.filteredSelectionModels.next(this.clients.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the models
        this.filteredSelectionModels.next(
            this.clients.filter(client => client.name.toLowerCase().indexOf(search) > -1)
        );
    }

    /**
     * Fetch Linked table data
     */
    fetchLT() {
        // fetch client part
        this.store.dispatch(new AllClientsRequested());
        const clientSubscription = this.store.pipe(
            skip(1),
            select(selectAllClients))
            .subscribe(res => {
                this.clients = [];
                if (res) {
                    this.clients = res;
                    this.filteredSelectionModels.next(this.clients.slice());
                }
            });
        this.subscriptions.push(clientSubscription);

        // fetch role part
        this.store.dispatch(new AllUsersOfRoleRequested({roleId: 0}));


        const valuerSubscription = this.store.pipe(
            skip(1),
            select(selectValuersInStore))
            .subscribe(res => {
                this.valuers = [];
                if (res) {
                    this.valuers = res.items;
                }
            });
        this.subscriptions.push(valuerSubscription);

        // fetch PurposeOfValuations part
        this.store.dispatch(new AllPurposeOfValuationsRequested());

        const purposeSubscription = this.store.pipe(
            skip(1),
            select(selectAllPurposeOfValuations))
            .subscribe(res => {
                this.purposeOfValuations = [];
                if (res) {
                    this.purposeOfValuations = res;
                }
            });
        this.subscriptions.push(purposeSubscription);

        // fetch reportDeliveryMethods part
        this.store.dispatch(new AllReportDeliveryMethodsRequested());

        const reportSubscription = this.store.pipe(
            skip(1),
            select(selectAllReportDeliveryMethods))
            .subscribe(res => {
                this.reportDeliveryMethods = [];
                if (res) {
                    this.reportDeliveryMethods = res;
                }
            });
        this.subscriptions.push(reportSubscription);

        const contactSubscription = this.store.pipe(
            skip(1),
            select(selectAllContacts))
            .subscribe(res => {
                this.contacts = [];
                if (res) {
                    this.contacts = res;
                }
            });
        this.subscriptions.push(contactSubscription);


        // fetch conflict
        this.store.dispatch(new AllConflictRequested());


        const showConflictSubscription = this.conflictService
            .conflictData$
            .subscribe(res => {
                this.ciData = [];
                if (res) {
                    this.ciData = res;
                    this.ciTemplateData$.next(this.filterCoiService.getCiData(this.ciData, this.purposeOfValuationID === 8, this.selectedPrevKnowledge === 1, this.selectedFinancialInterest === 1, this.isTemplate));
                }
            });
        this.subscriptions.push(showConflictSubscription);

        const showDisclosureSubscription = this.conflictService
            .disclosureData$
            .subscribe(res => {
                this.dcData = [];
                if (res) {
                    this.dcData = res;
                }
            });
        this.subscriptions.push(showDisclosureSubscription);

        // standart part
        this.store.dispatch(new AllRicsStandardsRequested());
        const ricsSubscription = this.store.pipe(
            skip(1),
            select(selectAllRicsStandards))
            .subscribe(res => {
                this.rics = [];
                if (res) {
                    this.rics = res;
                }
            });
        this.subscriptions.push(ricsSubscription);


        this.store.dispatch(new AllIvsStandardsRequested());
        const ivsSubscription = this.store.pipe(
            skip(1),
            select(selectAllIvsStandards))
            .subscribe(res => {
                this.ivs = [];
                if (res) {
                    this.ivs = res;
                }
            });
        this.subscriptions.push(ivsSubscription);

        this.store.dispatch(new AllUnitMeasurementsRequested());
        const unitsSubscription = this.store.pipe(
            skip(1),
            select(selectAllUnitMeasurements))
            .subscribe(res => {
                this.units = [];
                if (res) {
                    this.units = res;
                }
            });
        this.subscriptions.push(unitsSubscription);

        this.store.dispatch(new AllValuationStandardRequested());
        const valuationStandardSubscription = this.store.pipe(
            skip(1),
            select(selectAllValuationStandard))
            .subscribe(res => {
                this.valuationStandards = [];
                if (res) {
                    this.valuationStandards = res;
                }
            });
        this.subscriptions.push(valuationStandardSubscription);

        this.store.dispatch(new AllCountriesRequested());
        this.store.dispatch(new AllCitiesRequested());
        const countrySubscription = this.store.pipe(select(selectAllCountries)).subscribe((res: CountryModel[]) => {
            this.allCountries = [];
            each(res, (_country: CountryModel) => {
                this.allCountries.push(_country);
            });
        });
        this.subscriptions.push(countrySubscription);

    }

    /**
     * Init toe
     */
    initToe() {
        this.createForm();
        this.hasMultimediaSupport = this.toe.has_multimedia_support

        const conflictSubscription = this.store.pipe(
            skip(1),
            select(selectAllConflicts))
            .subscribe(res => {
                if (res) {
                    const result = [];
                    this.conflictService.setConflictData(res);
                    let _index = 0;
                    res.forEach(item => {
                        const tmp = new ConflictModel();
                        tmp.clear();
                        tmp.index = this.toe[item.name] || 0;
                        tmp.optionType = item.option_type;
                        tmp.text = this.toe[item.name + '_description'];
                        tmp.text2 = this.toe[item.name + '_description_b'];
                        tmp.type_of_conflict_id = this.toe[item.name + '_type_of_conflict_id'];
                        tmp.appropriate_parties = this.toe[item.name + '_appropriate_parties'];
                        tmp.reason_continue = this.toe[item.name + '_reason_continue'];

                        tmp.index_a = this.toe[item.name + '_coi'] || 0;
                        tmp.optionType_a = item.option_type_a;

                        tmp.prefix = item.name;
                        if (item.name == 'employees_knows_each_other') {
                            _index = tmp.index;
                        }
                        tmp.optionPrefix = ConflictType.conflictTypes[item.option_type - 1].options[tmp.index].prefix;
                        tmp.optionPrefix_a = ConflictType.conflictTypes[item.option_type_a - 1].options[tmp.index_a].prefix;

                        tmp.coiDetailsTP = this.toe.properties_coi.filter(property => property.conflict_details_name === item.name);
                        result.push(tmp);
                    });
                    this.conflictService.setConflictResult(result);
                }
            });

        this.subscriptions.push(conflictSubscription);

        // valuer_involved_in_purchase_files
        this.conflictService.getAllDisclosure().subscribe(
            res => {
                this.conflictService.setDisclosureData(res.data);
                const result = [];
                let _index = 0;
                res.data.forEach(item => {
                    const tmp = new ConflictModel();
                    tmp.clear();
                    tmp.index = this.toe[item.name] || 0;
                    tmp.optionType = item.option_type;
                    tmp.text = this.toe[item.name + '_description'];

                    if (item.name == 'valuation_relied_on_other_parties') {
                        _index = this.toe.valuation_relied_on_other_parties;
                    }

                    tmp.prefix = item.name;
                    tmp.optionPrefix = ConflictType.conflictTypes[item.option_type - 1].options[tmp.index].prefix;
                    result.push(tmp);
                });
                this.conflictService.setDisclosureResult(result);
                this.conflictService.changedPurposeValuation(this.toe.purpose_of_valuation_id);
            });


        if (this.toe.id) {
            this.subheaderService.setBreadcrumbs([
                {title: 'Main menu', page: `../assignment`},
                {title: 'Assignments', page: `../assignment/assignments`},
                {title: 'Terms of Engagements', page: `../assignment/assignments/todo`},
                {title: 'Edit Terms of Engagements', page: `../assignment/assignments/todo/edit/${this.toe.id}`},
            ]);


            if (this.toe.status > 0) {
                this.selectedTab = 0;
                this.onTabChanged({index: 0});
            }
        } else {
            this.subheaderService.setBreadcrumbs([
                {title: 'Main menu', page: `../assignment`},
                {title: 'Assignments', page: `../assignment/assignments`},
                {title: 'Terms of Engagements', page: `../assignment/assignments/todo`},
                {title: 'Add Terms of Engagements', page: `../assignment/assignments/todo/add`},
            ]);
        }
    }

    /**
     * Create form
     */
    createForm() {

        this.toeForm1 = this.toeFB.group({
            template_name: [this._templateName, Validators.required],
            template_description: [this._templateDescr, this.isTemplate ? Validators.required : null],
            template_agency: [this._templateAgencyID, Validators.required],
            name: [this.toe.name, Validators.required],
            status_of_valuer: [this.toe.status_of_valuer, Validators.required],
            purpose_of_valuation_id: [this.toe.purpose_of_valuation_id],
            purpose_of_valuation_reason: [this.toe.purpose_of_valuation_reason],
            restrictions_on_use: [this.toe.restrictions_on_use],
            restrictions_on_use_intended_users: [this.toe.restrictions_on_use_intended_users, this.toe.restrictions_on_use === 0 ? null : Validators.required],
            restrictions_on_use_descr: [this.toe.restrictions_on_use_descr],
            responsibilities_liabilities: [this.toe.responsibilities_liabilities],
            responsibilities_liabilities_descr: [this.toe.responsibilities_liabilities_descr],
            limitations_on_liability: [this.toe.limitations_on_liability],
            limitations_on_liability_descr: [this.toe.limitations_on_liability_descr],
            generic_one: [this.toe.generic_one],
            generic_one_descr: [this.toe.generic_one_descr],
            client_id: [this.toe.client_id],
            contact_id: [this.toe.contact_id],

            valuer_id: [this.toe.valuer_id],
            valuer_previous_involvment: [this.toe.valuer_previous_involvment],

            unit_of_measurement_id: [this.toe.unit_of_measurement_id 
                ? this.toe.unit_of_measurement_id 
                : this.currentUser
                    ? this.currentUser.settings.unit_of_measurement_id
                    : null],
            law_country_id: [this.toe.law_country_id],
            core_standard_id: [this.toe.core_standard_id, Validators.required]
        });

        let _leadValuerId = 0;
        this.valuersSubject.value.forEach((item, index) => {
            if (item.lead_valuer == 1) {
                _leadValuerId = item.worker.id;
            }
        });

        this.instructionDate = this.typesUtilsService.getDateFromString(this.toe.instruction_date);
        this.draftDate = this.typesUtilsService.getDateFromString(this.toe.draft_delivery_date);
        this.finalDate = this.typesUtilsService.getDateFromString(this.toe.final_delivery_date);
        this.toeForm2 = this.toeFB.group({
            support_clarifications: [this.toe.support_clarifications],

            instruction_date: [{value: this.instructionDate, disabled: false}],
            draft_delivery_date: [{value: this.draftDate, disabled: false}],
            final_delivery_date: [{value: this.finalDate, disabled: false}],
            payment_terms: [this.toe.payment_terms],
        });

        if (this.toe.client_id > 0) {
            this.selectClient(null);
        }

        this.conflictService.changedValueOfDisclosure('valuation_relied_on_other_parties', this.toe.restrictions_on_use);
        this.conflictService.changedPurposeValuation(this.toe.purpose_of_valuation_id);
        this.conflictService.fileCntSubject.next(this.toe.valuer_involved_in_purchase_files.length);

        this.toeForm1.controls.law_country_id.valueChanges.pipe(
            startWith(this.toeForm1.controls.law_country_id.value),
            takeUntil(this._onDestroy),
        ).subscribe(country_id => {
            const country = this.allCountries.find(c => c.id === country_id)
            if (!country || !country.default_timezone) {
                this.gowerningLawTimezone = null;
                return;
            }
            this.gowerningLawTimezone = this.timezoneService.getGmt(country.default_timezone);
        })
        this.toeForm1.controls.purpose_of_valuation_id.valueChanges.subscribe(purposeOfValuationId => {
            this.purposeOfValuationID = purposeOfValuationId;
            if (this.purposeOfValuationID == 8) {
                this.ciTemplateData$.next(this.filterCoiService.getCiData(this.ciData, true, this.selectedPrevKnowledge === 1, this.selectedFinancialInterest === 1, this.isTemplate));
            } else {
                this.ciTemplateData$.next(this.filterCoiService.getCiData(this.ciData, false, this.selectedPrevKnowledge === 1, this.selectedFinancialInterest === 1, this.isTemplate));
            }
            if (purposeOfValuationId == CONSTANTS.pov_none) {
                this.toeForm1.controls.restrictions_on_use.setValue(0);
                this.toeForm1.controls.purpose_of_valuation_reason.setValidators([Validators.required]);
            } else {
                this.toeForm1.controls.purpose_of_valuation_reason.clearValidators();
                this.toeForm1.controls.purpose_of_valuation_reason.setValue('');
            }
            this.toeForm1.controls.purpose_of_valuation_reason.updateValueAndValidity();
            if (this.isTemplate) {
                this.toeForm1.controls.purpose_of_valuation_reason.clearValidators();
                this.toeForm1.controls.purpose_of_valuation_reason.updateValueAndValidity();
            }
        });

        this.toeForm1.controls.restrictions_on_use.valueChanges.pipe(startWith(this.toeForm1.controls.restrictions_on_use.value)).subscribe((value: number) => {
            if (value === 0) {
                this.toeForm1.controls.responsibilities_liabilities.setValue(value);
                this.toeForm1.controls.restrictions_on_use_intended_users.setValidators(null);
                this.toeForm1.controls.restrictions_on_use_intended_users.updateValueAndValidity();
            }
            if (value === 1) {
                this.toeForm1.controls.restrictions_on_use_intended_users.setValidators([
                    Validators.required
                ]);
                this.toeForm1.controls.restrictions_on_use_intended_users.updateValueAndValidity();
            }
            if (this.isTemplate) {
                this.toeForm1.controls.restrictions_on_use_intended_users.clearValidators();
                this.toeForm1.controls.restrictions_on_use_intended_users.updateValueAndValidity();
            }
            this.conflictService.changedValueOfDisclosure('valuation_relied_on_other_parties', value);
        });
        this.toeForm1.controls.responsibilities_liabilities.valueChanges.subscribe(value => {
            if (value === 1) {
                this.toeForm1.controls.responsibilities_liabilities_descr.setValidators([Validators.required]);
            } else {
                if (this.toe && this.toe.id) {
                    this.toeForm1.controls.responsibilities_liabilities_descr.setValue(this.toe.responsibilities_liabilities_descr);
                } else {
                    this.toeForm1.controls.responsibilities_liabilities_descr.setValue('');
                }
                this.toeForm1.controls.responsibilities_liabilities_descr.clearValidators();
            }
            this.toeForm1.controls.responsibilities_liabilities_descr.updateValueAndValidity();
            if (this.isTemplate) {
                this.toeForm1.controls.responsibilities_liabilities_descr.clearValidators();
                this.toeForm1.controls.responsibilities_liabilities_descr.updateValueAndValidity();
            }
        });

        this.toeForm1.controls.limitations_on_liability.valueChanges.subscribe(value => {
            if (value === 1) {
                this.toeForm1.controls.limitations_on_liability_descr.setValidators([Validators.required]);
            } else {
                if (this.toe && this.toe.id) {
                    this.toeForm1.controls.limitations_on_liability_descr.setValue(this.toe.limitations_on_liability_descr);
                } else {
                    this.toeForm1.controls.limitations_on_liability_descr.setValue('');
                }
                this.toeForm1.controls.limitations_on_liability_descr.clearValidators();
            }
            this.toeForm1.controls.limitations_on_liability_descr.updateValueAndValidity();
            if (this.isTemplate) {
                this.toeForm1.controls.limitations_on_liability_descr.clearValidators();
                this.toeForm1.controls.limitations_on_liability_descr.updateValueAndValidity();
            }
        });

        this.toeForm1.controls.generic_one.valueChanges.subscribe(value => {
            if (value === 1) {
                this.toeForm1.controls.generic_one_descr.setValidators([Validators.required]);
            } else {
                if (this.toe && this.toe.id) {
                    this.toeForm1.controls.generic_one_descr.setValue(this.toe.generic_one_descr);
                } else {
                    this.toeForm1.controls.generic_one_descr.setValue('');
                }
                this.toeForm1.controls.generic_one_descr.clearValidators();
            }
            this.toeForm1.controls.generic_one_descr.updateValueAndValidity();
            if (this.isTemplate) {
                this.toeForm1.controls.generic_one_descr.clearValidators();
                this.toeForm1.controls.generic_one_descr.updateValueAndValidity();
            }
        });

        this.toeForm1.controls.template_agency.valueChanges.subscribe(value => {
            if (value === -1) {
                this.agencyInfo = null;
            } else {
                this.agencyInfo = this.agencies.find(a => a.id == value);
            }
        })

        this._loadToeConflicts()


        if (!this.isTemplate) {
            const controls = this.toeForm1.controls;
            controls.template_name.clearValidators();
            controls.template_name.updateValueAndValidity();
            controls.template_agency.clearValidators();
            controls.template_agency.updateValueAndValidity();
            controls.template_description.clearValidators();
            controls.template_description.updateValueAndValidity();
        }
    }

    private _loadToeConflicts() {
        this.toeForm1.controls.client_id.valueChanges.pipe(
            startWith(this.toeForm1.controls.client_id.value),
            takeUntil(this._onDestroy)
        ).subscribe(client_id => {
            if (client_id == null) {
                return
            }

            if (!this.assignment) {
                return
            }

            this.store.dispatch(new LoadConflictOfAgencyHasPreviousInvolvementWithTheClient({client_id, agency_id: this.assignment.agency_id, toe_id: this.toe ? this.toe.id : undefined}))
        })

        combineLatest([
            this.toeForm1.controls.client_id.valueChanges.pipe(startWith(this.toeForm1.controls.client_id.value)),
            this.members$
        ]).pipe(takeUntil(this._onDestroy)).subscribe(([client_id, members]) => {
            if (client_id == null ) {
                return;
            }
            const valuer = members.find(member => member.lead_valuer == 1)
            if (!valuer) {
                return;
            }
            this.store.dispatch(new LoadConflictOfLeadValuerHasPreviousInvolvementWithTheClient({
                client_id, 
                lead_valuer_id: valuer.worker.id, 
                toe_id: this.toe ? this.toe.id : undefined
            }))
        })

        this._tps$.pipe(takeUntil(this._onDestroy)).subscribe(tps => {
            const query: ToeConflictTpQuery[] = tps
                .filter(tp => {
                    if (tp.name == null || tp.name == undefined || typeof tp.name != 'string' || tp.name.trim().length == 0) {
                        return false
                    }
                    if (tp.top_property_type_id == null || tp.top_property_type_id == undefined) {
                        return false
                    }
                    if (tp.type_id == null || tp.type_id == undefined) {
                        return false
                    }
                    if (tp.surface == null || tp.surface == undefined) {
                        return false
                    }
                    if (tp.unit_of_area_measurement_id == null || tp.unit_of_area_measurement_id == undefined) {
                        return false
                    }
                    return true
                })
                .map(tp => {
                    return {
                        id: tp.id,
                        name: tp.name,
                        propertyType: tp.top_property_type_id,
                        propertySubType: tp.type_id,
                        size: tp.surface,
                        measurement_id: tp.unit_of_area_measurement_id,
                        location: {
                            lat: tp.latitude,
                            lng: tp.longitude
                        }
                    }
                })

            if (query.length == 0) {
                return
            }

            if (!this.assignment) {
                return
            }

            this.store.dispatch(new LoadConflictOfAgencyAlreadyPerformedValuationOnTheTP({agency_id: this.assignment.agency_id, tp_queries: query, toe_id: this.toe ? this.toe.id : undefined}))
        })
        this._tps$.pipe(takeUntil(this._onDestroy)).subscribe(tps => {
            const unknownSizedTps = tps.filter(tp => tp.surface == 0)
            this.disableSizebasedPricing = unknownSizedTps.length > 0
        })

        combineLatest([
            this.members$,
            this._tps$
        ]).pipe(takeUntil(this._onDestroy)).subscribe(([members, tps]) => {
            const valuer = members.find(member => member.lead_valuer == 1)
            if (!valuer) {
                return;
            }

            const query: ToeConflictTpQuery[] = tps
                .filter(tp => {
                    if (tp.name == null || tp.name == undefined || typeof tp.name != 'string' || tp.name.trim().length == 0) {
                        return false
                    }
                    if (tp.top_property_type_id == null || tp.top_property_type_id == undefined) {
                        return false
                    }
                    if (tp.type_id == null || tp.type_id == undefined) {
                        return false
                    }
                    if (tp.surface == null || tp.surface == undefined) {
                        return false
                    }
                    if (tp.unit_of_area_measurement_id == null || tp.unit_of_area_measurement_id == undefined) {
                        return false
                    }
                    return true
                })
                .map(tp => {
                    return {
                        id: tp.id,
                        name: tp.name,
                        propertyType: tp.top_property_type_id,
                        propertySubType: tp.type_id,
                        size: tp.surface,
                        measurement_id: tp.unit_of_area_measurement_id,
                        location: {
                            lat: tp.latitude,
                            lng: tp.longitude
                        }
                    }
                })

            if (query.length == 0) {
                return
            }

            this.store.dispatch(new LoadConflictOfLeadValuerAlreadyPerformedValuationOnTheTP({
                lead_valuer_id: valuer.worker_id,
                toe_id: this.toe ? this.toe.id : undefined,
                tp_queries: query
            }))
        })
    }

    /**
     * Refresh toe
     *
     * @param isNew: boolean
     * @param id: number
     */
    refreshToe(isNew: boolean = false, id = 0) {
        let url = this.router.url;
        if (!isNew) {
            this.router.navigate([url], {relativeTo: this.activatedRoute});
            return;
        }

        url = `${this.layoutConfigService.getCurrentMainRoute()}/toe-management/toes/edit/${id}`;
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    /**
     * Reset
     */
    reset() {
        // this.toe = Object.assign({}, this.oldToe);
        // this.createForm();
        // this.hasFormErrors = false;
        // this.toeForm1.markAsPristine();
        // this.toeForm1.markAsUntouched();
        // this.toeForm1.updateValueAndValidity();
    }


    changeDraftDate() {
        this.draftDate = new Date(this.toeForm2.controls.draft_delivery_date.value);

        if (this.draftDate.getTime() > this.finalDate.getTime()) {
            this.finalDate = this.draftDate;
            this.toeForm2.controls.final_delivery_date.patchValue(this.finalDate);
        }
    }

    /**
     * Save data
     *
     * @param isComplete: boolean
     */
    onSubmit(isComplete: boolean = false) {
        if (this.isTemplate) {
            const controls = this.toeForm1.controls;
            if (this.toeForm1.invalid) {
                Object.keys(controls).forEach(cName => {
                    if (controls[cName].invalid) {
                        controls[cName].markAsTouched();
                    }
                })
                return;
            }
            this.cdComponents.forEach((child) => {
                child.sendDataToService();
            });
            this.ddComponents.forEach((child) => {
                child.sendDataToService();
            });
            const newTemplate = this.prepareToeTemplate();
            if (isComplete) {
                if (newTemplate.id === undefined) {
                    this.store.dispatch(new AddToeTemplate({
                        template: newTemplate
                    }));
                } else {
                    this.store.dispatch(new UpdateToeTemplate({
                        id: newTemplate.id,
                        template: newTemplate
                    }))
                }
                this.back();
            } else {
            }
        } else {
            this.isComplete = isComplete;
            this.saveState = true;
            this.hasFormErrors = false;
            const controls = this.toeForm1.controls;
            this.changeValidator(isComplete);

            /** check form 1 */
            if (isComplete && !this.toeForm1.valid) {
                Object.keys(controls).forEach(controlName =>
                    controls[controlName].markAsTouched()
                );

                this.hasFormErrors = true;
                this.errorMessage = this.translate.instant('TOE.FILL_REQUIRED_FIELD');
                this.selectedTab = 0;
                this.selectedtabchange.next(this.selectedTab);
                return;
            }


            if (isComplete) {
                this.targetProperties.forEach(targetProperty => {
                    if (targetProperty.is_complete === 0) {
                        this.hasFormErrors = true;
                        this.errorMessage = this.translate.instant('TOE.TP_NOT_VALIDATED');
                        this.selectedTab = 1;
                        this.selectedtabchange.next(this.selectedTab);
                    }
                });
                if (this.hasFormErrors) {
                    return;
                }
            }

            /** check valuer */

            if (isComplete && !this.checkLeadValuer()) {
                this.hasFormErrors = true;
                this.errorMessage = this.translate.instant('TOE.LEAD_VALUER_FIELD');
                this.selectedTab = 0;
                this.selectedtabchange.next(this.selectedTab);
                return;
            }

            /** check tp */

            if (isComplete && this.targetProperties.length < 1) {
                this.hasFormErrors = true;
                this.errorMessage = this.translate.instant('TOE.ADD_AT_LEAST_ONE_PROPERY_FIELD');
                this.selectedTab = 1;
                this.selectedtabchange.next(this.selectedTab);
                return;
            }

            /** check payment terms */

            if (isComplete && this.termsPercent != 100) {
                this.hasFormErrors = true;
                this.errorMessage = this.translate.instant('TOE.TERMS_PERCENT_100');
                this.selectedTab = 6;
                this.selectedtabchange.next(this.selectedTab);
                return;
            }

            /** check LVs Daily rate on pricing strategy == 4 */

            if (isComplete && this.paymentForm && this.paymentForm.controls.pricing_strategy.value == 4 && this.checkCompanyDailyRate()) {
                this.hasFormErrors = true;
                // this.errorMessage = this.translate.instant('TOE.LEAD_VALUER_FIELD');
                this.errorMessage = "Valuation breakdown values cannot be empty or zero";
                this.selectedTab = 6;
                this.selectedtabchange.next(this.selectedTab);
                return;
            }

            /** check LVs Daily rate on pricing strategy == 5 */

            if (isComplete && this.paymentForm && this.paymentForm.controls.pricing_strategy.value == 5 && !this.checkLeadValuerDailyRate()) {
                this.hasFormErrors = true;
                // this.errorMessage = this.translate.instant('TOE.LEAD_VALUER_FIELD');
                this.errorMessage = "Lead valuers Daily Rates, Days must be filled";
                this.selectedTab = 6;
                this.selectedtabchange.next(this.selectedTab);
                return;
            }

            /** check conflicts */
            this.cdComponents.forEach((child) => {
                child.sendDataToService();
            });

            if (isComplete && !this.conflictService.changeValidConflicts()) {
                this.hasFormErrors = true;
                this.errorMessage = this.translate.instant('TOE.FILL_EXPLAIN_FIELD');
                if (this.selectedTab == 2) {
                    this.cdComponents.forEach((child) => {
                        child.valid();
                    });
                    return;
                }

                this.selectedTab = 2;
                this.selectedtabchange.next(this.selectedTab);
                setTimeout(() => {
                    this.cdComponents.forEach((child) => {
                        child.valid();
                    });
                }, 2000);
                return;
            }


            this.ddComponents.forEach((child) => {
                child.sendDataToService();
            });

            if (isComplete && !this.conflictService.changeValidOfDisclosures()) {

                this.hasFormErrors = true;
                this.errorMessage = this.translate.instant('TOE.FILL_EXPLAIN_FIELD');
                if (this.selectedTab == 3) {
                    this.ddComponents.forEach((child) => {
                        child.valid();
                    });
                    return;
                }
                this.selectedTab = 3;
                this.selectedtabchange.next(this.selectedTab);

                setTimeout(() => {
                    this.ddComponents.forEach((child) => {
                        child.valid();
                    });
                }, 2000);
                return;
            }


            const control1 = this.toeForm2.controls;
            /** check form */
            if (!this.toeForm2.valid) {
                Object.keys(control1).forEach(controlName =>
                    control1[controlName].markAsTouched()
                );

                this.hasFormErrors = true;
                this.errorMessage = this.translate.instant('TOE.FILL_REQUIRED_FIELD');
                this.selectedTab = 4;
                this.selectedtabchange.next(this.selectedTab);
                return;
            }


            if (isComplete && this.selectedRdm.length == 0) {

                this.errorMessage = this.translate.instant('TOE.CHOOSE_REQUIRED_FIELD');
                this.hasFormErrors = true;
                this.selectedTab = 4;
                this.selectedtabchange.next(this.selectedTab);
                return;
            }

            // payment tab
            const paymentControls = this.paymentForm.controls;
            this.changePaymentValidator(isComplete);
            /** check form */
            if (isComplete && !this.paymentForm.valid) {
                Object.keys(paymentControls).forEach(controlName =>
                    paymentControls[controlName].markAsTouched()
                );

                this.hasFormErrors = true;
                this.errorMessage = this.translate.instant('TOE.FILL_REQUIRED_FIELD');
                this.selectedTab = 5;
                this.selectedtabchange.next(this.selectedTab);

                return;
            }

            if (isComplete && this.acPaymentTermsAltComponent && this.acPaymentTermsAltComponent.termsPercent != 100) {
                this.hasFormErrors = true;
                // this.errorMessage = this.translate.instant('TOE.FILL_REQUIRED_FIELD');
                this.errorMessage = 'total payment terms need to be 100%';
                this.selectedTab = 5;
                this.selectedtabchange.next(this.selectedTab);

                return;
            }

            if (isComplete && this.acPaymentTermsAltComponent && !this.acPaymentTermsAltComponent.paymentDateValid) {
                this.hasFormErrors = true;
                // this.errorMessage = this.translate.instant('TOE.FILL_REQUIRED_FIELD');
                this.errorMessage = 'Payment Dates are not valid';
                this.selectedTab = 5;
                this.selectedtabchange.next(this.selectedTab);

                return;
            }

            if (isComplete && this.postValuationDeliveryServiceComponent && this.postValuationDeliveryServiceComponent.validate()) {
                this.hasFormErrors = true;
                this.errorMessage = 'Post valuation delivery service details must be filled';
                this.selectedTab = 5;
                this.selectedtabchange.next(this.selectedTab);

                return;
            }

            if (isComplete && this.conflictService.valuerDoubtsAbilityValue()) {

                const _title = this.translate.instant('TOE.PROMPT');
                const _description = this.translate.instant('TOE.VALIDATOR_DOUBT_DESCRIPTION');
                const _waitDesciption = this.translate.instant('TOE.PROMPT_WAIT_SAVE');

                const dialogRef = this.layoutUtilsService.saveElement(_title, _description, _waitDesciption);
                dialogRef.afterClosed().subscribe(res => {
                    if (!res) {
                        return;
                    }

                    const _editedToe = this.prepareToe(isComplete);
                    const _payment = this.preparePayment(isComplete);
                    if (_editedToe.id > 0) {
                        this.updateToe(_editedToe, _payment);
                        this.router.navigate(['../../toes/' + this.toe.id + '/dashboard'], {relativeTo: this.activatedRoute});
                        return
                    }

                    this.addToe(_editedToe, _payment)
                    if (this._fromTemplate) {
                        this.router.navigate(['../../toes'], {relativeTo: this.activatedRoute});
                    } else if (this._isFromQuote) {
                        this.router.navigate(['../../toes'], {relativeTo: this.activatedRoute});
                    } else {
                        this.router.navigate(['../toes'], {relativeTo: this.activatedRoute});
                    }
                });

                return;
            }

            const payment = this.preparePayment(isComplete);
            const editedToe = this.prepareToe(isComplete);

            if (this.postValuationDeliveryServiceComponent) {
                editedToe.postValuationDeliveryServicetData = this.postValuationDeliveryServiceComponent.getData();
            } 

            if (isComplete) {
                const dialogRef = this.dialog.open<SaveToeDialogComponent, SaveToeData, boolean>(SaveToeDialogComponent, {
                    data: {
                        toe: editedToe,
                        payment: payment,
                        workers: this.valuersSubject.value,
                        rdms: this.selectedRdm,
                        tpList: this.targetProperties,
                        coiProperties: this.coiProperties,
                        paymentTaxes: this.paymentTaxesSubject.value,
                        valuations: this.valuationsSubject.value,
                        paymentTerms: this.paymentTermsSubject.value,
                        standards: this.toeStandards,
                        fileList: this.uploadedFiles
                    },
                    width: '440px',
                    disableClose: true
                });
                const dialogSub = dialogRef.afterClosed().subscribe(res => {
                    if (!res) {
                        return;
                    }
                    if (editedToe.id > 0) {
                        this.oldToe = editedToe;
                        this.toe = Object.assign({}, this.oldToe);
                        this.ref.markForCheck();
                        this.ref.detectChanges();
                        this.tpChanged = false;
                        this.router.navigate(['../../toes/' + this.toe.id + '/dashboard'], {relativeTo: this.activatedRoute});
                    } else {
                        const addSubscription = this.store.pipe(select(selectLastCreatedToeId)).subscribe(newID => {
                            this.oldToe = editedToe;
                            this.toe = Object.assign({}, this.oldToe);
                            this.toe.id = newID;
                            this.ref.markForCheck();
                            this.ref.detectChanges();
                            this.tpChanged = false;
                            if (this._fromTemplate) {
                                this.router.navigate(['../../toes/' + this.toe.id + '/dashboard'], {relativeTo: this.activatedRoute});
                            } else if (this._isFromQuote) {
                                this.router.navigate(['../../toes/' + this.toe.id + '/dashboard'], {relativeTo: this.activatedRoute});
                            } else {
                                this.router.navigate(['../toes/' + this.toe.id + '/dashboard'], {relativeTo: this.activatedRoute});
                            }
                        });
                        this.subscriptions.push(addSubscription);
                    }
                });
                this.subscriptions.push(dialogSub);
                return;
            } else {
                if (editedToe.id > 0) {
                    this.updateToe(editedToe, payment);
                    this.router.navigate(['../../toes'], {relativeTo: this.activatedRoute});
                    return;
                }

                this.addToe(editedToe, payment);
                if (this._fromTemplate) {
                    this.router.navigate(['../../toes'], {relativeTo: this.activatedRoute});
                } else if (this._isFromQuote) {
                    this.router.navigate(['../../toes/'], {relativeTo: this.activatedRoute});
                } else {
                    this.router.navigate(['../toes'], {relativeTo: this.activatedRoute});
                }
            }
        }
    }

    changeRdm(event, r) {
        if (event.checked) {
            this.selectedRdm.push(r.id);
        } else {
            this.selectedRdm = this.selectedRdm.filter((ele) => {
                return ele != r.id;
            });
        }
    }

    changeAgencyDocument(event, r) {
        let tempSelectedAgencyDocuments = Object.assign([], this.selectedAgencyDocuments);
        if (event.checked) {
            tempSelectedAgencyDocuments.push(r.id);
            this.selectedAgencyDocuments = tempSelectedAgencyDocuments;
        } else {
            this.selectedAgencyDocuments = tempSelectedAgencyDocuments.filter((ele) => {
                return ele != r.id;
            });
        }
    }

    setDefaultMultimedia() {
        if (this.selectedTpMultimedias.length == 0 && this.targetProperties.length > 0) {
            let tempSelectedTpMultimedia = Object.assign([], this.selectedTpMultimedias);
            let tp = this.targetProperties[0];
            const _media = new ToeTpMultimediaModel();
            _media.clear();
            _media.toe_id = this.toe.id;
            _media.tp_id = tp.id ? tp.id : -1;
            _media.tp_index = 0;
            _media.tp_name = tp.name;
            tempSelectedTpMultimedia.push(_media);
            this.selectedTpMultimedias = tempSelectedTpMultimedia;
        }
    }

    changeDocument(event, r, valuerIndex) {
        const valuers = Object.assign([], this.valuersSubject.value);
        const valuer = Object.assign({}, valuers[valuerIndex]);
        const selected_documents = Object.assign([], valuer.selected_document);
        if (event.checked) {
            selected_documents.push(r.id);
        } else {
            const index = selected_documents.indexOf(r.id);
            selected_documents.splice(index, 1);
        }
        valuer.selected_document = selected_documents;
        valuers[valuerIndex] = valuer;
        this.valuersSubject.next(valuers);
    }

    changeCertificate(event, r, valuerIndex) {
        const valuers = Object.assign([], this.valuersSubject.value);
        const valuer = Object.assign({}, valuers[valuerIndex]);
        const selected_certificate = Object.assign([], valuer.selected_certificate);
        if (event.checked) {
            selected_certificate.push(r.id);
        } else {
            const index = selected_certificate.indexOf(r.id);
            selected_certificate.splice(index, 1);
        }
        valuer.selected_certificate = selected_certificate;
        valuers[valuerIndex] = valuer;
        this.valuersSubject.next(valuers);
    }

    selectClient(e) {
        if (e) {
            this.toeForm1.controls.contact_id.patchValue('');
            this.store.dispatch(new AllContactsRequested({clientId: e.value}));
            return;
        }
        this.store.dispatch(new AllContactsRequested({clientId: this.toe.client_id}));
    }

    selectPurpose(e) {
        if (e) {
            this.conflictService.changedPurposeValuation(e.value);
        }
    }

    saveDraft() {
        this.changeValidator(false);
        if (!this.toeForm1.valid) {
            this.toeForm1.controls.name.markAsTouched();

            this.hasFormErrors = true;
            this.errorMessage = this.translate.instant('TOE.FILL_REQUIRED_FIELD');
            this.selectedTab = 0;
            return;
        } else {
            const _title = this.translate.instant('TOE.PROMPT');
            const _description = this.translate.instant('TOE.SAVE_DRAFT_DESCRIPTION');
            const _waitDesciption = this.translate.instant('TOE.PROMPT_WAIT_SAVE');

            const dialogRef = this.layoutUtilsService.saveElement(_title, _description, _waitDesciption);
            dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    return;
                }
                this.onSubmit(false);
            });
        }
    }

    back() {
        // if (this.isTemplate) {
        //     this.location.back();
        //     // if (this.isEdit) {
        //     //     this.router.navigate(['../../../../list'], {relativeTo: this.activatedRoute});
        //     //     return;
        //     // }
        //     // this.router.navigate(['../../../list'], {relativeTo: this.activatedRoute});
        // } else {
        //     if (this.isEdit) {
        //         this.router.navigate(['../../toes'], {relativeTo: this.activatedRoute});
        //         return
        //     }
        //     this.router.navigate(['../toes'], {relativeTo: this.activatedRoute});
        // }
        this.location.back();
    }

    /**
     * Returns prepared data for save
     */
    prepareToe(isComplete: boolean = false): ToeModel {
        // get data from first tab
        const controls = this.toeForm1.controls;
        const _toe = new ToeModel();
        _toe.clear();
        _toe.from_quote = this.fromQuote
        _toe.is_complete = Number(isComplete);
        _toe.status = Number(isComplete);
        _toe.status_of_valuer = controls.status_of_valuer.value;
        _toe.id = this.toe.id;
        _toe.purpose_of_valuation_id = controls.purpose_of_valuation_id.value;
        _toe.purpose_of_valuation_reason = controls.purpose_of_valuation_reason.value;

        _toe.restrictions_on_use = controls.restrictions_on_use.value;
        _toe.restrictions_on_use_intended_users = controls.restrictions_on_use_intended_users.value;
        _toe.restrictions_on_use_descr = controls.restrictions_on_use_descr.value;
        _toe.responsibilities_liabilities = controls.responsibilities_liabilities.value;
        _toe.responsibilities_liabilities_descr = controls.responsibilities_liabilities_descr.value;
        _toe.limitations_on_liability = controls.limitations_on_liability.value;
        _toe.limitations_on_liability_descr = controls.limitations_on_liability_descr.value;
        _toe.generic_one = controls.generic_one.value;
        _toe.generic_one_descr = controls.generic_one_descr.value;
        _toe.has_multimedia_support = this.hasMultimediaSupport
        _toe.timezone = this.gowerningLawTimezone
        if (_toe.restrictions_on_use == 0) {
            _toe.responsibilities_liabilities = 0;
        }
        if (_toe.responsibilities_liabilities == 0) {
            _toe.responsibilities_liabilities_descr = '';
        }
        if (_toe.limitations_on_liability == 0) {
            _toe.limitations_on_liability_descr = '';
        }
        if (_toe.generic_one == 0) {
            _toe.generic_one_descr = '';
        }

        _toe.assignment_id = this.assignmentId;
        _toe.name = controls.name.value;
        _toe.client_id = controls.client_id.value;
        _toe.contact_id = controls.contact_id.value;
        _toe.valuer_id = controls.valuer_id.value;
        _toe.valuer_previous_involvment = controls.valuer_previous_involvment.value;

        _toe.unit_of_measurement_id = controls.unit_of_measurement_id.value;
        _toe.law_country_id = controls.law_country_id.value;

        _toe.core_standard_id = controls.core_standard_id.value;

        _toe.has_prev_knowledge = this.selectedPrevKnowledge;
        _toe.has_financial_interest = this.selectedFinancialInterest;

        // get data from fourth tab
        const control2 = this.toeForm2.controls;
        _toe.support_clarifications = control2.support_clarifications.value;

        let _date = new Date(control2.instruction_date.value);
        _toe.instruction_date = this.typesUtilsService.getDateStringFromDate(_date);

        _date = new Date(control2.draft_delivery_date.value);
        _toe.draft_delivery_date = this.typesUtilsService.getDateStringFromDate(_date);

        _date = new Date(control2.final_delivery_date.value);
        _toe.final_delivery_date = this.typesUtilsService.getDateStringFromDate(_date);

        _toe.selected_agency_documents = this.selectedAgencyDocuments;
        _toe.selected_tp_multimedias = this.selectedTpMultimedias;

        _toe.payment_terms = control2.payment_terms.value;
        _toe.additional_documents = this.additionalDocPath ? this.additionalDocPath : this.toe.additional_documents;

        _toe.payment = this.preparePayment(isComplete);
        _toe.payment_terms_ac = this.paymentTermsSubject.value;
        _toe.payment_taxes = this.paymentTaxesSubject.value;

        // get data of conflicts
        this.conflictService.conflictResultSubject.value.forEach(item => {
            this.coiProperties = [...this.coiProperties, ...item.coiDetailsTP];
            _toe[item.prefix] = item.index;
            _toe[item.prefix + '_description'] = item.text;
            _toe[item.prefix + '_description_b'] = item.text2;
            _toe[item.prefix + '_type_of_conflict_id'] = item.type_of_conflict_id;
            _toe[item.prefix + '_appropriate_parties'] = item.appropriate_parties;
            _toe[item.prefix + '_reason_continue'] = item.reason_continue;
            if (item.optionType_a === 5) {
                _toe[item.prefix + '_coi'] = item.index_a;
            }
            if (item.optionType_a === 7) {
                _toe[item.prefix + '_coi'] = item.index_a;
            }
        });

        // get data of disclosures
        this.conflictService.disclosureResultSubject.value.forEach(item => {
            _toe[item.prefix] = item.index;
            _toe[item.prefix + '_description'] = item.text;
        });
        _toe.valuer_involved_in_purchase_files = this.toe.valuer_involved_in_purchase_files;
        _toe.postValuationDeliveryServicetData = this.toe.postValuationDeliveryServicetData;

        return _toe;
    }

    /**
     * Add Toe
     *
     * @param _toe: Toe
     * @param _payment
     */
    addToe(_toe: ToeModel, _payment: PaymentModel) {
        this.store.dispatch(new ToeOnServerCreated({
            toe: _toe,
            workers: this.valuersSubject.value,
            rdms: this.selectedRdm,
            tp_list: this.targetProperties,
            coi_properties: this.coiProperties,
            payment: _payment,
            paymentTaxes: this.paymentTaxesSubject.value,
            valuations: this.valuationsSubject.value,
            paymentTerms: this.paymentTermsSubject.value,
            standards: this.toeStandards,
            fileList: this.uploadedFiles,
            isDuplicated: false
        }));

        this.oldToe = _toe;

        const addSubscription = this.store.pipe(select(selectLastCreatedToeId)).subscribe(newId => {
            this.toe = Object.assign({}, this.oldToe);
            this.toe.id = newId;
            this.ref.markForCheck();
            this.ref.detectChanges();
            this.tpChanged = false;
        });
        this.subscriptions.push(addSubscription);
    }

    /**
     * Update toe
     *
     * @param _toe: Toe
     * @param _payment
     * @param withBack: boolean
     */
    updateToe(_toe: ToeModel, _payment: PaymentModel, withBack: boolean = false) {

        const updateToe: Update<ToeModel> = {
            id: _toe.id,
            changes: _toe
        };

        this.store.dispatch(new ToeUpdated({
            toe: _toe,
            workers: this.valuersSubject.value,
            partialToe: updateToe,
            rdms: this.selectedRdm,
            tp_list: this.targetProperties,
            coi_properties: this.coiProperties,
            payment: _payment,
            paymentTaxes: this.paymentTaxesSubject.value,
            valuations: this.valuationsSubject.value,
            paymentTerms: this.paymentTermsSubject.value,
            standards: this.toeStandards,
            fileList: this.uploadedFiles,
        }));
        this.oldToe = _toe;
        this.toe = Object.assign({}, this.oldToe);

        let message = `ToE has been saved successfully.`;

        if (_toe.status > 0) {
            message = `ToE has been validated successfully.`;
        }

        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, false, false);
        this.ref.markForCheck();
        this.ref.detectChanges();
        this.tpChanged = false;
    }

    /**
     * Returns component title
     */
    getComponentTitle() {
        if (this.isTemplate) {
            if (!this._templateID) {
                return 'New ToE Template';
            }
            if (this.readonly) {
                return `View ToE Template "${this._templateName}" (READ ONLY)`
            }
            return `Edit ToE Template "${this._templateName}"`;
        }
        const result = `New ToE for "${this.assignment ? this.assignment.name : ''}" Assignment`;
        if (!this.toe || !this.toe.id) {
            return result;
        }

        if (this.toe.status > 0) {
            return `Edit ToE "${this.toe.name}" for "${this.assignment.name}" Assignment (READ ONLY)`;
        }

        return `Edit ToE "${this.toe.name}" for "${this.assignment.name}" Assignment`;
    }

    /**
     * Close Alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    private removeValidators() {
        const toe1FormControls = this.toeForm1.controls;
        Object.keys(toe1FormControls).filter(cName => cName != 'template_name' && cName != 'template_description' && cName != 'template_agency').forEach(cName => {
            toe1FormControls[cName].clearValidators();
            toe1FormControls[cName].updateValueAndValidity();
        });
        const toe2FormControls = this.toeForm2.controls;
        Object.keys(toe2FormControls).forEach(cName => {
            toe2FormControls[cName].clearValidators();
            toe2FormControls[cName].updateValueAndValidity();
        });
        const paymentFormControls = this.paymentForm.controls;
        Object.keys(paymentFormControls).forEach(cName => {
            paymentFormControls[cName].clearValidators();
            paymentFormControls[cName].updateValueAndValidity();
        })
    }

    private changeValidator(isComplete: boolean = false) {
        if (isComplete) {
            this.toeForm1.controls.name.setValidators([Validators.required]);
            this.toeForm1.controls.name.updateValueAndValidity();
            this.toeForm1.controls.purpose_of_valuation_id.setValidators([Validators.required]);
            this.toeForm1.controls.purpose_of_valuation_id.updateValueAndValidity();
            if (this.toeForm1.controls.purpose_of_valuation_id.value == 3) {
                this.toeForm1.controls.purpose_of_valuation_reason.setValidators([Validators.required]);
                this.toeForm1.controls.purpose_of_valuation_reason.updateValueAndValidity();
            }
            if (this.toeForm1.controls.responsibilities_liabilities.value == 1) {
                this.toeForm1.controls.responsibilities_liabilities_descr.setValidators([Validators.required]);
                this.toeForm1.controls.responsibilities_liabilities_descr.updateValueAndValidity();
            }
            if (this.toeForm1.controls.limitations_on_liability.value == 1) {
                this.toeForm1.controls.limitations_on_liability_descr.setValidators([Validators.required]);
                this.toeForm1.controls.limitations_on_liability_descr.updateValueAndValidity();
            }
            if (this.toeForm1.controls.generic_one.value == 1) {
                this.toeForm1.controls.generic_one_descr.setValidators([Validators.required]);
                this.toeForm1.controls.generic_one_descr.updateValueAndValidity();
            }
            this.toeForm1.controls.client_id.setValidators([Validators.required]);
            this.toeForm1.controls.client_id.updateValueAndValidity();
            if (this.toeForm1.controls.client_id.value && this.getClient(this.toeForm1.controls.client_id.value).type == 'Company') {
                this.toeForm1.controls.contact_id.setValidators([Validators.required]);
                this.toeForm1.controls.contact_id.updateValueAndValidity();
            }
            // this.toeForm1.controls.valuer_previous_involvment.setValidators([Validators.required]);

            this.toeForm1.controls.unit_of_measurement_id.setValidators([Validators.required]);
            this.toeForm1.controls.unit_of_measurement_id.updateValueAndValidity();
            this.toeForm1.controls.law_country_id.setValidators([Validators.required]);
            this.toeForm1.controls.law_country_id.updateValueAndValidity();
            this.toeForm1.controls.core_standard_id.setValidators([Validators.required]);
            this.toeForm1.controls.core_standard_id.updateValueAndValidity();

            this.toeForm2.controls.instruction_date.setValidators([Validators.required]);
            this.toeForm2.controls.instruction_date.updateValueAndValidity();
            this.toeForm2.controls.draft_delivery_date.setValidators([Validators.required]);
            this.toeForm2.controls.final_delivery_date.setValidators([Validators.required]);
            this.toeForm2.controls.final_delivery_date.updateValueAndValidity();
            this.toeForm2.controls.payment_terms.setValidators([Validators.required]);
        } else {

            this.toeForm1.controls.name.setValidators([Validators.required]);
            this.toeForm1.controls.name.updateValueAndValidity();
            this.toeForm1.controls.purpose_of_valuation_id.setValidators(null);
            this.toeForm1.controls.purpose_of_valuation_id.updateValueAndValidity()
            this.toeForm1.controls.purpose_of_valuation_reason.setValidators(null);
            this.toeForm1.controls.purpose_of_valuation_reason.updateValueAndValidity();
            this.toeForm1.controls.responsibilities_liabilities_descr.setValidators(null);
            this.toeForm1.controls.responsibilities_liabilities_descr.updateValueAndValidity();
            this.toeForm1.controls.limitations_on_liability_descr.setValidators(null);
            this.toeForm1.controls.limitations_on_liability_descr.updateValueAndValidity();
            this.toeForm1.controls.generic_one_descr.setValidators(null);
            this.toeForm1.controls.generic_one_descr.updateValueAndValidity();
            this.toeForm1.controls.client_id.setValidators(null);
            this.toeForm1.controls.client_id.updateValueAndValidity();
            this.toeForm1.controls.valuer_id.setValidators(null);
            this.toeForm1.controls.valuer_id.updateValueAndValidity();
            this.toeForm1.controls.contact_id.setValidators(null);
            this.toeForm1.controls.contact_id.updateValueAndValidity();
            this.toeForm1.controls.valuer_previous_involvment.setValidators(null);
            this.toeForm1.controls.valuer_previous_involvment.updateValueAndValidity();
            this.toeForm1.controls.core_standard_id.setValidators(null);
            this.toeForm1.controls.core_standard_id.updateValueAndValidity();

            this.toeForm1.controls.unit_of_measurement_id.setValidators(null);
            this.toeForm1.controls.unit_of_measurement_id.updateValueAndValidity();
            this.toeForm1.controls.law_country_id.setValidators(null);
            this.toeForm1.controls.law_country_id.updateValueAndValidity();

            this.toeForm2.controls.support_clarifications.setValidators(null);
            this.toeForm2.controls.instruction_date.setValidators(null);
            this.toeForm2.controls.draft_delivery_date.setValidators(null);
            this.toeForm2.controls.final_delivery_date.setValidators(null);
            this.toeForm2.controls.payment_terms.setValidators(null);

        }
        this.toeForm2.updateValueAndValidity();
        // Object.keys(this.toeForm1.controls).forEach(controlName => {
        //     this.toeForm1.controls[controlName].updateValueAndValidity();
        // });
    }

    showFiles() {
        const dialogRef = this.dialog.open(FileDialogV2Component, {
            data: {
                title: this.translate.instant('TOE.LIST.FILE_DIALOG.TITLE1'),
                files: this.toe.valuer_involved_in_purchase_files
            },
            minHeight: '150px'
        });

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.toe.valuer_involved_in_purchase_files = res;
            this.conflictService.fileCntSubject.next(this.toe.valuer_involved_in_purchase_files.length);
        });
    }

    // tp tab
    addTP() {
        const dialogRef = this.dialog.open(TpAddDialogComponent,
            {
                data: {
                    item: null,
                    measurement: {
                        id: this.toeForm1.controls.unit_of_measurement_id.value == 1 ? 2 : 1,
                        name: this.getSystemMeasurementUnitAreaName(this.toeForm1.controls.unit_of_measurement_id.value)
                    },
                    others: this.dataSource.data,
                    agency: this.agencyInfo
                },
                disableClose: true,
            });

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.tpChanged = true;
            this.targetProperties.push(res);
            this._getLatestFinalReportDate(this.targetProperties);
            this.dataSource.data = this.targetProperties;
            this._tps$.next(this.targetProperties)
            const valuations = this.computeValuations(this.targetProperties);
            this.valuationsSubject.next(valuations);
            const additionalCosts = this.handleTpDueDiligenceAdditionalCost(res, this.paymentTaxes);
            this.paymentTaxesSubject.next(additionalCosts);
            this.dataSourceBreakDown = new MatTableDataSource(this.valuationsSubject.value);
            this.ref.markForCheck();
            this.ref.detectChanges();
        });
    }

    addTpFromTemplate() {
        const dialogRef = this.dialog.open(TpTemplateListModalComponent, {

        });
        const dialogSub = dialogRef.afterClosed().subscribe(
            res => {
                if (!res) {
                    return;
                }
                switch (res.type) {
                    case 'select': {
                        this._addTpTemplate(res.template);
                        break;
                    }
                    case 'view': {
                        this.readonlyService.setReadOnly(true);
                        this.dialog.open(TpTemplateModalComponent, {
                            data: {
                                item: res.template
                            }
                        })
                        break;
                    }
                    default: {
                        return;
                    }
                }
            }
        )
        this.subscriptions.push(dialogSub);
    }
    _addTpTemplate(template: TpTemplateModel) {
        const ac = this.convertTpTemplateToTp(template);
        const dialogRef = this.dialog.open(TpAddDialogComponent,
            {
                data: {
                    item: ac,
                    measurement: {
                        id: this.toeForm1.controls.unit_of_measurement_id.value == 1 ? 2 : 1,
                        name: this.getSystemMeasurementUnitAreaName(this.toeForm1.controls.unit_of_measurement_id.value)
                    },
                    others: this.dataSource.data,
                    agency: this.agencyInfo
                },
                disableClose: true,
            });

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.tpChanged = true;
            this.targetProperties.push(res);
            this._getLatestFinalReportDate(this.targetProperties);
            this.dataSource.data = this.targetProperties;
            this._tps$.next(this.targetProperties)
            const valuations = this.computeValuations(this.targetProperties);
            this.valuationsSubject.next(valuations);
            const additionalCosts = this.handleTpDueDiligenceAdditionalCost(res, this.paymentTaxes);
            this.paymentTaxesSubject.next(additionalCosts);
            this.dataSourceBreakDown = new MatTableDataSource(this.valuationsSubject.value);
            this.ref.markForCheck();
            this.ref.detectChanges();
        });
    }

    deleteTP(_item, index) {
        const _title = this.translate.instant('TOE.PROMPT');
        const _description = this.translate.instant('TOE.TP.DELETE_TP_DESCRIPTION');
        const _waitDesciption = this.translate.instant('TOE.TP.PROMPT_WAIT_DELETE');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.tpChanged = true;
            this.targetProperties.splice(index, 1);
            const valuations = this.computeValuations(this.targetProperties);
            const _deletedTp = {
                ...(Object.assign({}, _item)),
                diligence_check: 0
            };
            this.valuationsSubject.next(valuations);
            const additionalCosts = this.handleTpDueDiligenceAdditionalCost(_deletedTp, this.paymentTaxes);
            this.paymentTaxesSubject.next(additionalCosts);
            this._getLatestFinalReportDate(this.targetProperties);
            this.dataSource.data = this.targetProperties;
            this._tps$.next(this.targetProperties)
        });
    }

    editTP(_item, index) {
        const dialogRef = this.dialog.open(TpAddDialogComponent,
            {
                data: {
                    item: _item,
                    measurement: {
                        id: this.toeForm1.controls.unit_of_measurement_id.value == 1 ? 2 : 1,
                        name: this.getSystemMeasurementUnitAreaName(this.toeForm1.controls.unit_of_measurement_id.value)
                    },
                    others: this.dataSource.data.filter((_item, indx) => indx != index),
                    agency: this.agencyInfo
                },
                disableClose: true,
            });

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.tpChanged = true;
            this.targetProperties[index] = res;
            this._getLatestFinalReportDate(this.targetProperties);
            this.dataSource.data = this.targetProperties;
            this._tps$.next(this.targetProperties)

            let valuations = [];
            valuations = this.computeValuations(this.targetProperties);
            this.valuationsSubject.next(valuations);

            const additionalCosts = this.handleTpDueDiligenceAdditionalCost(res, this.paymentTaxes);
            this.paymentTaxesSubject.next(additionalCosts);


            this.dataSourceBreakDown = new MatTableDataSource(this.valuationsSubject.value);
            this.ref.markForCheck();
            this.ref.detectChanges();
        });
    }

    viewTP(_item, index) {
        const dialogRef = this.dialog.open(TpAddDialogComponent,
            {
                data: {
                    item: _item,
                    viewMode: true,
                    measurement: {
                        id: this.toeForm1.controls.unit_of_measurement_id.value,
                        name: this.getSystemMeasurementUnitAreaName(this.toeForm1.controls.unit_of_measurement_id.value)
                    },
                    others: this.dataSource.data.filter((item, indx) => indx != index),
                    agency: this.agencyInfo
                },
            });
    }

    copyTP(_item: AssetClassModel) {
        const _originalName = _item.name;
        const _copyCnt = this._getLastCopy(_item);
        const _currentCopyNum = _copyCnt + 1;
        const _copyName = _originalName + ' (Copy ' + _currentCopyNum + ')';
        const _newTp = this._copyTP(_item, _copyName);
        this._addTp(_newTp);
    }

    _getLastCopy(_tp: AssetClassModel): number {
        let lastNum = 0;
        this.targetProperties.forEach(tp => {
            if (this._isCopy(_tp, tp)) {
                const num = this._getCopyNum(tp.name);
                lastNum = lastNum < num ? num : lastNum;
            }
        });
        return lastNum;
    }

    _isCopy(_tp: AssetClassModel, _copyTp: AssetClassModel): boolean {
        const name = this._getPropertyName(_copyTp);
        if (_tp.name === _copyTp.name) {
            return false;
        }
        if (_tp.name === name) {
            return true;
        }
        return false;
    }

    _getCopyNum(_name: string): number {
        const text = '(Copy';
        if (!_name.includes(text)) {
            return -1;
        }
        const indx = _name.lastIndexOf(text);
        const lastIndx = _name.length - 1;
        const str = _name.substring(indx, lastIndx);
        const num = str.match(/\d+/g).map(Number);
        return num[0];
    }

    _getPropertyName(_tp: AssetClassModel): string {
        const text = '(Copy';
        if (!_tp.name.includes(text)) {
            return _tp.name;
        }
        const indx = _tp.name.lastIndexOf(text);
        const name = _tp.name.substring(0, indx - 1);
        return name;
    }

    _copyTP(_tp: AssetClassModel, _copyName: string): AssetClassModel {
        const _newTp = Object.assign({}, _tp);
        _newTp.id = undefined;
        _newTp.name = _copyName;
        _newTp.is_complete = 0;

        // Valuations
        const _newValuations: ValuationModel[] = [];
        _newTp.valuations.forEach(val => {
            const _val = Object.assign({}, val);
            _val.id = undefined;
            _val.asset_class_id = undefined;
            _val.asset_class_name = _newTp.name;
            _newValuations.push(_val);
        });
        _newTp.valuations = _newValuations;

        // Assumptions
        const _newDefualtAssumptions: AssetClassDefaultAssumptionModel[] = [];
        _newTp.selected_default_assumptions.forEach(val => {
            const _newVal = Object.assign({}, val);
            _newVal.id = undefined;
            _newDefualtAssumptions.push(_newVal);
        });
        _newTp.selected_default_assumptions = _newDefualtAssumptions;
        const _newSpecialAssumptions: AssetClassSpecialAssumptionModel[] = [];
        _newTp.selected_special_assumptions.forEach(val => {
            const _newVal = Object.assign({}, val);
            _newVal.id = undefined;
            _newSpecialAssumptions.push(_newVal);
        });
        _newTp.selected_special_assumptions = _newSpecialAssumptions;

        // Departures
        const _newDepartures: AssetClassDepartureModel[] = [];
        _newTp.selected_departures.forEach(val => {
            const _newVal = Object.assign({}, val);
            _newVal.id = undefined;
            _newDepartures.push(_newVal);
        });
        _newTp.selected_departures = _newDepartures;

        // External Source References
        const _newSERs: AssetClassSourceExternalReferenceModel[] = [];
        _newTp.external_references.forEach(val => {
            const _newVal = Object.assign({}, val);
            _newVal.id = undefined;
            // _newVal.ac_id = undefined;
            _newSERs.push(_newVal);
        });
        _newTp.external_references = _newSERs;
        _newTp.external_references.forEach(val => {
        });

        return _newTp;
    }

    _addTp(_tp: AssetClassModel) {
        this.tpChanged = true;
        this.targetProperties.push(_tp);
        this._getLatestFinalReportDate(this.targetProperties);
        this.dataSource.data = this.targetProperties;
        this._tps$.next(this.targetProperties)
        let valuations = [];
        valuations = this.computeValuations(this.targetProperties);
        this.valuationsSubject.next(valuations);
        this.dataSourceBreakDown = new MatTableDataSource(this.valuationsSubject.value);
        const additionalCosts = this.handleTpDueDiligenceAdditionalCost(_tp, this.paymentTaxes);
        this.paymentTaxesSubject.next(additionalCosts);
        this.ref.markForCheck();
        this.ref.detectChanges();
    }

    computeValuations(tps: AssetClassModel[]): ValuationModel[] {
        let valuations: ValuationModel[] = [];
        tps.forEach(tp => {
            let tpValuations = [];
            tp.valuations.forEach(item => {
                const newItem = {...Object.assign({}, item), property_type_id: null, property_type_name: null};
                newItem.asset_class_type_id = tp.type_id;
                newItem.property_type_id = tp.top_property_type_id;
                if (tp.top_property_type_id == PropertyTypes.Custom) {
                    newItem.property_type_name = tp.custom_property_type ? tp.custom_property_type : tp.custom_asset_class_name;
                } else {
                    newItem.property_type_name = tp.type_name;
                }
                newItem.asset_class_name = tp.name;
                tpValuations.push(newItem);
            })
            valuations = [...valuations, ...tpValuations];
        });
        return valuations;
    }

    onTabChanged($event) {
        const activeIndex = $event.index;
        if (activeIndex === 5) {
            // payment
            this.checkTotalPrice = true;
            this.oldTotalPrice = this.getTotalPrice();
            this.unitSubject.next(this.getSystemMeasurementUnitAreaName(this.toeForm1.controls.unit_of_measurement_id.value));
        }
        if (activeIndex === 6) {
            this.canGenerate();
            // generate report
            if (this.toe.id) {

                this.store.dispatch(new AllToeReportsRequested({toeId: this.toe.id}));
                const toeReportSubscription = this.store.pipe(
                    select(selectAllToeReports),
                ).subscribe((res) => {
                    this.generatedReports.next(res.sort((a, b) =>
                        (new Date(a.created_at) < new Date(b.created_at)) ? 1 :
                            ((new Date(b.created_at) < new Date(a.created_at)) ? -1 : 0)));
                    this.toeDelivered();
                });

                this.subscriptions.push(toeReportSubscription);
            }
        }
    }
    onFileUpload(event) {
        this.additionalDocPath = event.success;
        this.uploadedFiles = this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : this.toe.additional_files;
    }

    // payment
    createFormPayment() {
        this.paymentForm = this.toeFB.group({
            agency_currency_id: [this.payment.agency_currency_id],
            client_currency_id: [this.payment.client_currency_id],
            discount: [this.payment.discount],
            exchange_rate: [this.payment.exchange_rate],
            is_validate: [this.payment.is_validate],
            price_upon_signature: [this.payment.price_upon_signature],
            total_price_amount: [this.payment.total_price_amount],
            total_price_letters: [this.payment.total_price_letters],
            vat: [this.payment.vat],
            currency: [this.payment.currency, Validators.required],
            vat_other: [this.payment.vat_other],
            pricing_strategy: [this.payment.pricing_strategy],
        });

        this.fetchSelectsPayment();

        this.paymentForm.controls.currency.valueChanges.subscribe(value => {
            this.currencySubject.next(value);
        });

        this.paymentForm.controls.pricing_strategy.valueChanges.subscribe(value => {
            if (value == 5) {
                this.valuationFee = this.valuersSubject.value.reduce((prev, cur) => {
                    return prev + Number(cur.total_price);
                }, 0);
            } else {
                this.valuationFee = this.valuationsSubject.value.reduce((prev, cur) => {
                    return prev + Number(cur.total_price);
                }, 0);
            }
        });
    }

    fetchSelectsPayment() {
        this.filteredCountriesData.next(this.countriesData.slice());

        this.filterCtrlCountriesData.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterCountriesData();
            });
    }


    protected filterCountriesData() {
        if (!this.countriesData) {
            return;
        }
        // get the search keyword
        let search = this.filterCtrlCountriesData.value;
        if (!search || search === '') {
            this.filteredCountriesData.next(this.countriesData.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the models
        this.filteredCountriesData.next(
            this.countriesData.filter(countryData => countryData.currencyCode.toLowerCase().indexOf(search) > -1)
        );
    }

    change(event, index) {
        if (event.target.value) {
            let _data = this.valuationsSubject.value;
            const temp: ValuationModel = Object.assign({}, _data[index]);
            temp.total_price = Number(event.target.value);
            _data = Object.assign([], _data, {[index]: temp});
            this.valuationsSubject.next(_data);
        }
    }

    /**
     * Returns prepared data for save
     */
    preparePayment(isComplete: boolean): PaymentModel {
        const controls = this.paymentForm.controls;
        const _payment = new PaymentModel();
        _payment.clear();
        _payment.terms_of_engagement_id = this.toe.id;
        _payment.is_validate = Number(isComplete);

        _payment.discount = controls.discount.value;
        _payment.vat = controls.vat.value;
        _payment.total_price_amount = this.getTotalPrice();
        _payment.total_price_letters = controls.total_price_letters.value;
        _payment.price_upon_signature = controls.price_upon_signature.value;
        _payment.exchange_rate = controls.exchange_rate.value;
        _payment.vat_other = controls.vat_other.value;
        _payment.pricing_strategy = controls.pricing_strategy.value;
        _payment.currency = controls.currency.value;

        return _payment;
    }

    private changePaymentValidator(isComplete: boolean = false) {
        if (isComplete) {
            this.paymentForm.controls.currency.setValidators([Validators.required]);
        } else {
            this.paymentForm.controls.currency.clearValidators();
        }
        this.paymentForm.controls.currency.updateValueAndValidity();
    }

    /**
     * Returns Item Status in string
     * @param status: number
     */
    getItemStatusString(status: number = 0): string {
        switch (status) {
            case 0:
                return 'Draft';
            case 1:
                return 'Validated';
            case 2:
                return 'Signed';
            case 3:
                return 'Completed';
            case 4:
                return 'Aborted';
        }
        return '';
    }

    /** UI */
    /**
     * Retursn CSS Class Name by status
     *
     * @param status: number
     */
    getItemCssClassByStatus(status: number = 0): string {
        switch (status) {
            case 0:
                return 'warning';
            case 4:
                return 'danger';

            default:
                return 'success';
        }
    }

    getSystemMeasurementUnitAreaName(id): string {
        if (!id) {
            return '(N/A)';
        }
        return '(' + (this.units.find(el => el.id == id) ? this.units.find(el => el.id == id).area : '') + ')';
    }

    getTotalPrice() {
        const totalPrice = (this.valuationFee + this.extraCost) -
            ((this.valuationFee + this.extraCost) * this.paymentForm.controls.discount.value / 100) +
            ((this.valuationFee + this.extraCost - ((this.valuationFee + this.extraCost) * this.paymentForm.controls.discount.value / 100)) *
                (this.paymentForm.controls.vat.value + this.paymentForm.controls.vat_other.value) / 100);
        this.totalPrice.next(totalPrice);
        return totalPrice;
    }

    updateCheckedOptions(item, $event) {
        if ($event.checked) {
            const obj: ToeStandardModel = new ToeStandardModel();
            obj.clear();
            obj.valuation_standard_id = item.id;
            obj.name = item.name;
            this.toeStandards = [...this.toeStandards, obj];
        } else {
            const index = this.toeStandards.findIndex(element => element.valuation_standard_id === item.id);
            if (index !== -1) {
                this.toeStandards.splice(index, 1);
            }
        }
    }

    setSelectedValuationStandard(item): boolean {
        if (this.toeStandards.length <= 0) {
            return false;
        } else {
            return this.toeStandards.some(element => element.valuation_standard_id === item.id);
        }
    }

    clearDate(_control) {
        _control.setValue(null);
        _control.updateValueAndValidity();
    }

    getLeadValuerName() {
        if (this.valuersSubject.value.length > 0) {
            const leadValuer = this.valuersSubject.value.find(item => item.lead_valuer === 1);
            return leadValuer.worker.first_name + ' ' + leadValuer.worker.last_name;
        }
        return 'N/A';
    }

    getClient(clientId): Client {
        if (this.clients.length > 0) {
            return this.clients.find(item => item.id === clientId);
        }
        return null;
    }

    uploadDoc() {
        this.fileInput.nativeElement.click();
    }

    generateReport() {
        if (this.toe.id) {
            const _toeReport = new ToeReport();
            _toeReport.clear();
            _toeReport.toe_id = this.toe.id;
            _toeReport.status = 0; // 0 means in progress
            _toeReport.toe_status = (this.generateReportAsDraft == true ? 0 : this.toe.status);
            _toeReport.user_id = this.currentUser.id;

            this.store.dispatch(new ToeReportOnServerCreated({
                toeReport: _toeReport
            }));

            setInterval(() => {
                this.generating = localStorage.getItem('generatingReport') == '1';
            }, 1000);
            this.generating = true;
            localStorage.setItem('generatingReport', '1');
            const toeReportSubscriptions = this.store.pipe(
                select(selectLastCreatedToeReportId),
                delay(1000), // Remove this line
            ).subscribe(res => {
                if (!res) {
                    return;
                }
                this.toeReportService.generateToeReport(this.toe.id, this.currentUser.id, _toeReport.toe_status, res).subscribe(res => {
                    if (res) {
                        this.toeReportService.getAllToeReports(this.toe.id).subscribe(res => {
                            if (res) {
                                this.generating = false;
                                localStorage.setItem('generatingReport', '0');
                                this.generatedReports.next(res.data.sort((a, b) =>
                                    (new Date(a.created_at) < new Date(b.created_at)) ? 1
                                        : ((new Date(b.created_at) < new Date(a.created_at)) ? -1 : 0)));
                                this.toeDelivered();
                            }
                        });
                    }
                });
            });

            this.subscriptions.push(toeReportSubscriptions);
        }
    }

    downloadToeReport(item, index) {
        window.open(item.url, '_blank');
    }

    editReport(item) {
        const _title = 'Add Description';
        const dialogRef = this.dialog.open(GeneratedReportEditDialog, {
            data: {
                title: _title,
                description: item.report_desc,
            },
            width: '440px',
            position: {
                top: '50px',
                right: '50px'
            }
        })

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const _tmp = Object.assign({}, item) as ToeReport;
            _tmp.report_desc = res.description;
            _tmp.sent_at = this.typesUtilsService.getDateStringFromDate(new Date(item.sent_at));
            const updatedToeReport: Update<ToeReport> = {
                id: _tmp.id,
                changes: _tmp
            }
            this.store.dispatch(new ToeReportUpdated({
                partialToeReport: updatedToeReport,
                toeReport: _tmp
            }))
            this.ref.markForCheck();
            this.ref.detectChanges();
        })
    }

    editSignedReport(item: SignedToeDocModel) {
        const _title = 'Edit Signed Toe';

        const _dialogRef = this.dialog.open(SignedToeDialogComponent, {
            data: {
                title: _title,
                item: item,
            },
            width: '440px',
            disableClose: false,
            hasBackdrop: false,
        });

        _dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.toeReportService.updateToeReportHasAltered(res.item)
                .subscribe((result) => {
                    this.currentSignedDocId.next(result.data.id);
                    let _data = this.signedReports.value;
                    let _index = findIndex(_data, {id: result.data.id});
                    _data.splice(_index, 1, result.data);
                    this.signedReports.next(_data);
                    // this.signedReports.next([result.data, ..._data]);
                });
        });
    }

    goDashboard() {
        if (this.toe && this.toe.id) {
            this.router.navigate(['../../toes/' + this.toe.id + '/dashboard'], {relativeTo: this.activatedRoute});
            return;
        }
        this.router.navigate(['../toes/' + this.toe.id + '/dashboard'], {relativeTo: this.activatedRoute});
    }

    revertToDraft() {

        const _title = this.translate.instant('TOE.PROMPT');
        const _description = this.translate.instant('TOE.REVERT_DRAFT_DESCRIPTION');
        const _waitDesciption = this.translate.instant('TOE.PROMPT_WAIT_REVERT');

        const dialogRef = this.layoutUtilsService.saveElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const draftToe = Object.assign({}, this.toe);
            draftToe.status = 0;

            const updateToe: Update<ToeModel> = {
                id: draftToe.id,
                changes: draftToe
            };
            this.store.dispatch(new ToeStatusUpdated({toe: updateToe}));
            this.signedReports.next([]);
            this.oldToe = draftToe;
            this.toe = Object.assign({}, this.oldToe);
            this.selectedTab = 0;
            this.ref.markForCheck();
            this.ref.detectChanges();

        });
    }

    deleteToeReport(item, index) {
        const _title = this.translate.instant('TOE.PROMPT');
        const _description = this.translate.instant('TOE.REPORT.DELETE_REPORT_DESCRIPTION');
        const _waitDesciption = this.translate.instant('TOE.REPORT.PROMPT_WAIT_DELETE');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.toeReportService.deleteToeReport(item.id).subscribe((res) => {
                const tempGeneratedReports = [...this.generatedReports.value];
                tempGeneratedReports.splice(index, 1);
                this.generatedReports.next(tempGeneratedReports);
                this.toeDelivered();
            });
        });
    }

    canGenerate() {
        const leadValuer = this.valuersSubject.value.find(item => item.lead_valuer === 1);

        // if (this.currentUser.role_id === 1) {   // admin
        //     this.canGenerateToeReport = true;
        // } else
        if (this.currentUser.id === this.assignment.manager.id) { // assignment manager
            this.canGenerateToeReport = true;
        } else this.canGenerateToeReport = !!(leadValuer && this.currentUser.id === leadValuer.worker_id);
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm(this.translate.instant('TOE.DISCARD_PROMPT'))) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (!this.oldToe) {
            return false;
        }
        const _toe = this.prepareToe();
        return !this.saveState && (_toe.purpose_of_valuation_id != this.oldToe.purpose_of_valuation_id ||
            _toe.purpose_of_valuation_reason != this.oldToe.purpose_of_valuation_reason ||
            _toe.restrictions_on_use != this.oldToe.restrictions_on_use ||
            _toe.restrictions_on_use_descr != this.oldToe.restrictions_on_use_descr ||
            _toe.responsibilities_liabilities != this.oldToe.responsibilities_liabilities ||
            _toe.responsibilities_liabilities_descr != this.oldToe.responsibilities_liabilities_descr ||
            _toe.limitations_on_liability != this.oldToe.limitations_on_liability ||
            _toe.limitations_on_liability_descr != this.oldToe.limitations_on_liability_descr ||
            _toe.generic_one != this.oldToe.generic_one ||
            _toe.generic_one_descr != this.oldToe.generic_one_descr ||
            _toe.assignment_id != this.oldToe.assignment_id ||
            _toe.name != this.oldToe.name ||
            _toe.client_id != this.oldToe.client_id ||
            _toe.contact_id != this.oldToe.contact_id ||
            _toe.valuer_id != this.oldToe.valuer_id ||
            _toe.valuer_previous_involvment != this.oldToe.valuer_previous_involvment ||
            _toe.unit_of_measurement_id != this.oldToe.unit_of_measurement_id ||
            _toe.law_country_id != this.oldToe.law_country_id ||
            _toe.support_clarifications != this.oldToe.support_clarifications ||
            _toe.instruction_date != this.oldToe.instruction_date ||
            _toe.draft_delivery_date != this.oldToe.draft_delivery_date ||
            _toe.final_delivery_date != this.oldToe.final_delivery_date ||
            _toe.payment_terms != this.oldToe.payment_terms ||
            this.tpChanged ||
            this.paymentFormChange ||
            this.checkChangesOnInclutionOfDocuments() ||
            this.checkChangesOnValuersDocuments() ||
            this.checkChangesOnValuersCertificates() ||
            (this.checkTotalPrice && this.oldTotalPrice != this.getTotalPrice()));
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHander($event) {
        if (this.discard()) {
            $event.preventDefault();
            $event.returnValue = 'test';
        }
    }

    checkLeadValuer() {
        let leadValuer = null;
        if (this.valuersSubject.value.length > 0) {
            leadValuer = this.valuersSubject.value.find(item => item.lead_valuer == 1);
            return leadValuer != null;
        }
        return false;
    }

    checkLeadValuerDailyRate() {
        let leadValuer = null;
        if (this.valuersSubject.value.length > 0) {
            leadValuer = this.valuersSubject.value.find(item => item.lead_valuer == 1);
            return leadValuer != null && leadValuer.days && leadValuer.days > 0 && leadValuer.daily_rate && leadValuer.daily_rate > 0;
        }
        return false;
    }

    checkCompanyDailyRate() {
        let valBreakdownWithZeroTotalPrice = null;
        if (this.valuationsSubject.value.length > 0) {
            valBreakdownWithZeroTotalPrice = this.valuationsSubject.value.find(item => item.total_price == 0 || item.total_price == undefined);
            return valBreakdownWithZeroTotalPrice != null;
        }
        return false;
    }

    checkChangesOnInclutionOfDocuments() {
        let array1 = this.oldToe.selected_agency_documents;
        let array2 = this.selectedAgencyDocuments;
        let is_same = (array1.length == array2.length) && array1.every(function (element, index) {
            return element === array2[index];
        });
        return !is_same;
    }

    checkChangesOnValuersDocuments() {
        let is_same = true;
        this.valuersSubject.value.forEach((item, index) => {
            let array1 = item.selected_document;
            let array2 = this.oldValuersSubject[index].selected_document;
            is_same = (array1.length == array2.length) && array1.every(function (element, index) {
                return element === array2[index];
            });
        });
        return !is_same;
    }

    checkChangesOnValuersCertificates() {
        let is_same = true;
        this.valuersSubject.value.forEach((item, index) => {
            let array1 = item.selected_certificate;
            let array2 = this.oldValuersSubject[index].selected_certificate;
            is_same = (array1.length == array2.length) && array1.every(function (element, index) {
                return element === array2[index];
            });
        });
        return !is_same;
    }

    checkChangesOnAddtionalFiles() {
        let array1 = this.oldToeAdditionalFiles;
        let array2 = this.uploadedFiles; // this.uploadFileComponent && this.uploadFileComponent.uploadFiles ? this.uploadFileComponent.uploadFiles : [];
        let is_same = (array1.length == array2.length) && array1.every(function (element, index) {
            return element === array2[index];
        });
        return !is_same;
    }

    cancelUpload() {
        this.isInProgressSub.next(false);
        this.cancelReq.next();
        this.cancelReq.complete();
    }

    uploadSignedDoc(event) {


        const files: FileList = event.target.files;
        let wrongExt = false;

        Array.from(files).forEach((file, i) => {
            const ext = file.name.split('.').pop().toLowerCase();
            if (!UploadFileComponent.fileTypes.find(e => e === ext)) {
                wrongExt = true;
            }
        });
        if (wrongExt) {
            alert(`Please upload "${UploadFileComponent.fileTypes.join(', ')}" files only.`);
            return;
        }


        const firstFile = files.item(0);
        const file_size = firstFile.size;
        const fileName = firstFile.name;
        const humanReadableSize = this.humanFileSize(file_size);

        const formData = new FormData();
        formData.append('file', firstFile, 'document.pdf');
        formData.append('path',
            'terms_of_engagement/signed_doc/' + this.toe.id + '/');


        this.fileUploadService.upload2path(formData)
            .pipe(
                takeUntil(
                    this.cancelReq
                ),
                catchError(error => throwError(error))
            )
            .subscribe(
                (res) => {

                    if (!res) {
                        return;
                    }

                    if (res.status === 'sent') {
                        this.isInProgressSub.next(true);
                        this.progressSub.next(0);
                        return;
                    }


                    if (res.status === 'progress') {
                        this.progressSub.next(res.message);
                        return;
                    }
                    if (res.status === 'error') {
                        this.isInProgressSub.next(false);
                        this.hasFailedSub.next(true);
                        this.errorSub.next('Too large file. Max file upload is: 16mb.');
                        return;
                    }

                    if (res.status == 'done') {

                        this.isInProgressSub.next(false);

                        const _item = new SignedToeDocModel();
                        _item.clear();
                        _item.toe_id = this.toe.id;
                        _item.checked = 1;
                        _item.url = res.path + res.name;
                        _item.file_name = fileName;
                        _item.user_id = this.currentUser.id;
                        _item.file_size = humanReadableSize;
                        _item.has_altered = 0;
                        _item.details = '';
                        this.toeReportService.createToeSignedReport(_item)
                            .subscribe((result) => {
                                this.currentSignedDocId.next(result.data.id);
                                let _data = this.signedReports.value;
                                this.signedReports.next([result.data, ..._data]);
                            });


                        // change status
                        if (this.toe.status < 2) {
                            const draftToe = Object.assign({}, this.toe);
                            draftToe.status = 2;
                            draftToe.signedReportUrl = _item.url;

                            const updateToe: Update<ToeModel> = {
                                id: draftToe.id,
                                changes: draftToe
                            };
                            this.store.dispatch(new ToeStatusUpdated({toe: updateToe}));
                            this.oldToe = draftToe;
                            this.toe = Object.assign({}, this.oldToe);
                        }

                    }
                },
                (err) => {
                    this.isInProgressSub.next(false);
                    this.hasFailedSub.next(true);
                    this.errorSub.next('Too large file. Max file upload is: 16mb.');
                }
            );

        // clear the input form
        event.srcElement.value = null;

    }

    previewSignedReport(report) {
        if (this.docs.indexOf(this.typesUtilsService.getFileType(report.url)) > -1) {
            window.open(environment.baseApiUrl + report.url);
        } else {
            const dialogRef = this.dialog.open(ImageViewerDialogComponent, {
                data: {
                    picture: report.url,
                    type: this.typesUtilsService.getFileType(report.url)
                }
            });
        }
    }

    deleteToeSignedReport(id) {
        const _title = this.translate.instant('TOE.PROMPT');
        const _description: string = this.translate.instant('TOE.SIGNED_REPORT.DELETE_REPORT_DESCRIPTION');
        const _waitDesciption = this.translate.instant('TOE.SIGNED_REPORT.PROMPT_WAIT_DELETE');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.toeReportService.deleteToeSignedReport(id)
                .pipe(take(1))
                .subscribe(
                    (res) => {
                    });

            let _data = this.signedReports.value;
            this.signedReports.next(_data.filter(el => el.id != id))
        });
    }

    changeSignedDoc($event, id) {

        $event.preventDefault()
        const _title = this.translate.instant('TOE.PROMPT');
        const _description = this.translate.instant('TOE.SIGNED_REPORT.CHANGE_REPORT_DESCRIPTION');
        const _waitDesciption = this.translate.instant('TOE.SIGNED_REPORT.PROMPT_WAIT_CHANGE');

        const dialogRef = this.layoutUtilsService.saveElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.toeReportService.updateToeSignedReport(this.toe.id, id)
                .pipe(take(1))
                .subscribe(
                    (res) => {
                    });
            this.currentSignedDocId.next(id);
        });
    }

    onchangeSentTo($ev, obj: ToeReport, index: number) {
        let tempObj = Object.assign({}, obj);
        if ($ev.checked) {
            tempObj.sent_to = 1;
            tempObj.sent_by = this.currentUser.id;
            tempObj.sent_by_name = this.currentUser.first_name + ' ' + this.currentUser.last_name;
            this.editReportDelivery(tempObj, index);
        } else {
            tempObj.sent_to = 0;
            tempObj.sent_by = null;
            const _tmp = Object.assign([], this.generatedReports.value);
            _tmp[index] = obj;
            this.generatedReports.next(_tmp);

            const updatedToeReport: Update<ToeReport> = {
                id: tempObj.id,
                changes: tempObj
            };
            this.store.dispatch(new ToeReportUpdated({partialToeReport: updatedToeReport, toeReport: tempObj}));
        }
        this.toeDelivered();
    }

    onchangeReportOption($ev) {
        if ($ev.checked) {
            this.generateReportAsDraft = true;
        } else {
            this.generateReportAsDraft = false;
        }
    }

    editReportDelivery(obj, index) {
        const _title = 'Edit Document Delivery';

        const dialogRef = this.dialog.open(ToeReportEditDialogComponent, {
            data: {title: _title, item: obj},
            width: '440px',
            disableClose: false,
            hasBackdrop: false,
        });

        dialogRef.afterClosed().subscribe(res => {
            const _tmp = Object.assign([], this.generatedReports.value);

            if (!res) {
                obj.sent_to = 0;
                _tmp[index] = obj;

            } else {
                _tmp[index] = res.item;
            }

            this.generatedReports.next(_tmp);

            const updatedToeReport: Update<ToeReport> = {
                id: res ? res.item.id : obj.id,
                changes: res ? res.item : obj
            };
            this.store.dispatch(new ToeReportUpdated({partialToeReport: updatedToeReport, toeReport: res ? res.item : obj}));
        });
        this.ref.markForCheck();
        this.ref.detectChanges();
        this.toeDelivered();
    }

    toeDelivered() {
        let delivered = false;
        this.generatedReports.value.forEach(report => {
            if (report.sent_to == 1 && report.toe_status >= 1) {
                delivered = true;
            }
        });
        this.delivered = delivered;
    }

    private humanFileSize(bytes: number, dp: number = 1): string {
        const thresh = 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(dp) + ' ' + units[u];
    }

    matSelectCompareWith(a: any, b: any) {
        return a + '' === b + '';
    }

    prevKnowledgeRadioChange(event: MatRadioChange) {
        this.selectedPrevKnowledge = event.value;
        this.ciTemplateData$.next(this.filterCoiService.getCiData(this.ciData, this.purposeOfValuationID === 8, this.selectedPrevKnowledge === 1, this.selectedFinancialInterest === 1, this.isTemplate));
    }
    financialInterestRadioChange(event: MatRadioChange) {
        this.selectedFinancialInterest = event.value;
        this.ciTemplateData$.next(this.filterCoiService.getCiData(this.ciData, this.purposeOfValuationID === 8, this.selectedPrevKnowledge === 1, this.selectedFinancialInterest === 1, this.isTemplate));
    }

    @HostListener('window:beforeunload')
    saveAssignmentState() {
        // this.store.dispatch(new SaveState({
        //     name: 'assignments'
        // }));
    }

    /** Due diligence */
    private handleTpDueDiligenceAdditionalCost(tp: AssetClassModel, additionalCosts: PaymentTaxModel[]): PaymentTaxModel[] {
        const _additionalCosts = Object.assign([], additionalCosts) as PaymentTaxModel[];

        if (tp.diligence_check == 1) {
            // Add due diligence
            // 1. check if it already exists
            let indx = _additionalCosts.findIndex(ac => {
                if (ac.name == 'Due Diligence' && (ac.asset_class_name == tp.name || ac.asset_class_name == tp.prev_name)) {
                    return true;
                }
                return false;
            });
            if (indx > -1) {
                if (tp.name != tp.prev_name) {
                    const ac = _additionalCosts[indx];
                    ac.asset_class_name = tp.name;
                    _additionalCosts.splice(indx, 1, ac)
                    return _additionalCosts;
                }
                return _additionalCosts;
            }

            // 2. add if it does not exist
            let _additionalCost = new PaymentTaxModel();
            _additionalCost.clear();
            _additionalCost.terms_of_engagement_id = tp.toe_id;
            _additionalCost.asset_class_id = tp.id;
            _additionalCost.asset_class_name = tp.name;
            _additionalCost.name = 'Due Diligence';
            _additionalCost.qty = 1;
            _additionalCost.amount = 0;
            _additionalCosts.push(_additionalCost);
        } else {
            // Remove due diligence
            // 1. check if it exists
            let indx = _additionalCosts.findIndex(ac => {
                if (ac.name == 'Due Diligence' && ac.asset_class_name == tp.name) {
                    return true;
                }
                return false;
            });
            if (indx == -1) {
                return _additionalCosts;
            }

            // 2. remove it
            _additionalCosts.splice(indx, 1);
        }

        return _additionalCosts;
    }

    /** Toe Template */
    private prepareToeTemplate(): ToeTemplateModel {
        const newTemplate = new ToeTemplateModel();

        const control1 = this.toeForm1.controls;
        newTemplate.id = this._templateID;
        newTemplate.name = control1.template_name.value;
        newTemplate.description = control1.template_description.value;
        const agency_id = Number(control1.template_agency.value);
        newTemplate.agency_id = agency_id == -1 ? null : agency_id;
        newTemplate.status_of_valuer = control1.status_of_valuer.value;
        newTemplate.purpose_of_valuation_id = control1.purpose_of_valuation_id.value;
        newTemplate.purpose_of_valuation_reason = control1.purpose_of_valuation_reason.value;
        newTemplate.restrictions_on_use = control1.restrictions_on_use.value;
        newTemplate.restrictions_on_use_intended_users = control1.restrictions_on_use_intended_users.value;
        newTemplate.responsibilities_liabilities = control1.responsibilities_liabilities.value;
        newTemplate.responsibilities_liabilities_descr = control1.responsibilities_liabilities_descr.value;
        newTemplate.limitations_on_liability = control1.limitations_on_liability.value;
        newTemplate.limitations_on_liability_descr = control1.limitations_on_liability_descr.value;
        newTemplate.generic_one = control1.generic_one.value;
        newTemplate.generic_one_descr = control1.generic_one_descr.value;

        newTemplate.valuation_standards = this.toeStandards.map(ts => ({
            id: ts.id,
            valuation_standard_id: ts.valuation_standard_id,
            template_id: newTemplate.id
        })); 

        newTemplate.client_id = control1.client_id.value;
        newTemplate.contact_id = control1.contact_id.value;

        newTemplate.unit_of_measurement_id = control1.unit_of_measurement_id.value;
        newTemplate.law_country_id = control1.law_country_id.value;

        newTemplate.members = this.valuersSubject.value.map(valuer => {
            return {
                id: valuer.id,
                template_id: newTemplate.id,
                worker_id: valuer.worker_id,
                lead_valuer: valuer.lead_valuer,
                selected_document: valuer.selected_document,
                selected_certificate: valuer.selected_certificate,
                assigned_default_tasks: valuer.assigned_default_tasks,
            }
        });
        newTemplate.selected_agency_documents = this.selectedAgencyDocuments;

        newTemplate.has_prev_knowledge = this.selectedPrevKnowledge;
        newTemplate.has_financial_interest = this.selectedFinancialInterest;

        this.conflictService.conflictResultSubject.value.forEach(item => {
            newTemplate[item.prefix] = item.index;
            newTemplate[item.prefix + '_description'] = item.text;
            newTemplate[item.prefix + '_description_b'] = item.text2;
            newTemplate[item.prefix + '_type_of_conflict_id'] = item.type_of_conflict_id;
            newTemplate[item.prefix + '_appropriate_parties'] = item.appropriate_parties;
            newTemplate[item.prefix + '_reason_continue'] = item.reason_continue;
            if (item.optionType_a === 5) {
                newTemplate[item.prefix + '_coi'] = item.index_a;
            }
            if (item.optionType_a === 7) {
                newTemplate[item.prefix + '_coi'] = item.index_a;
            }
        })

        this.conflictService.disclosureResultSubject.value.forEach(item => {
            newTemplate[item.prefix] = item.index;
            newTemplate[item.prefix + '_description'] = item.text;
        })
        newTemplate.valuer_involved_in_purchase_files = this.toe.valuer_involved_in_purchase_files.map(f => ({
            id: f.id ? f.id : undefined,
            path: f.path,
            name: f.name,
            title: f.title,
            descr: f.descr ? f.descr : null
        }));

        newTemplate.deliveries = this.selectedRdm;
        const control2 = this.toeForm2.controls;
        newTemplate.support_clarifications = control2.support_clarifications.value;

        const paymentControls = this.paymentForm.controls;
        newTemplate.payment = {
            id: this.payment.id,
            template_id: newTemplate.id,
            discount: paymentControls.discount.value,
            vat_other: paymentControls.vat_other.value,
            vat: paymentControls.vat.value,
            currency: paymentControls.currency.value,
            total_price: this.getTotalPrice()
        }
        newTemplate.paymentTaxes = this.paymentTaxesSubject.value.map(pt => ({
            id: pt.id,
            template_id: newTemplate.id,
            name: pt.name,
            qty: pt.qty,
            amount: pt.amount
        }));
        newTemplate.paymentTerms = this.paymentTermsSubject.value.map((pt, i) => ({
            id: pt.id,
            template_id: newTemplate.id,
            title: pt.title,
            percent: pt.percent,
            amount: pt.amount,
            order: i,
            payment_date: pt.payment_date
        }));

        newTemplate.otherDocuments = this.uploadedFiles.map(f => ({
            id: f.id ? f.id : undefined,
            path: f.path,
            name: f.name,
            title: f.title,
            descr: f.descr ? f.descr : null
        }))

        return newTemplate;
    }

    private convertQuoteToToeRelatedData(quote: Quote): {
        toe: ToeModel,
        standards: ToeStandardModel[],
        workers: SupportingValuerModel[],
        payment: PaymentModel,
        paymentTaxes: PaymentTaxModel[],
        paymentTerms: PaymentTermModel[],
        tps: AssetClassModel[]
    } {
        const toe = new ToeModel();
        toe.clear()
        toe.assignment_id = this.assignmentId
        toe.status = 0
        toe.client_id = quote.client_id
        toe.contact_id = quote.client_contact_id
        toe.core_standard_id = quote.core_standard_id
        toe.purpose_of_valuation_id = quote.purpose_of_valuation_id
        toe.purpose_of_valuation_reason = quote.purpose_of_valuation_reason
        toe.unit_of_measurement_id = quote.unit_measurement_id

        const toeStandards: ToeStandardModel[] = quote.custom_supplements.map(id => {
            const ts = new ToeStandardModel()
            ts.clear()
            ts.terms_of_engagement_id = toe.id
            ts.valuation_standard_id = id
            return ts
        })
        const documents = quote.documents
            ? quote.documents.split(',').map(document => {
                const splits = document.split(':')
                return {
                    type: splits[0] == 'agency'
                        ? 'Agency'
                        : splits[0] == 'user'
                            ? 'User'
                            : 'Certificate',
                    id: Number(splits[1])
                }
            })
            : []
        toe.selected_agency_documents = documents
            .filter(item => item.type == 'Agency')
            .map(item => item.id) 
        const workers: SupportingValuerModel[] = quote.members.map(m => {
            const worker = new SupportingValuerModel()
            worker.clear()
            worker.terms_of_engagement_id = toe.id
            worker.worker_id = m.user_id
            worker.lead_valuer = m.is_lead_valuer ? 1 : 0
            worker.worker = {
                ...m.user,
                id: m.user_id
            } as User
            worker.selected_document = documents
                .filter(item => item.type == 'User')
                .map(item => {
                    const id = m.user.files.find(file => file.id == item.id)
                    return id
                }) 
                .filter(id => id !== undefined)
                .map(item => item.id)
            worker.selected_certificate = documents
                .filter(item => item.type == 'Certificate')
                .map(item => {
                    const id = m.user.certificates.find(file => file.id == item.id)
                    return id
                })
                .filter(id => id !== undefined)
                .map(item => item.id)
            worker.assigned_default_tasks = m.assigned_tasks
            return worker
        })
        const payment = new PaymentModel()
        payment.clear()
        payment.discount = quote.discount
        payment.vat = quote.vat
        payment.vat_other = quote.other_taxes
        payment.currency = quote.currency
        payment.pricing_strategy = quote.pricing_strategy
        // payment.total_price_amount

        const paymentTaxes: PaymentTaxModel[] = quote.additional_costs.map(ac => {
            const tp = quote.target_properties.find(tp => tp.id == ac.target_property_id)
            const paymentTax = new PaymentTaxModel()
            paymentTax.clear()
            paymentTax.terms_of_engagement_id = toe.id
            paymentTax.asset_class_name = tp ? tp.name : 'General'
            paymentTax.name = ac.title ? ac.title.name : null
            paymentTax.qty = ac.quantity
            paymentTax.amount = ac.amount
            paymentTax.description = ac.description
            return paymentTax
        })

        const paymentTerms: PaymentTermModel[] = quote.payment_terms.map((pt, i) => {
            const paymentTerm = new PaymentTermModel()
            paymentTerm.clear()
            paymentTerm.terms_of_engagement_id = toe.id
            paymentTerm.title = (i + 1) == quote.payment_terms.length
                ? 'Final Payment Term'
                : `Payment Term-${i+1}`
            paymentTerm.percent = pt.percent
            paymentTerm.payment_date = pt.date
            return paymentTerm
        })

        const assetClasses = quote.target_properties.map(tp => {
            const assetClass = new AssetClassModel()
            assetClass.clear()
            assetClass.name = tp.name
            assetClass.is_complete = 0 
            assetClass.prev_name = tp.name
            assetClass.surface = tp.surface
            assetClass.top_property_type_id = tp.property_type_id
            assetClass.type_id = tp.property_sub_type_id
            assetClass.type_name = tp.property_sub_type ? tp.property_sub_type.name : null
            assetClass.floors_valuated = tp.floor_locations.join(',')

            assetClass.country_id = tp.country_id
            assetClass.city_id = tp.city_id
            assetClass.zip_code = tp.zip_code
            assetClass.address = tp.address
            assetClass.latitude = Number(tp.latitude)
            assetClass.longitude = Number(tp.longitude)

            assetClass.custom_asset_class_name = tp.custom_property_type_name
            assetClass.custom_property_type = tp.custom_property_sub_type_name
            assetClass.custom_floor_valuated = tp.custom_floor_locations

            assetClass.instructed_to_measure = tp.instructed_to_measure ? 1 : 0
            assetClass.type_of_inspection = tp.investigation_type_id
            assetClass.diligence_check = tp.diligence_check ? 1 : 0

            assetClass.draft_date = tp.draft_date
            assetClass.inspection_date = tp.inspection_date
            assetClass.valuation_date = tp.final_date

            assetClass.standard_measurement_id = tp.measurement_standard_id
            assetClass.measurement_methodology_id = tp.measurement_methodology_id
            assetClass.new_standard_measurement_name = tp.custom_measurement_standard_name
            assetClass.purpose_measurement_id = tp.purpose_of_measurement_id
            assetClass.percentage = tp.interest_valued
            assetClass.discount = tp.interest_discount
            assetClass.discount_descr = tp.interest_discount_descr

            assetClass.valuations = tp.valuations.map(valuation => {
                const item = new ValuationModel()
                item.clear()
                item.asset_class_name = tp.name
                item.asset_class_type_id = tp.property_sub_type_id
                item.property_type_name = tp.property_sub_type ? tp.property_sub_type.name : 'N/A'
                item.base_of_value_id = valuation.basis_of_value_id
                item.base_of_value_name = valuation.basis_of_value ? valuation.basis_of_value.name : 'N/A'
                item.approaches_to_value_id = valuation.approach_to_value_id
                item.methods_to_value_id = valuation.method_to_value_id
                item.methods_to_value_name = valuation.method_to_value ? valuation.method_to_value.name : 'N/A'
                item.premise_of_value_id = valuation.premise_of_value_id
                item.tenure_id = valuation.tenure_id ? valuation.tenure_id : valuation.land_tenure_id
                item.tenure_custom = valuation.custom_tenure_name
                item.consideration_type_id = valuation.consideration_type_id
                item.estimated_value = valuation.estimated_value == null ? 0 : valuation.estimated_value
                item.percentage = valuation.percentage == null ? 0 : valuation.percentage
                item.per_size_value = valuation.per_size_value == null ? 0 : valuation.per_size_value
                item.daily_rate = valuation.daily_rate == null ? 0 : valuation.daily_rate
                item.days = valuation.days == null ? 0 : valuation.days
                item.inspection_type = tp.investigation_type_id
                item.total_price = valuation.total_price 
                item.surface = tp.surface
                return item;
            })
            return assetClass
        })
        quote.other_documents.forEach(doc => toe.additional_files.push({...doc, id: undefined, descr: null}))

        return {
            toe,
            standards: toeStandards,
            workers,
            payment,
            paymentTaxes,
            paymentTerms,
            tps: assetClasses
        }
    }

    private convertTemplateToTeoRelatedData(template: ToeTemplateModel, fromTemplate: boolean): {
        toe: ToeModel,
        standards: ToeStandardModel[],
        workers: SupportingValuerModel[],
        rdms: number[],
        payment: PaymentModel,
        paymentTaxes: PaymentTaxModel[],
        paymentTerms: PaymentTermModel[],
    } {
        const toe = new ToeModel();
        toe.clear();
        toe.assignment_id = this.assignmentId;
        toe.status = 0;
        toe.status_of_valuer = template.status_of_valuer;
        toe.client_id = template.client_id;
        toe.contact_id = template.contact_id;
        toe.purpose_of_valuation_id = template.purpose_of_valuation_id;
        toe.purpose_of_valuation_reason = template.purpose_of_valuation_reason;
        toe.restrictions_on_use = template.restrictions_on_use;
        toe.restrictions_on_use_intended_users = template.restrictions_on_use_intended_users;
        toe.responsibilities_liabilities = template.responsibilities_liabilities;
        toe.responsibilities_liabilities_descr = template.responsibilities_liabilities_descr;
        toe.limitations_on_liability = template.limitations_on_liability;
        toe.limitations_on_liability_descr = template.limitations_on_liability_descr;
        toe.generic_one = template.generic_one;
        toe.generic_one_descr = template.generic_one_descr;
        toe.unit_of_measurement_id = template.unit_of_measurement_id;
        toe.law_country_id = template.law_country_id;

        const toeStandards: ToeStandardModel[] = template.valuation_standards.map(vs => {
            const ts: ToeStandardModel = new ToeStandardModel();
            ts.clear();
            ts.id = fromTemplate ? undefined : vs.id;
            ts.terms_of_engagement_id = toe.id;
            ts.valuation_standard_id = vs.valuation_standard_id;
            return ts;
        });

        const workers: SupportingValuerModel[] = template.members.map(m => {
            const worker = new SupportingValuerModel();
            worker.clear();
            worker.id = fromTemplate ? undefined : m.id;
            worker.terms_of_engagement_id = toe.id;
            worker.worker_id = m.worker_id;
            worker.lead_valuer = m.lead_valuer;
            worker.worker = m.worker;
            worker.selected_document = m.selected_document;
            worker.selected_certificate = m.selected_certificate;
            worker.assigned_default_tasks = m.assigned_default_tasks;
            return worker;
        })
        toe.selected_agency_documents = template.selected_agency_documents;
        toe.has_prev_knowledge = template.has_prev_knowledge;
        toe.has_financial_interest = template.has_financial_interest;

        toe.copyCOIData(template);
        toe.copyDData(template);
        toe.valuer_involved_in_purchase_files = template.valuer_involved_in_purchase_files;

        toe.support_clarifications = template.support_clarifications;

        const payment = new PaymentModel();
        payment.clear();
        if (template.payment) {
            payment.id = fromTemplate ? undefined : template.payment.id;
            payment.discount = template.payment.discount;
            payment.vat = template.payment.vat;
            payment.vat_other = template.payment.vat_other;
            payment.currency = template.payment.currency;
            payment.total_price_amount = template.payment.total_price;
        }
        const paymentTaxes: PaymentTaxModel[] = template.paymentTaxes.map(pt => {
            const paymentTax = new PaymentTaxModel();
            paymentTax.clear();
            paymentTax.id = fromTemplate ? undefined : pt.id;
            paymentTax.terms_of_engagement_id = toe.id;
            paymentTax.name = pt.name;
            paymentTax.qty = pt.qty;
            paymentTax.amount = pt.amount;
            return paymentTax;
        });
        const paymentTerms: PaymentTermModel[] = template.paymentTerms.map(pt => {
            const paymentTerm = new PaymentTermModel();
            paymentTerm.clear();
            paymentTerm.id = fromTemplate ? undefined : pt.id;
            paymentTerm.terms_of_engagement_id = toe.id;
            paymentTerm.title = pt.title;
            paymentTerm.percent = pt.percent;
            paymentTerm.amount = pt.amount;
            paymentTerm.payment_date = pt.payment_date;
            paymentTerm.order = pt.order;
            return paymentTerm;
        });
        template.otherDocuments.forEach(doc => toe.additional_files.push(doc));
        return {
            toe,
            standards: toeStandards,
            workers,
            rdms: template.deliveries,
            payment,
            paymentTaxes,
            paymentTerms
        }
    }

    private convertTpTemplateToTp(template: TpTemplateModel): AssetClassModel {
        const ac = new AssetClassModel();
        ac.clear();
        ac.type_id = template.type_id;
        ac.top_property_type_id = template.top_property_type_id;
        ac.property_type_id = template.property_type_id;
        ac.premise_of_value_id = template.premise_of_value_id;
        ac.tenure_id = template.tenure_id;
        
        ac.custom_asset_class_name = template.custom_asset_class_name;
        ac.custom_property_type = template.custom_property_type;
        ac.custom_tenure = template.custom_tenure;

        ac.percentage = template.percentage;
        ac.discount = template.discount;
        ac.discount_descr = template.discount_descr;

        ac.country_id = template.country_id;
        ac.city_id = template.city_id;
        ac.zip_code = template.zip_code;

        ac.included_all_equipment = template.included_all_equipment;
        ac.included_all_equipment_descr = template.included_all_equipment_descr;
        ac.included_furniture = template.included_furniture;
        ac.included_furniture_descr = template.included_furniture_descr;
        ac.natural_accident_consideration = template.natural_accident_consideration;
        ac.natural_accident_consideration_descr = template.natural_accident_consideration_descr;
        ac.material_accident_consideration = template.material_accident_consideration;
        ac.material_accident_consideration_descr = template.material_accident_consideration_descr;
        ac.diligence_check = template.diligence_check;
        ac.diligence_check_descr = template.diligence_check_descr;
        ac.type_of_inspection = template.type_of_inspection;
        ac.instructed_to_measure = template.instructed_to_measure;
        ac.standard_measurement_id = template.standard_measurement_id;
        ac.measurement_methodology_id= template.measurement_methodology_id;
        ac.purpose_measurement_id = template.purpose_measurement_id;
        ac.intended_lender = template.intended_lender;
        ac.specific_req_on_intended_lender = template.specific_req_on_intended_lender;
        ac.specific_req_on_intended_lender_descr = template.specific_req_on_intended_lender_descr;
        ac.expected_limitations_restrictions = template.expected_limitations_restrictions;
        ac.limitations_and_restrictions = template.limitations_and_restrictions;

        ac.country_currency = template.country_currency;
        ac.reporting_currency = template.reporting_currency;
        ac.currency_exchange_rate = template.currency_exchange;

        ac.valuations = template.valuations.map(v => {
            const val = new ValuationModel();
            val.clear();
            val.approaches_to_value_id = v.approaches_to_value_id;
            val.approaches_to_value_name = v.approaches_to_value_name;
            val.methods_to_value_id = v.methods_to_value_id;
            val.methods_to_value_name = v.methods_to_value_name;
            val.premise_of_value_id = v.premise_of_value_id;
            val.tenure_id = v.tenure_id;
            val.tenure_custom = v.tenure_custom;
            // val.interest_valued = v.interest_valued;
            // val.discount = v.discount;
            // val.comment = v.comment;
            return val;
        });
        ac.selected_default_assumptions = template.selectedAssumptions.map(assumption => {
            const model = new AssetClassDefaultAssumptionModel();
            model.clear();
            model.default_assumption_id = assumption.type == 0 ? assumption.assumption_id : undefined;
            model.default_assumption_name = assumption.assumption_name;
            return model;
        })
        ac.selected_special_assumptions = template.selectedSpecialAssumptions.map(assumption =>{
            const model = new AssetClassSpecialAssumptionModel();
            model.clear();
            model.special_assumption_id = assumption.type == 0 ? assumption.assumption_id : undefined;
            model.special_assumption_name = assumption.assumption_name;
            return model;
        })
        ac.selected_departures = template.selectedDepartures.map(departure => {
            const model = new AssetClassDepartureModel();
            model.clear();
            model.departure_id = departure.type == 0 ? departure.departure_id : undefined;
            model.departure_name = departure.departure_name;
            model.valuation_standard_id = departure.valuation_standard_id;
            model.valuation_standard_name = departure.valuation_standard_name;
            return model;
        })
        ac.vpgas = template.vpgas.map(vpga => {
            const v = new LocalVpgaModel();
            v.vpga = vpga.vpga;
            return v;
        });
        ac.external_references = template.external_refs.map(er => {
            const acser = new AssetClassSourceExternalReferenceModel();
            acser.clear();
            acser.source_external_reference_id = er.source_external_reference_id;
            acser.source_external_reference = er.source_external_reference;
            acser.reference_available = er.reference_available;
            acser.assumption = er.assumption;
            acser.assumption_descr = er.assumption_descr;
            acser.special_assumption = er.special_assumption;
            acser.special_assumption_descr = er.special_assumption_descr;
            return acser;
        })


        return ac;
    }

    onTabChange(index: number) {
        this.selectedTab = index;
    }

    private _convertSupportingToMember(valuers: SupportingValuerModel[]): IToeTeamMember[] {
        return valuers.map(val => {
            return {
                id: val.id,
                ref_id: val.terms_of_engagement_id,
                lead_valuer: val.lead_valuer,
                position: val.position,
                daily_rate: val.daily_rate ? val.daily_rate : 0,
                days: val.days ? val.days : 0,
                total_price: val.total_price ? val.total_price : 0,
                worker_id: val.worker_id,
                worker: val.worker,
                assigned_default_tasks: val.assigned_default_tasks,
                selected_certificates: val.selected_certificate,
                selected_document: val.selected_document
            }
        })
    }
    private _convertMemberToSupporting(members: IToeTeamMember[]): SupportingValuerModel[] {
        return members.map(m => {
            const val = new SupportingValuerModel();
            val.clear();
            val.id = m.id;
            val.terms_of_engagement_id = m.ref_id;
            val.worker_id = m.worker_id;
            val.worker = m.worker;
            val.lead_valuer = m.lead_valuer;
            val.position = m.position;
            val.daily_rate = m.daily_rate;
            val.days = m.days;
            val.total_price = m.total_price ? m.total_price : 0;
            val.selected_document = m.selected_document;
            val.selected_certificate = m.selected_certificates;
            val.assigned_default_tasks = m.assigned_default_tasks;
            return val;
        }) 
    }

    private _getLatestFinalReportDate(tps: AssetClassModel[]) {
        this.latestFinalReportDate = new Date(null);
        tps.filter(tp => tp.valuation_date != null || tp.valuation_date != "").forEach(tp => {
            if ((new Date(this.typesUtilsService.getDateFromString(tp.valuation_date))).getTime() >= this.latestFinalReportDate.getTime()) {
                this.latestFinalReportDate = new Date(this.typesUtilsService.getDateFromString(tp.valuation_date));
            }
        })
        if (this.latestFinalReportDate.getTime() == (new Date(null)).getTime()) {
            return;
        }
        if (this.instructionDate.getTime() > this.latestFinalReportDate.getTime()) {
            this.toeForm2.controls.instruction_date.patchValue(this.latestFinalReportDate);
        }
        this.toeForm2.controls.final_delivery_date.patchValue(this.latestFinalReportDate);
        this.cdr.detectChanges();
    }

    getInstructionDateMax() {
        const dateOfCompletion = new Date(this.toeForm2.controls.final_delivery_date.value);
        return dateOfCompletion;
    }
    getCompletionDateMin() {
        return this.latestFinalReportDate;
    }

    addClient() {
        const dialogRef = this.dialog.open(ClientModalComponent);
        dialogRef.afterClosed().pipe(takeUntil(this._onDestroy)).subscribe(res => {
            if (!res) {
                return;
            }
            this.toeForm1.controls.client_id.setValue(res.id);
            this.toeForm1.controls.client_id.updateValueAndValidity();
            this.store.dispatch(new AllContactsRequested({clientId: res.id}));
        });
    }

    isExpired(dateValue) {
        if (dateValue == null || dateValue == undefined || dateValue.trim().length == 0) {
            return false;
        }
        const date = this.typesUtilsService.getDateFromString(dateValue);
        return this.typesUtilsService.compareTwoDate(date, new Date()) <= 0;
    }

    openToeConflicts() {
        this.dialog.open(ToeConflictsModalComponent, {
            width: '80vw'
        })
    }

    changeMultimediaSupport(event: MatCheckboxChange) {
        this.hasMultimediaSupport = event.checked
    }


    getPvdData() {
        if (this.toe.postValuationDeliveryServicetData && this.toe.postValuationDeliveryServicetData.id) {
            return this.toe.postValuationDeliveryServicetData
        }
        if ( this.agencyInfo.postValuationDeliveryServicetData ) {
            return this.agencyInfo.postValuationDeliveryServicetData
        }
        return null;
    }
}

export const CONSTANTS = {
   pov_none : 3
}
