import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { MadLogin } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';

@Component({
  selector: 'kt-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.scss']
})
export class PostLoginComponent implements OnInit {

  constructor(
    private _route: ActivatedRoute,
    private _store$: Store<AppState>
  ) { }

  ngOnInit(): void {
    const token = this._route.snapshot.queryParamMap.get('access_token') as string
    if (token) {
      this._store$.dispatch(new MadLogin({token}))
    }
  }

}
