import { Component, EventEmitter, Host, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, ObservedValueOf, Subscription } from 'rxjs';

export type Option = {
  id: number,
  text: string
}

@Component({
  selector: 'kt-filter-selection',
  templateUrl: './filter-selection.component.html',
  styleUrls: ['./filter-selection.component.scss']
})
export class FilterSelectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() bg: string;
  @Input() placeholder: string = '';
  @Input() selections: Option[] = [];
  @Input() resetCmd$: Observable<void>;
  @Input() defaultOption: Option | null = null;
  @Output() onSelect: EventEmitter<number> = new EventEmitter();

  @HostBinding('style.min-width') min_width = '0px';

  text$ = new BehaviorSubject<string>('')
  subText$ = new BehaviorSubject<string>(null);
  resetSubscription: Subscription;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.defaultOption && changes.defaultOption.currentValue) {
      this.text$.next(changes.defaultOption.currentValue.text);
      this.subText$.next(this.placeholder);
    }
  }

  ngOnInit(): void {
    this.resetSubscription = this.resetCmd$.subscribe(() => {
      this.text$.next(this.unSet());
      this.subText$.next(this.unSetSub());
    })
    let length = this.placeholder.length;
    this.min_width = `${length + 100}px`

    this.text$.next(this.defaultOption ? this.defaultOption.text : this.unSet());
    this.subText$.next(this.unSetSub());
  }

  unSet() {
    return this.selections[0].id == -1 ? this.selections[0].text : this.placeholder;
  }

  unSetSub() {
    return this.selections[0].id == -1 ? this.placeholder : null;
  }

  ngOnDestroy(): void {
    this.resetSubscription.unsubscribe();
  }

  _onSelect(item: Option) {
    this.text$.next(item.text);
    this.subText$.next(this.placeholder);
    this.onSelect.emit(item.id);
  }

}
