import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MadAuthService } from "src/app/core/mad-auth/mad-auth.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'kt-redirect',
    template: `
<div class="kt-login-head kt_login_wrapper">
  <!-- <span class="kt-login-signup-label">Don't have an account yet?</span>&nbsp;&nbsp;
  <a href="javascript:;" routerLink="/mad-auth/register" class="kt-link kt-login-signup-link">
    Sign Up
  </a> -->
</div>

<div class="kt-login-body">
  <div class="kt-login-form">
    <h1>
      <a href="https://www.interval-soft.com/" title="Login Page">
        Post Login Page
      </a>
    </h1>
    <!--<div class="mad-login-form">
      <div id="login-embed"></div>
    </div> -->
  </div>
</div>
    `
})
export class RedirectComponent implements OnInit {
    constructor(
        private _route: ActivatedRoute,
        private _madAuthService: MadAuthService
    ) {}

    async ngOnInit() {
        const token = this._route.snapshot.queryParamMap.get('access_token') as string
        const user = await this._madAuthService.setAccessToken(token)
        const organisation = user.Account.Name;
        const subdomain = slugify(organisation);
        const redirectURL = environment.outsetaRedirectURL;
        const url = `${redirectURL.protocol}://${subdomain}.${redirectURL.url}?access_token=${token}`
        window.location.href = url;
    }
}

function slugify(str: String) {
  return String(str)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
}