// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
// Translate
import {TranslateModule} from '@ngx-translate/core';
import {PartialsModule} from '../../partials/partials.module';
// Services
import {HttpUtilsService, TypesUtilsService, 
    // InterceptService, 
    LayoutUtilsService} from '../../../core/_base/crud';
// Material
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';

// Components
import {NgbModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {ValuationProcessEditComponent} from './valuation-process-edit/valuation-process-edit.component';
import {TargetPropertyComponent} from './target-property.component';
import {AcValuationComponent} from './_sub/ac-valuation/ac-valuation.component';
import {AddValuationDialogComponent} from './_sub/add-valuation-dialog/add-valuation-dialog.component';
import {
    AssetClassEffects,
    AssetClassesService,
    assetClassesReducer,
    ValuationEffects,
    ValuationsService,
    valuationsReducer,
    PaymentEffects,
    PaymentsService,
    paymentsReducer,
    assetClassDetailReducer,
    tpFileReducer,
    TpFileService,
    TpFileEffects, tpTaskReducer, TpTaskEffects, TpTaskService,
    AssetClassSERService,
    assetClassSERReducer,
    AssetClassSEREffects
} from '../../../core/asset_class';
import {NgxMaskDirective, IConfig, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {TagInputModule} from 'ngx-chips';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {ToeGetResolver} from '../toe/_sub/resolver/toe-get-resolver';
import {AssetClassGetResolver} from './_sub/resolvers/asset-class-get-resolver';
import {ToeDashboardComponent} from './dashboard/toe-dashboard.component';
import {TpRowComponent} from './_sub/tp-row/tp-row.component';
import {SharedModule} from '../shared_components/shared.module';
import {InspectionEditComponent} from './inspection/inspection-edit.component';
import {AgmCoreModule} from '@agm/core';
import {AssetClassDetailEffects} from '../../../core/asset_class/_effects/asset-class-detail.effects';
import {AssetClassDetailService} from '../../../core/asset_class/_services/asset-class-detail.service';
import {AssetClassDetailResolver} from './inspection/asset-class-detail-resolver';
import {AssetClassDetailSubDataResolver} from './inspection/asset-class-detail-sub-data-resolver';
import {ProjectManagementComponent} from './project-management/project-management.component';
import {MatDatetimepickerModule} from '@mat-datetimepicker/core';
import {PmEditDialogComponent} from './_sub/pm-edit-dialog/pm-edit-dialog.component';
import {UploadFileBehaviorComponent} from './_sub/upload-file-behavior/upload-file-behavior.component';
import {TpDocumentInfoEditDialogComponent} from './_sub/tp-document-info-edit-dialog/tp-document-info-edit-dialog.component';

import {ToeWorkersResolver} from './_sub/resolvers/toe-workers-resolver';
import {ToeUsersResolver} from './_sub/resolvers/toe-users-resolver';
import {ToeRdmResolver} from './_sub/resolvers/toe-rdm-resolver';
import {ToeActionResolver} from './_sub/resolvers/toe-action-resolver';
import {RouteToeIdResolver} from './_sub/resolvers/route-toe-id-resolver';
import {ToeAssetClassesResolver} from './_sub/resolvers/toe-asset-classes-resolver';
import {environment} from '../../../../environments/environment';
import {CanDeactivateGuard, UserService} from '../../../core/auth';
import {CustomPipesModule} from '../../theme/pipes/custom-pipes.module';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatMomentDatetimeModule} from '@mat-datetimepicker/moment';
import {FacilityEditComponent} from '../linked-tables/facility-edit/facility-edit.component';
import {ReportsComponent} from './_sub/tp-row/_sub/reports/reports.component';
import {propertyDetailReportReducer} from 'src/app/core/asset_class/_reducers/property-detail-report.reducers';
import {PropertyDetailReportEffects} from 'src/app/core/asset_class/_effects/property-detail-report.effects';
import {PropertyDetailReportService} from 'src/app/core/asset_class/_services/property-detail-report.service';
import {LeaseholdInfoDialogComponent} from './valuation-process-edit/_sub/leasehold-info-dialog/leasehold-info-dialog.component';
import {EditReportDescriptionDialogComponent} from './_sub/tp-row/_sub/edit-description-dialog/edit-description-dialog.component';
import {TextWithPictureSliderDialogComponent} from './valuation-process-edit/_sub/text-with-picture-slider-dialog/text-with-picture-slider-dialog.component';
import {CountryEditComponent} from '../admin-management/countries/country-edit/country-edit.component';
import {AdjustmentTabComponent} from './valuation-process-edit/_sub/adjustment-tab/adjustment-tab.component';
import {AdjustmentTableComponent} from './valuation-process-edit/_sub/adjustment-tab/adjustment-table/adjustment-table.component';
import {PercentRangeDirective} from './valuation-process-edit/_sub/adjustment-tab/percentRange.directive';
import {CriterionsTableComponent} from './valuation-process-edit/_sub/adjustment-tab/criterions-table/criterions-table.component';
import {ValuationTableComponent} from './valuation-process-edit/_sub/adjustment-tab/valuation-table/valuation-table.component';
import {SizeModalComponent} from './valuation-process-edit/_sub/adjustment-tab/_modals/size-modal/size-modal.component';
import {ConsiderationModalComponent} from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/consideration-modal.component';
import {CriterionModalComponent} from './valuation-process-edit/_sub/adjustment-tab/_modals/criterion-modal/criterion-modal.component';
import {CriterionEditModalComponent} from './valuation-process-edit/_sub/adjustment-tab/_modals/criterion-edit-modal/criterion-edit-modal.component';
import {AdjustmentModalComponent} from './valuation-process-edit/_sub/adjustment-tab/_modals/adjustment-modal/adjustment-modal.component';
import {
    CriterionEffects, criterionReducer, CriterionService, ValuationDataEffects, valuationDataReducer,
    ValuationDataService
} from 'src/app/core/valuation';
import {sizeCriterionReducer} from 'src/app/core/valuation/_reducers/size_criterion.reducer';
import {SizeCriterionService} from 'src/app/core/valuation/_services/size_criterion.service';
import {SizeCriterionEffects} from 'src/app/core/valuation/_effects/size_criterion.effects';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {PercentageBottomSheet} from '../../partials/content/crud/percentage-bottom-sheet/percentage-bottom-sheet.component';
import {valuationAdjustmentReducer} from 'src/app/core/valuation/_reducers/valuation-adjustment.reducer';
import {ValuationAdjustmentEffects} from 'src/app/core/valuation/_effects/valuation-adjustment.effects';
import {ValuationAdjustmentService} from 'src/app/core/valuation/_services/valuation-adjustment.service';
import {valuationReducer} from 'src/app/core/valuation/_reducers/valuation.reducer';
import {AdjustmentValuationEffects} from 'src/app/core/valuation/_effects/valuation.effects';
import {AdjustmentValuationService} from 'src/app/core/valuation/_services/valuation.service';
import {ValuationKeyplaceComponent} from './valuation-process-edit/_sub/keyplace-tab/valuation-keyplace.component';
import {AssumptionsTabComponent} from './valuation-process-edit/_sub/assumptions-tab/assumptions-tab.component';
import {AssumptionsTableComponent} from './valuation-process-edit/_sub/assumptions-tab/assumptions-table/assumptions-table.component';
import {assumptionDepartureReducer} from 'src/app/core/valuation/_reducers/assumption_departure.reducer';
import {ValuationAssumptionDepartureEffects} from 'src/app/core/valuation/_effects/assumption-departure.effects';
import {EditAssumptionDepartureComponent} from './valuation-process-edit/_sub/assumptions-tab/_modals/edit-assumption-departure/edit-assumption-departure.component';
import {AssumptionDepartureService} from 'src/app/core/valuation/_services/assumption_departure.service';

import {TpAdditionalDocumentComponent} from './_sub/tp-additional-document/tp-additional-document.component';
import {TpFileInfoComponent} from './_sub/tp-file-info/tp-file-info.component';

import {RestoreModalComponent} from './valuation-process-edit/_sub/assumptions-tab/_modals/restore-modal/restore-modal.component';
import {Vpga10TableComponent} from './valuation-process-edit/_sub/assumptions-tab/vpga10-table/vpga10-table.component'
import {valuationVpga10MattersReducer} from 'src/app/core/valuation/_reducers/vpga10-matters.reducer';
import {ValuationVpga10MattersEffects} from 'src/app/core/valuation/_effects/vpga10-matters.effects';
import {EditVpga10MatterComponent} from './valuation-process-edit/_sub/assumptions-tab/_modals/edit-vpga10-matter/edit-vpga10-matter.component';
import {Vpga10MatterService} from 'src/app/core/valuation/_services/vpga10-matters.service';
import {SplitPipe} from './valuation-process-edit/_sub/assumptions-tab/split.pipe';
import {SourceRequiredExternalRefTableComponent} from './valuation-process-edit/_sub/assumptions-tab/source-required-external-ref-table/source-required-external-ref-table.component';
import {valuationSRERReducer} from 'src/app/core/valuation/_reducers/valuation-srer.reducer';
import {ValuationSREREffects} from 'src/app/core/valuation/_effects/valuation-srer.effects';
import {EditSrerComponent} from './valuation-process-edit/_sub/assumptions-tab/_modals/edit-srer/edit-srer.component';
import {ValuationSRERService} from 'src/app/core/valuation/_services/valuation-srer.service';
import {BackgroundTabComponent} from './valuation-process-edit/_sub/background-tab/background-tab.component';
import {ToeReportEffects, ToeReportService, InvoiceService, ToeInvoicePaymentService, ToeSettlementService} from 'src/app/core/toe';
import {AssignmentsService} from 'src/app/core/assignment';
import {valuationNotesReducer} from 'src/app/core/valuation/_reducers/valuation-notes.reducer';
import {ValuationNotesEffects} from 'src/app/core/valuation/_effects/valuation-notes.effects';
import {EditNoteComponent} from './valuation-process-edit/_sub/background-tab/edit-note/edit-note.component';
import {MapModalComponent} from './valuation-process-edit/_sub/background-tab/map-modal/map-modal.component';
import { MilestoneModuleComponent } from './valuation-process-edit/_sub/background-tab/modules/milestone-module/milestone-module.component';
import { ValuationModuleComponent } from './valuation-process-edit/_sub/background-tab/modules/valuation-module/valuation-module.component';
import { TeamInvolvementModuleComponent } from './valuation-process-edit/_sub/background-tab/modules/team-involvement-module/team-involvement-module.component';
import { PropertySpecificsModuleComponent } from './valuation-process-edit/_sub/background-tab/modules/property-specifics-module/property-specifics-module.component';
import { AvailableDocumentsModuleComponent } from './valuation-process-edit/_sub/background-tab/modules/available-documents-module/available-documents-module.component';
import { SpecialAssumptionsModuleComponent } from './valuation-process-edit/_sub/background-tab/modules/special-assumptions-module/special-assumptions-module.component';
import { NotesModuleComponent } from './valuation-process-edit/_sub/background-tab/modules/notes-module/notes-module.component';
import { PicturesModuleComponent } from './valuation-process-edit/_sub/background-tab/modules/pictures-module/pictures-module.component';
import { ConsiderationCriterionService } from 'src/app/core/valuation/_services/consideration-criterion.service';
import { LeaseholdInformationTableComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/leasehold-information-table/leasehold-information-table.component';
import { ValueCalculationComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/value-calculation/value-calculation.component';
import { HeadlineToEffectiveComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/headline-to-effective/headline-to-effective.component';
import { TimeValueOfMoneyMethodComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/headline-to-effective/time-value-of-money-method/time-value-of-money-method.component';
import { StraightlineMethodComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/headline-to-effective/straight-line-method/straight-line-method.component';
import { CashFlowMethodComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/headline-to-effective/cash-flow-method/cash-flow-method.component';
import { CfmComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/headline-to-effective/cash-flow-method/cfm/cfm.component';
import { PreliminaryTableComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/headline-to-effective/cash-flow-method/cfm/preliminary-table/preliminary-table.component';
import { FirstTermTableComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/headline-to-effective/cash-flow-method/cfm/first-term-table/first-term-table.component';
import { MidTermTableComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/headline-to-effective/cash-flow-method/cfm/mid-term-table/mid-term-table.component';
import { LastTermTableComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/headline-to-effective/cash-flow-method/cfm/last-term-table/last-term-table.component';
import { TpDocumentsComponent } from './_sub/tp-documents/tp-documents.component';
import { ToeRequiredComponent } from './_sub/tp-documents/toe-required/toe-required.component';
import { UserUploadedComponent } from './_sub/tp-documents/user-uploaded/user-uploaded.component';
import { SummaryTabComponent } from './valuation-process-edit/_sub/summary-tab/summary-tab.component';
import { ComparableTabTableComponent } from './valuation-process-edit/_sub/comparable-tab/comparable-tab-table/comparable-tab-table.component';
import { ComparableTabMapComponent } from './valuation-process-edit/_sub/comparable-tab/comparable-tab-map/comparable-tab-map.component';
import { ComparableTabComponent } from './valuation-process-edit/_sub/comparable-tab/comparable-tab.component';
import { CurrencyExchangeService } from 'src/app/core/admin/_services/currency_exchange.service';
import { AgencyCriteriaService } from 'src/app/core/admin/_services/agency-criteria.service';
import { LeaseholdInfoModalComponent } from './valuation-process-edit/_sub/leasehold-info-dialog/leasehold-info-modal.component';
import { TextWithPictureSliderModalComponent } from './valuation-process-edit/_sub/text-with-picture-slider-dialog/text-with-picture-slider-modal.component';
import { ConditionRatingModalComponent } from './valuation-process-edit/_sub/condition-rating-dialog/condition-rating-modal.component';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';
import { ReportingProcessComponent } from './reporting-process/reporting-process.component';
import { TpInfoComponent } from './reporting-process/tp-info/tp-info.component';
import { PropertySpecificSectionComponent } from './reporting-process/tp-info/property-specific-section/property-specific-section.component';
import { ToeSpecificSectionComponent } from './reporting-process/tp-info/toe-specific-section/toe-specific-section.component';
import { ValuationInfoComponent } from './reporting-process/valuation-info/valuation-info.component';
import { ValuationSpecificComponent } from './reporting-process/valuation-info/valuation-specific/valuation-specific.component';
import { MilestonesComponent } from './reporting-process/valuation-info/milestones/milestones.component';
import { SummaryTableComponent } from './reporting-process/valuation-info/summary-table/summary-table.component';
import { DocumentTreeComponent } from './reporting-process/reporting-settings/document-tree/document-tree.component';
import { OtherDocumentsComponent } from './reporting-process/reporting-settings/other-documents/other-documents.component';
import { reportingProcessReducer } from 'src/app/core/reporting/_reducers/reporting_process.reducer';
import { ReportingProcessEffects } from 'src/app/core/reporting/_effects/reporting_process.effects';
import { ReportingProcessService } from 'src/app/core/reporting/_services/reporting_process.service';
import { DocumentTreeV2Component } from './reporting-process/reporting-settings/document-tree-v2/document-tree-v2.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlMomentDateTimeModule, OWL_DATE_TIME_FORMATS } from '@busacca/ng-pick-datetime';
import { valuationReportReducer } from 'src/app/core/asset_class/_reducers/valuation-report.reducer';
import { ValuationReportEffects } from 'src/app/core/asset_class/_effects/valuation-report.effects';
import { ValuationReportService } from 'src/app/core/asset_class/_services/valuation-report.service';
import { DesignLayoutComponent } from './reporting-process/reporting-settings/design-layout/design-layout.component';
import { ReportingSettingsV2Component } from './reporting-process/reporting-settings/reporting-settings-v2.component';
import { GeneralValuationMattersComponent } from './reporting-process/reporting-settings/general-valuation-matters/general-valuation-matters.component';
import { SupportingDocumentsComponent } from './reporting-process/reporting-settings/supporting-documents/supporting-documents.component';
import { VpgaTwoComponent } from './reporting-process/reporting-settings/vpga-two/vpga-two.component';
import { PictureSelectorComponentOld } from './reporting-process/reporting-settings/picture-selector/picture-selector-old.component';
import { FileUploadDialogComponent } from './reporting-process/reporting-settings/file-upload-dialog/file-upload-dialog.component';
import { PictureSelectorComponent } from './reporting-process/reporting-settings/picture-selector/picture-selector.component';
import { toeReportTasksReducer } from 'src/app/core/toe/_reducers/toe-report-task.reducer';
import { ToeReportTaskEffects } from 'src/app/core/toe/_effects/toe-report-task.effects';
import { ToeReportTaskService } from 'src/app/core/toe/_services/toe-report-task.service';
import { EditReportTaskInfoComponent } from './dashboard/edit-report-task-info/edit-report-task-info.component';
import { EditPaymentInfoComponent } from './dashboard/edit-payment-info/edit-payment-info.component';
import { EditInvoicePaymentInfoComponent } from './dashboard/edit-invoice-payment-info/edit-invoice-payment-info.component';
import { EditInvoicePvdInfoComponent } from './dashboard/edit-invoice-pvd-info/edit-invoice-pvd-info.component';
import { EditSettlementInfoComponent } from './dashboard/edit-settlement-info/edit-settlement-info.component';
import { FileUploadComponent } from './dashboard/toe-documents/file-upload/file-upload.component';
import { InformedConsentReportService } from 'src/app/core/toe/_services/informed-consent-report.service';
import { draftStatementReportReducer } from 'src/app/core/asset_class/_reducers/draft-statement-report.reducer';
import { DraftStatementEffects } from 'src/app/core/asset_class/_effects/draft-statement.effects';
import { DraftStatementReportService } from 'src/app/core/asset_class/_services/draft-statement-report.service';
import { CoiDialogComponent } from './_sub/coi-dialog/coi-dialog.component';
import { ToeModule } from '../toe/toe.module';
import { NewCoiService } from 'src/app/core/toe/_services/new-coi.service';
import { ToeDocumentsComponent } from './dashboard/toe-documents/toe-documents.component';
import { ToeInvoicesComponent } from './dashboard/toe-invoices/toe-invoices.component';
import { ToeSettlementComponent } from './dashboard/toe-settlement/toe-settlement.component';
import { ToeAuditComponent } from './dashboard/toe-audit/toe-audit.component';
import { WarningModalComponent } from './_sub/amendment-dialog-v2/warning-modal/warning-modal.component';
import { ToeAmendmentService } from 'src/app/core/toe/_services/toe-amendment.service';
import { AmendmentDialogV2Component } from './_sub/amendment-dialog-v2/amendment-dialog-v2.component';
import { RemoveTpAmendmentV2Component } from './_sub/amendment-dialog-v2/remove-tp-amendment-v2/remove-tp-amendment-v2.component';
import { RemoveTpAmendmentV3Component } from './_sub/amendment-dialog-v2/remove-tp-amendment-v3/remove-tp-amendment-v3.component';
import { ManualModalComponent } from './_sub/tp-row/_sub/manual-modal/manual-modal.component';
import { ToeDocsComponent } from './reporting-process/reporting-settings/supporting-documents/toe-docs/toe-docs.component';
import { DeliveryTaskModalComponent } from './_sub/tp-row/_sub/delivery-task-modal/delivery-task-modal.component';
import { SignedReportsComponent } from './_sub/tp-row/_sub/signed-reports/signed-reports.component';
import { tpSignedDocReducer } from 'src/app/core/asset_class/_reducers/tp-signed-docs.reducer';
import { TpSignedDocEffects } from 'src/app/core/asset_class/_effects/tp-signed-docs.effects';
import { TpSignedDocService } from 'src/app/core/asset_class/_services/tp-signed-docs.service';
import { AdjustmentTemplateListComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/adjustment-template-list/adjustment-template-list.component';
import { TemplateFeatureModule } from '../template/template-feature.module';
import { CriterionTemplateListComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/criterion-template-list/criterion-template-list.component';
import { PercentInputComponent } from './valuation-process-edit/_sub/adjustment-tab/_sub/percent-input/percent-input.component';
import { DeliveryFormComponent } from './_sub/tp-row/_sub/delivery-task-modal/delivery-form/delivery-form.component';
import { AbortToeAmendmentComponent } from './_sub/amendment-dialog-v2/abort-toe-amendment/abort-toe-amendment.component';
import { PaymentsInfoComponent } from './_sub/amendment-dialog-v2/payments-info/payments-info.component';
import { AuditTaskModalComponent } from './_sub/tp-row/_sub/audit-task-modal/audit-task-modal.component';
import {SizeTableModalComponent} from './valuation-process-edit/_sub/background-tab/size-modal/size-modal.component';
import { ConditionRatingModuleComponent } from './valuation-process-edit/_sub/background-tab/modules/condition-rating-module/condition-rating-module.component';
import { SizesModalComponent } from './valuation-process-edit/_sub/sizes-modal/sizes-modal.component';
import { DueDiligenceModalComponent } from './_sub/tp-row/_sub/due-diligence-modal/due-diligence-modal.component';
import { ComparableDocumentsModalComponent } from './valuation-process-edit/_sub/comparable-documents-modal/comparable-documents-modal.component';
import { ImageViewerNgbModalComponent } from './valuation-process-edit/_sub/image-viewer-ngb-modal/image-viewer-ngb-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InspectionBaseComponent } from './inspection-base/inspection-base.component';
import { InspectionPicturesComponent } from './inspection-base/inspection-pictures/inspection-pictures.component';
import { InspectionConditionRatingsComponent } from './inspection-base/inspection-condition-ratings/inspection-condition-ratings.component';
import { InspectionSizeMeasurementsComponent } from './inspection-base/inspection-size-measurements/inspection-size-measurements.component';
import { InspectionObservationComponent } from './inspection-base/inspection-observation/inspection-observation.component';
import { InspectionLandParcelComponent } from './inspection-base/inspection-land-parcel/inspection-land-parcel.component';
import { ComparableInfoDialogComponent } from './valuation-process-edit/_sub/comparable-info-dialog/comparable-info-dialog.component';
import { AssetClassTenureService } from 'src/app/core/asset_class/_services/asset-class-tenures.service';
import { AccommodationLayoutModalComponent } from './valuation-process-edit/_sub/comparable-info-dialog/accommodation-layout-modal/accommodation-layout-modal.component';
import { ParcelIdentificationModalComponent } from './valuation-process-edit/_sub/comparable-info-dialog/parcel-identification-modal/parcel-identification-modal.component';
import { ParcelConsiderationModalComponent } from './valuation-process-edit/_sub/comparable-info-dialog/parcel-consideration-modal/parcel-consideration-modal.component';
import { LandAreaModalComponent } from './valuation-process-edit/_sub/comparable-info-dialog/land-area-modal/land-area-modal.component';
import { FilterDialogComponent } from './valuation-process-edit/_sub/comparable-tab/_sub/filter-dialog-component/filter-dialog-component.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import { NpnSliderModule } from 'npn-slider';
import { ValuationResolver } from './valuation-process-edit/valuation.resolver';
import { SizesTableComponent } from './valuation-process-edit/_sub/adjustment-tab/sizes-table/sizes-table.component';
import { ConsiderationInfoTableComponent } from './valuation-process-edit/_sub/comparable-info-dialog/consideration-info-table/consideration-info-table.component';
import { InspectionBuildingPicturesComponent } from './inspection-base/inspection-building-pictures/inspection-building-pictures.component';
import { ConditionRatinModuleV2Component } from './valuation-process-edit/_sub/background-tab/modules/condition-ratin-module-v2/condition-ratin-module-v2.component';
import { InspectionConditionRatingsUploadComponent } from './inspection-base/inspection-condition-ratings-upload/inspection-condition-ratings-upload.component';
import { RemoveTpAmendmentService } from 'src/app/core/toe/_services/remove-tp-amendment.service';
import { PaymentTypeEffects, paymentTypesReducer, PaymentTypesService } from 'src/app/core/linked-tables';
import { ToeAuditTaskModalComponent } from './dashboard/toe-audit/audit-task-modal/audit-task-modal.component'
import { toeAuditTrailTaskReminderReducer } from 'src/app/core/toe/_reducers/toe-audit-task-reminders.reducer'
import { ToeAuditTaskReminderEffects } from 'src/app/core/toe/_effects/toe-audit-task-reminder.effects'
import { ToeAuditTaskReminderService } from 'src/app/core/toe/_services/toe-audit-task-reminder.service'
import { BuildingInfoModalComponent } from 'src/app/views/pages/target-property/valuation-process-edit/_sub/comparable-info-dialog/building-info-modal/building-info-modal.component';
import { AdjustmentAddModalComponent } from './valuation-process-edit/_sub/adjustment-tab/_modals/adjustment-add-modal/adjustment-add-modal.component'
import { ComparableModule } from '../comparable/comparable.module';
import { KeyplaceModalComponent } from './valuation-process-edit/_sub/keyplace-tab/keyplace-modal/keyplace-modal.component';
import { AssignmentResolver } from './resolvers/assignment-resolver';
import { ValuationProcessComponent } from './valuation-process/valuation-process.component';
import { BackgroundTabV2Component } from './valuation-process/background-tab-v2/background-tab-v2.component';
import { MilestoneModuleV2Component } from './valuation-process/milestone-module-v2/milestone-module-v2.component';
import { PicturesModuleV2Component } from './valuation-process/pictures-module-v2/pictures-module-v2.component';
import { PropertySpecificsModuleV2Component } from './valuation-process/property-specifics-module-v2/property-specifics-module-v2.component';
import { TeamInvolvementModuleV2Component } from './valuation-process/team-involvement-module-v2/team-involvement-module-v2.component';
import { ValuationModuleV2Component } from './valuation-process/valuation-module-v2/valuation-module-v2.component';
import { ValuationProcessEffects, valuationProcessReducer, ValuationProcessService } from 'src/app/core/valuation-process';
import { LandmarksTabComponent } from './valuation-process/landmarks-tab/landmarks-tab.component';
import { valuationLandmarkReducer } from 'src/app/core/valuation-process/_reducers/valuation-landmark.reducer';
import { ComparablesTabV2Component } from './valuation-process/comparables-tab-v2/comparables-tab-v2.component';
import { ComparablesMapV2Component } from './valuation-process/comparables-tab-v2/comparables-map-v2/comparables-map-v2.component';
import { ComparablesTableV2Component } from './valuation-process/comparables-tab-v2/comparables-table-v2/comparables-table-v2.component';
import { valuationComparableReducer } from 'src/app/core/valuation-process/_reducers/valuation-comparable.reducer';
import { ValuationComparableEffects } from 'src/app/core/valuation-process/_effects/valuation-comparable.effects';
import { ValuationComparableService } from 'src/app/core/valuation-process/_services/valuation-comparable.service';
import { MapMarkerInfoWindowComponent } from './valuation-process/_sub/map-marker-info-window/map-marker-info-window.component';
import { valuationComparableFullReducer } from 'src/app/core/valuation-process/_reducers/valuation-comparable-full.reducer';
import { ComparableInfoDialogV2Component } from './valuation-process/_sub/comparable-info-dialog-v2/comparable-info-dialog-v2.component';
import { ConsiderationsInfoTableV2Component } from './valuation-process/_sub/considerations-info-table-v2/considerations-info-table-v2.component';
import { PictureSliderDialogComponent } from './valuation-process/_sub/picture-slider-dialog/picture-slider-dialog.component';
import { AdjustmentsTabV2Component } from './valuation-process/adjustments-tab-v2/adjustments-tab-v2.component';
import { SizesTableV2Component } from './valuation-process/adjustments-tab-v2/sizes-table-v2/sizes-table-v2.component';
import { valuationProcessSizeCriterionReducer } from 'src/app/core/valuation-process/_reducers/valuation-process-size-criterion.reducers';
import { ValuationProcessSizeCriterionEffects } from 'src/app/core/valuation-process/_effects/size-criterion.effects';
import { ValuationProcessSizeCriterionsService } from 'src/app/core/valuation-process/_services';
import { AdjustmentsTableV2Component } from './valuation-process/adjustments-tab-v2/adjustments-table-v2/adjustments-table-v2.component';
import { valuationProcessAdjustmentsReducer } from 'src/app/core/valuation-process/_reducers/valuation-adjustment.reducer';
import { AddAdjustmentsModalV2Component } from './valuation-process/_sub/add-adjustments-modal-v2/add-adjustments-modal-v2.component';
import { EditAdjustmentModalV2Component } from './valuation-process/_sub/edit-adjustment-modal-v2/edit-adjustment-modal-v2.component';
import { PercentInputV2Component } from './valuation-process/_sub/percent-input-v2/percent-input-v2.component';
import { CriterionsTableV2Component } from './valuation-process/adjustments-tab-v2/criterions-table-v2/criterions-table-v2.component';
import { valuationProcessCriterionsReducer } from 'src/app/core/valuation-process/_reducers/valuation-criterion.reducer';
import { CriterionsModalV2Component } from './valuation-process/adjustments-tab-v2/criterions-table-v2/criterions-modal-v2/criterions-modal-v2.component';
import { valuationProcessConsiderationCriterionReducer } from 'src/app/core/valuation-process/_reducers/valuation-consideration-criterion.reducer';
import { ValuationTableV2Component } from './valuation-process/adjustments-tab-v2/valuation-table-v2/valuation-table-v2.component';
import { valuationProcessValuationReducer } from 'src/app/core/valuation-process/_reducers/valuation.reducer';
import { SummaryTabV2Component } from './valuation-process/summary-tab-v2/summary-tab-v2.component';
import { FilterSectionAllComponent } from './valuation-process/_sub/filter-section-all/filter-section-all.component';
import { SummaryTableV2Component } from './reporting-process/valuation-info/summary-table-v2/summary-table-v2.component';
import { valuationProcessCustomCriterionReducer } from 'src/app/core/valuation-process/_reducers/valuation-custom-criterion.reducer';
import { PropertySubTypesFilterComponent } from './valuation-process/_sub/filter-section-all/property-sub-types-filter/property-sub-types-filter.component';
import { DesignLayoutV2Component } from './reporting-process/design-layout-v2/design-layout-v2.component';
import { PictureSelectorV2Component } from './reporting-process/design-layout-v2/picture-selector-v2/picture-selector-v2.component';
import { VideoSelectorComponent } from './reporting-process/design-layout-v2/video-selector/video-selector.component';
import { VideoPlayerDialogComponent } from './reporting-process/design-layout-v2/video-player-dialog/video-player-dialog.component';
import { SimplePercentInputComponent } from './valuation-process/_sub/simple-percent-input/simple-percent-input.component';
import { ValuationComparableMapInfoDialogComponent } from './valuation-process/_sub/valuation-comparable-map-info-dialog/valuation-comparable-map-info-dialog.component';
import { PictureSelectorCheckboxComponent } from './reporting-process/design-layout-v2/picture-selector-checkbox/picture-selector-checkbox.component';
import { MadFormsModule } from '../mad-forms/mad-form.module';
import { DateWithOffsetPipe } from 'src/app/core/_base/date-with-offset.pipe';
import { CoreModule } from 'src/app/core/core.module';
import { LogisticsPageComponent } from './logistics-page/logistics-page.component';
import { LogisticsService } from './logistics-page/logistics.service';
import { LogiscticsParticipantEditComponent } from './logistics-page/components/logisctics-participant-edit/logisctics-participant-edit.component';
import { AssetClassSectionComponent } from './dashboard/asset-class-section/asset-class-section.component';
import { TaskService } from 'src/app/core/tasks/task.service';
import { DocumentsComponent } from './dashboard/asset-class-section/documents/documents.component';
import { LandmarksPageComponent } from './landmarks-page/landmarks-page.component';
import { ReportDesignPageComponent } from './report-design-page/report-design-page.component';
import { LandmarksService } from './landmarks-page/landmarks.service';
import { ReportDesignService } from './report-design-page/report-design.service';
import { MadDateAdapter } from 'src/app/core/date/mad-date-adapter';
import { MadSharedModule } from '../mad_shared/shared.module';
import * as fromLiquidationValuation from 'src/app/core/valuation-process/_reducers/liquidation-valuation.reducer'
import { LiquidationValueEstimateTabComponent } from './valuation-process/liquidation-value-estimate-tab/liquidation-value-estimate-tab.component';
import { ConfirmDialogModule } from '../mad_shared/confirm-dialog/confirm-dialog.module';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

export const MY_MOMENT_FORMATS = {
    parseInput: 'l LT',
    fullPickerInput: 'l LT',
    datePickerInput: 'l',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11Label: 'LL',
    monthYearA11Label: 'MMMM YYYY'
}

const routes: Routes = [
    {
        path: '',
        component: TargetPropertyComponent,
        resolve: {
            toeId: RouteToeIdResolver
        },
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },

            {
                path: 'dashboard',
                component: ToeDashboardComponent,
                resolve: {
                    workers: ToeWorkersResolver,
                    users: ToeUsersResolver,
                    rdMethods: ToeRdmResolver,
                    toeData: ToeGetResolver,
                    toeTpData: ToeAssetClassesResolver,
                    toe: ToeActionResolver,
                }
            },

            {
                path: 'project-management',
                component: ProjectManagementComponent,
                resolve: {
                    toe: ToeActionResolver,
                    workers: ToeWorkersResolver
                }
            },
            {
                path: 'inspection/:asset_class_id',
                component: InspectionEditComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    toeData: ToeGetResolver,
                    assetClassData: AssetClassGetResolver,
                    subData: AssetClassDetailSubDataResolver,
                    assetClassDet: AssetClassDetailResolver
                }
            },
            {
                path: 'inspection-base/:asset_class_id',
                component: InspectionBaseComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    toeData: ToeGetResolver,
                    assetClassData: AssetClassGetResolver,
                    subData: AssetClassDetailSubDataResolver,
                    assetClassDet: AssetClassDetailResolver
                }
            },
            {
                path: 'logistics/:asset_class_id',
                component: LogisticsPageComponent
            },
            {
                path: 'landmarks/:asset_class_id',
                component: LandmarksPageComponent
            },
            {
                path: 'report-design/:asset_class_id',
                component: ReportDesignPageComponent,
                resolve: {
                    assetClassData: AssetClassGetResolver,
                    subData: AssetClassDetailSubDataResolver
                }
            },
            {
                path: 'v2/valuation-process/:asset_class_id/:valuation_id',
                component: ValuationProcessComponent,
                // canDeactivate: [CanDeactivateGuard],
                resolve: {
                    toeData: ToeGetResolver,
                    assetClassData: AssetClassGetResolver,
                //     subData: AssetClassDetailSubDataResolver,
                //     assetClassDet: AssetClassDetailResolver,
                //     usersData: ToeUsersResolver,
                    workersData: ToeWorkersResolver,
                //     valuation: ValuationResolver,
                    assignment: AssignmentResolver
                }
            },
            {
                path: 'valuation-process/:asset_class_id/:valuation_id',
                component: ValuationProcessEditComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    toeData: ToeGetResolver,
                    assetClassData: AssetClassGetResolver,
                    subData: AssetClassDetailSubDataResolver,
                    assetClassDet: AssetClassDetailResolver,
                    usersData: ToeUsersResolver,
                    workersData: ToeWorkersResolver,
                    valuation: ValuationResolver,
                    assignment: AssignmentResolver
                }
            },
            {
                path: 'reporting-process/:asset_class_id',
                component: ReportingProcessComponent,
                resolve: {
                    toeData: ToeGetResolver,
                    assetClassData: AssetClassGetResolver,
                    subData: AssetClassDetailSubDataResolver,
                    usersData: ToeUsersResolver,
                    workersData: ToeWorkersResolver,
                }
            },
        ]
    }
];

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        HttpClientModule,
        PartialsModule,
        NgbModule,
        NgbPopoverModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatMomentDatetimeModule,
        MatDatetimepickerModule,
        NgxDaterangepickerMd,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTabsModule,
        MatTooltipModule,
        MatBottomSheetModule,
        MatDialogModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        OwlMomentDateTimeModule,
        TagInputModule,
        PdfViewerModule,
        ComparableModule,
        SharedModule,
        MadFormsModule,
        MatListModule,
        NpnSliderModule,
        AgmCoreModule.forRoot({
            apiKey: environment.agMapKey,
            libraries: ['places', 'drawing', 'geometry']
        }),
        // AgmJsMarkerClustererModule,
        NgxMatSelectSearchModule,
        CustomPipesModule,
        NgxMaskDirective,
        NgxMaskPipe,
        StoreModule.forFeature('assetClasses', assetClassesReducer),
        StoreModule.forFeature('payments', paymentsReducer),
        StoreModule.forFeature('assetClassDetails', assetClassDetailReducer),
        StoreModule.forFeature('tpFiles', tpFileReducer),
        StoreModule.forFeature('tpTasks', tpTaskReducer),
        StoreModule.forFeature('valuations', valuationsReducer),
        StoreModule.forFeature('assetClassSer', assetClassSERReducer),
        StoreModule.forFeature('property-detail-report', propertyDetailReportReducer),
        StoreModule.forFeature('valuation-report', valuationReportReducer),
        StoreModule.forFeature('draft-statement-report', draftStatementReportReducer),
        StoreModule.forFeature('criterions', criterionReducer),
        StoreModule.forFeature('size-criterions', sizeCriterionReducer),
        StoreModule.forFeature('valuation-data', valuationDataReducer),
        // valuationDataReducer
        // ValuationDataEffects
        StoreModule.forFeature('valuation-adjustments', valuationAdjustmentReducer),
        StoreModule.forFeature('adjustment-valuations', valuationReducer),
        StoreModule.forFeature('assumption-departures', assumptionDepartureReducer),
        StoreModule.forFeature('valuation-vpga10-matters', valuationVpga10MattersReducer),
        StoreModule.forFeature('valuation-srer', valuationSRERReducer),
        StoreModule.forFeature('valuation-notes', valuationNotesReducer),
        StoreModule.forFeature('reporting-process', reportingProcessReducer),
        StoreModule.forFeature('toeReportTasks', toeReportTasksReducer),
        StoreModule.forFeature('tp-signed-docs', tpSignedDocReducer),
        StoreModule.forFeature('paymentTypes', paymentTypesReducer),
        StoreModule.forFeature('toe-audit-task-reminders', toeAuditTrailTaskReminderReducer),
        StoreModule.forFeature('valuation-process', valuationProcessReducer),
        StoreModule.forFeature('valuation-landmarks', valuationLandmarkReducer),
        StoreModule.forFeature('valuation-comparables', valuationComparableReducer),
        StoreModule.forFeature('valuation-comparables-full', valuationComparableFullReducer),
        StoreModule.forFeature('valuation-size-criterions', valuationProcessSizeCriterionReducer),
        StoreModule.forFeature('valuation-process-adjustments', valuationProcessAdjustmentsReducer),
        StoreModule.forFeature('valuation-process-criterions', valuationProcessCriterionsReducer),
        StoreModule.forFeature('valuation-custom-criterions', valuationProcessCustomCriterionReducer),
        StoreModule.forFeature('valuation-process-consideration-criterions', valuationProcessConsiderationCriterionReducer),
        StoreModule.forFeature('valuation-process-valuation', valuationProcessValuationReducer),
        StoreModule.forFeature(fromLiquidationValuation.featureKey, fromLiquidationValuation.reducer),
        EffectsModule.forFeature([AssetClassEffects,
            PaymentEffects,
            AssetClassDetailEffects,
            TpFileEffects,
            TpTaskEffects,
            ValuationEffects,
            AssetClassSEREffects,
            PropertyDetailReportEffects,
            ValuationReportEffects,
            CriterionEffects,
            SizeCriterionEffects,
            ValuationDataEffects,
            ValuationAdjustmentEffects,
            AdjustmentValuationEffects,
            ValuationAssumptionDepartureEffects,
            ValuationVpga10MattersEffects,
            ValuationSREREffects,
            ValuationNotesEffects,
            ReportingProcessEffects,
            ToeReportTaskEffects,
            DraftStatementEffects,
            TpSignedDocEffects,
            PaymentTypeEffects,
            ToeAuditTaskReminderEffects,
            ValuationProcessEffects,
            ValuationComparableEffects,
            ValuationProcessSizeCriterionEffects
        ]),
        ToeModule,
        TemplateFeatureModule,
        MadSharedModule,
        ConfirmDialogModule
    ],
    providers: [
        /** Auth Comment
        // InterceptService,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: InterceptService,
        //     multi: true
        // },
        */
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px'
            }
        },
        {
            provide: DateAdapter,
            useClass: MadDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS, useValue: {
                parse: {
                    dateInput: 'DD MMM YYYY',
                },
                display: {
                    dateInput: 'DD MMM YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            }
        },
        {
            provide: OWL_DATE_TIME_FORMATS,
            useValue: MY_MOMENT_FORMATS,
        },
        provideNgxMask(options),
        HttpUtilsService,
        TypesUtilsService,
        CookieService,
        LayoutUtilsService,
        AssetClassesService,
        PaymentsService,
        TpFileService,
        AssetClassSERService,
        ToeGetResolver,
        AssetClassGetResolver,
        AssetClassDetailResolver,
        AssetClassDetailSubDataResolver,
        ValuationResolver,
        AssetClassDetailService,
        PropertyDetailReportService,
        ValuationReportService,
        CriterionService,
        SizeCriterionService,
        ValuationDataService,
        ValuationsService,
        ValuationAdjustmentService,
        AdjustmentValuationService,
        AssumptionDepartureService,
        Vpga10MatterService,
        ValuationSRERService,
        UserService,
        ToeReportService,
        InvoiceService,
        AssignmentsService,
        ConsiderationCriterionService,
        CurrencyExchangeService,
        RouteToeIdResolver,
        ToeActionResolver,
        ToeWorkersResolver,
        ToeUsersResolver,
        ToeRdmResolver,
        TpTaskService,
        ToeAssetClassesResolver,
        AgencyCriteriaService,
        ReadonlyService,
        ValuationDataService,
        ReportingProcessService,
        ToeReportTaskService,
        InformedConsentReportService,
        DraftStatementReportService,
        NewCoiService,
        ToeAmendmentService,
        TpSignedDocService,
        AssetClassTenureService,
        RemoveTpAmendmentService,
        PaymentTypesService,
        ToeInvoicePaymentService,
        ToeSettlementService,
        ToeAuditTaskReminderService,
        AssignmentResolver,
        ValuationProcessService,
        ValuationComparableService,
        ValuationProcessSizeCriterionsService,
        LogisticsService,
        LandmarksService,
        TaskService,
        ReportDesignService,
    ],
    declarations: [
        TargetPropertyComponent,
        ToeDashboardComponent,
        ProjectManagementComponent,
        InspectionEditComponent,
        AddValuationDialogComponent,
        TpRowComponent,
        AcValuationComponent,
        PmEditDialogComponent,
        TpFileInfoComponent,
        UploadFileBehaviorComponent,
        TpAdditionalDocumentComponent,
        TpDocumentInfoEditDialogComponent,
        LeaseholdInfoDialogComponent,
        TextWithPictureSliderDialogComponent,
        ValuationProcessEditComponent,
        ValuationKeyplaceComponent,
        ReportsComponent,
        EditReportDescriptionDialogComponent,
        AdjustmentTabComponent,
        AdjustmentTableComponent,
        PercentRangeDirective,
        CriterionsTableComponent,
        ValuationTableComponent,
        SizeModalComponent,
        ConsiderationModalComponent,
        CriterionModalComponent,
        AdjustmentModalComponent,
        CriterionEditModalComponent,
        AdjustmentModalComponent,
        AssumptionsTabComponent,
        AssumptionsTableComponent,
        EditAssumptionDepartureComponent,
        RestoreModalComponent,
        Vpga10TableComponent,
        EditVpga10MatterComponent,
        SplitPipe,
        SourceRequiredExternalRefTableComponent,
        EditSrerComponent,
        BackgroundTabComponent,
        EditNoteComponent,
        MapModalComponent,
        MilestoneModuleComponent,
        ValuationModuleComponent,
        TeamInvolvementModuleComponent,
        PropertySpecificsModuleComponent,
        AvailableDocumentsModuleComponent,
        SpecialAssumptionsModuleComponent,
        NotesModuleComponent,
        PicturesModuleComponent,
        LeaseholdInformationTableComponent,
        StraightlineMethodComponent,
        ValueCalculationComponent,
        HeadlineToEffectiveComponent,
        TimeValueOfMoneyMethodComponent,
        CashFlowMethodComponent,
        // MethodsSelectionComponent,
        CfmComponent,
        PreliminaryTableComponent,
        FirstTermTableComponent,
        MidTermTableComponent,
        LastTermTableComponent,
        TpDocumentsComponent,
        ToeRequiredComponent,
        UserUploadedComponent,
        SummaryTabComponent,
        ComparableTabTableComponent,
        ComparableTabMapComponent,
        ComparableTabComponent,
        LeaseholdInfoModalComponent,
        TextWithPictureSliderModalComponent,
        ConditionRatingModalComponent,
        ReportingProcessComponent,
        TpInfoComponent,
        PropertySpecificSectionComponent,
        ToeSpecificSectionComponent,
        ValuationInfoComponent,
        ValuationSpecificComponent,
        MilestonesComponent,
        SummaryTableComponent,
        DocumentTreeComponent,
        OtherDocumentsComponent,
        DocumentTreeV2Component,
        DesignLayoutComponent,
        ReportingSettingsV2Component,
        GeneralValuationMattersComponent,
        SupportingDocumentsComponent,
        VpgaTwoComponent,
        PictureSelectorComponentOld,
        FileUploadDialogComponent,
        PictureSelectorComponent,
        EditReportTaskInfoComponent,
        EditPaymentInfoComponent,
        EditInvoicePaymentInfoComponent,
        EditInvoicePvdInfoComponent,
        EditSettlementInfoComponent,
        FileUploadComponent,
        CoiDialogComponent,
        ToeDocumentsComponent,
        ToeInvoicesComponent,
        ToeSettlementComponent,
        ToeAuditComponent,
        WarningModalComponent,
        AmendmentDialogV2Component,
        RemoveTpAmendmentV2Component,
        RemoveTpAmendmentV3Component,
        ManualModalComponent,
        ToeDocsComponent,
        DeliveryTaskModalComponent,
        SignedReportsComponent,
        AdjustmentTemplateListComponent,
        CriterionTemplateListComponent,
        PercentInputComponent,
        DeliveryFormComponent,
        AbortToeAmendmentComponent,
        PaymentsInfoComponent,
        AuditTaskModalComponent,
        SizeTableModalComponent,
        ConditionRatingModuleComponent,
        SizesModalComponent,
        DueDiligenceModalComponent,
        ComparableDocumentsModalComponent,
        ImageViewerNgbModalComponent,
        InspectionBaseComponent,
        InspectionPicturesComponent,
        InspectionConditionRatingsComponent,
        InspectionSizeMeasurementsComponent,
        InspectionObservationComponent,
        InspectionLandParcelComponent,
        ComparableInfoDialogComponent,
        AccommodationLayoutModalComponent,
        ParcelIdentificationModalComponent,
        ParcelConsiderationModalComponent,
        LandAreaModalComponent,
        FilterDialogComponent,
        SizesTableComponent,
        ConsiderationInfoTableComponent,
        InspectionBuildingPicturesComponent,
        ConditionRatinModuleV2Component,
        InspectionConditionRatingsUploadComponent,
        ToeAuditTaskModalComponent,
        BuildingInfoModalComponent,
        AdjustmentAddModalComponent,
        KeyplaceModalComponent,
        ValuationProcessComponent,
        BackgroundTabV2Component,
        MilestoneModuleV2Component,
        PicturesModuleV2Component,
        PropertySpecificsModuleV2Component,
        TeamInvolvementModuleV2Component,
        ValuationModuleV2Component,
        LandmarksTabComponent,
        ComparablesTabV2Component,
        ComparablesMapV2Component,
        ComparablesTableV2Component,
        MapMarkerInfoWindowComponent,
        ComparableInfoDialogV2Component,
        ConsiderationsInfoTableV2Component,
        PictureSliderDialogComponent,
        AdjustmentsTabV2Component,
        SizesTableV2Component,
        AdjustmentsTableV2Component,
        AddAdjustmentsModalV2Component,
        EditAdjustmentModalV2Component,
        PercentInputV2Component,
        CriterionsTableV2Component,
        CriterionsModalV2Component,
        ValuationTableV2Component,
        SummaryTabV2Component,
        FilterSectionAllComponent,
        SummaryTableV2Component,
        PropertySubTypesFilterComponent,
        DesignLayoutV2Component,
        PictureSelectorV2Component,
        VideoSelectorComponent,
        VideoPlayerDialogComponent,
        SimplePercentInputComponent,
        ValuationComparableMapInfoDialogComponent,
        PictureSelectorCheckboxComponent,
        LogisticsPageComponent,
        LogiscticsParticipantEditComponent,
        AssetClassSectionComponent,
        DocumentsComponent,
        LandmarksPageComponent,
        ReportDesignPageComponent,
        LiquidationValueEstimateTabComponent
    ],
    exports: [
        PropertySpecificsModuleComponent,
        TeamInvolvementModuleComponent,
        MilestoneModuleComponent,
        ValuationSpecificComponent,
        ToeSpecificSectionComponent,
        SummaryTableComponent,
        ToeInvoicesComponent,
        ToeSettlementComponent,
        SummaryTableV2Component,
        PropertySpecificsModuleV2Component
    ]
    // schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class TargetPropertyModule {
}
